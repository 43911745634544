import React, { useEffect, useState } from "react";
import { Col, Row, Label, Button, FormGroup } from "reactstrap";
import Select from "react-select";
import {
  getServicesByQuery,
  updatefeildService,
} from "services/services.service";
import { getCategories } from "services/category.service";

export const RelatedServiceInfo = ({ toggle, initValues, reloadService }) => {
  const [values, setValues] = useState();
  const [services, setServices] = useState([]);
  const [relServiceList, setRelServicelist] = useState([]);
  const [selectedRelService, setSelectedRelService] = useState(null);
  const [subCatList, setSubCatList] = useState();
  const [selectedCat, setSelectedCat] = useState();
  const [loading, setLoading] = useState(false);
  console.log(selectedCat, "selected");
  useEffect(() => {
    const { relatedService } = initValues;
    setSelectedRelService(buildOptions(relatedService));
    getAllCataegories();
  }, []);

  //Build {label,value}array
  const buildOptions = list => {
    console.log(list, "listtt");
    const result = list?.map(ele => {
      let obj = {
        label: ele?.serviceId?.name,
        value: ele?.serviceId?._id,
      };
      return obj;
    });
    return result;
  };
  const buildOptionsCategory = list => {
    console.log(list, "listtt");
    const result = list?.map(ele => {
      let obj = {
        label: ele?.name,
        value: ele?._id,
      };
      return obj;
    });
    return result;
  };
  const getAllCataegories = () => {
    getCategories()
      .then(res => {
        if (res) {
          let activeServices = res?.filter(
            ele => ele?.status === "Active" || ele?.status === "active"
          );
          const result = buildOptionsCategory(activeServices);
          setSubCatList(result);
        } else {
          console.error(res);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
  const getServicesList = body => {
    getServicesByQuery(body)
      .then(res => {
        if (res?.data) {
          let activeServices = res?.data?.filter(
            ele => ele?.status === "Active" || ele?.status === "active"
          );
          const result = buildOptionsCategory(activeServices);

          setRelServicelist(result);
        } else {
          console.error(res);
        }
      })
      .catch(e => {
        setLoading(false);
        console.log(e);
      });
  };

  function handleRelService(selectedMulti) {
    const data = selectedMulti.map((e, index) => ({
      index: index + 1,
      serviceId: e.value,
    }));
    setValues({ ...values, ["relatedService"]: data });

    setSelectedRelService(selectedMulti);
    setServices(data);
  }

  function handleCatList(selectedMulti) {
    const data = [];
    selectedMulti.map(e => {
      let obj = {
        _id: e.value,
        name: e.label,
      };
      data.push(obj);
    });
    setValues({ ...values, ["categories"]: data });
    setSelectedCat(selectedMulti);

    let catId;
    if (setSelectedCat) {
      {
        data.map(ele => {
          catId = ele._id;
        });
      }
      let obj = {
        categories: catId,
        type: initValues?.type,
      };
      console.log(obj, "obj");
      getServicesList(obj);
    }
  }

  const onSubmit = () => {
    updatefeildService(initValues._id, { relatedService: services })
      .then(res => {
        reloadService();
        toggle();
      })
      .catch(e => console.log("====", e));
  };
  return (
    <>
      <Row className="mt-3 mx-0">
        <Col md={6} className="px-3">
          <FormGroup className="mb-3">
            <Label htmlFor="brands">Category</Label>
            <Select
              id="brands"
              value={selectedCat}
              isMulti={true}
              onChange={event => {
                handleCatList(event);
                const data = [];
                event.map(e => {
                  data.push(e.value);
                });
              }}
              options={subCatList}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </Col>
        <Col md={6} className="px-3">
          <FormGroup className="mb-3">
            <Label htmlFor="brands">Related Services</Label>
            <Select
              id="brands"
              value={selectedRelService}
              isMulti={true}
              onChange={event => {
                handleRelService(event);
                const data = [];
                event.map(e => {
                  data.push(e.value);
                });
              }}
              options={relServiceList}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </Col>
      </Row>

      <hr />
      <div className="d-flex mt-4 justify-content-end">
        <Button
          color="light"
          className="w-md btn-sm me-2"
          onClick={() => toggle()}
        >
          Back
        </Button>
        <Button
          color="primary"
          className="w-md btn-sm ms-2"
          onClick={() => onSubmit()}
        >
          Submit
        </Button>
      </div>
    </>
  );
};
export default RelatedServiceInfo;
