import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import "./OrderPageStyles.scss";
import Select from "react-select";
import moment from "moment";
import timesSlots from "utils/TimeSlots";
import { RescheduleOrder } from "services/V2_APIs/orders.service";
import SuccessAlert from "components/Alerts/succes";
import ErrorAlert from "components/Alerts/error";
import Spinner from "components/spinner";

const ChangeStylistModal = ({
  toggle,
  modal,
  AvailableProviders,
  providers,
  activeOrder,
  selecteditem,
  loading,
  getallOrders,
  getOrderById,
  toggleModel,
}) => {
  console.log(
    AvailableProviders,
    "avail mm",
    providers,
    selecteditem,
    activeOrder?._id
  );
  const [newProviderSelected, setNewProviderSelected] = useState({});
  const [setlectedWeek, setsetlectedWeek] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeSelected, setTimeSelected] = useState();
  const [success_msg, setsuccess_msg] = useState(false);
  console.log(success_msg, "success msg");
  const [error_msg, seterror_msg] = useState(false);
  const dateArray = [0, 1, 2, 3, 4];
  const handleButtonClick = (weekday, date) => {
    console.log(date, "date");
    let formatedDate = moment(date).format("YYYY-MM-DD");
    setsetlectedWeek(weekday);
    setSelectedDate(formatedDate);
    setTimeSelected({});
  };
  const getSlotsArray = (selectedSlot, selecteditem) => {
    let slotsArray = [];
    // debugger;
    if (selecteditem?.slots?.length === 1) {
      slotsArray?.push(selectedSlot);
    } else {
      for (let i = 0; i < selecteditem?.slots?.length; i++) {
        slotsArray?.push(selectedSlot + i - 1);
      }
    }
    console.log(slotsArray, "slotsArrayslotsArrayslotsArray 1 item");
    return slotsArray;
  };
  const OnSubmit = () => {
    const body = {
      appointmentDate: selectedDate,
      appointmentTime: timesSlots[timeSelected?.slotNumber],
      slots: getSlotsArray(timeSelected?.slotNumber, selecteditem),
      updatedServices: [
        {
          _id: selecteditem?._id,
          providerid: newProviderSelected?.value,
          companyid: newProviderSelected?.companyId
            ? newProviderSelected?.companyId
            : "",
          slots: [timeSelected?.slotNumber],
          amount: selecteditem?.amount,
        },
      ],
    };

    console.log(body, "Payload");

    // Call RescheduleOrder API
    RescheduleOrder(body, activeOrder?._id)
      .then(res => {
        console.log(res, "Reschedule Order response");
        setsuccess_msg(true);
        getallOrders();

        getOrderById(activeOrder);
        toggle();
      })
      .catch(e => {
        console.log(e, "Reschedule Order error");
        // Handle error (e.g., show error message)
        seterror_msg(true);
      });
  };

  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    // toggle();
    // toggleModel();
    setTimeSelected({});
    setSelectedDate(null);
    setsetlectedWeek(null);
  };

  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    // toggle();
    // toggleModel();
  };
  const closemodel = () => {
    toggle();
  };
  return (
    <>
      {loading ? (
        <Spinner loading={loading} />
      ) : (
        <Modal isOpen={modal} toggle={toggle} backdrop="static" size="lg">
          <ModalHeader toggle={toggle}>Update Stylist Availability</ModalHeader>
          <ModalBody className="bg-light">
            <Container fluid>
              <div className="d-flex align-items-center Available_provider_container flex-wrap">
                <h6 className="me-3">
                  Total Available Providers : {AvailableProviders?.length}
                </h6>
                {AvailableProviders?.map((ind, indx) => {
                  return (
                    <div key={indx} className="profile_images mb-2">
                      <img src={ind?.profileimage} />
                    </div>
                  );
                })}
              </div>
              <div>
                <Row>
                  <Col md={6}>
                    {AvailableProviders && AvailableProviders?.length ? (
                      <div>
                        <Select
                          name="parentCat"
                          id="parentCat"
                          // value={newProviderSelected}
                          placeholder="Select Provider..."
                          onChange={event => {
                            setNewProviderSelected(event);
                          }}
                          options={AvailableProviders}
                        />
                      </div>
                    ) : (
                      <Card>
                        <CardBody>No Providers are available</CardBody>
                      </Card>
                    )}
                  </Col>
                </Row>
              </div>
              <div>
                <div className="d-flex stylist_availability flex-wrap">
                  {dateArray?.map((item, i) => {
                    const newDate = moment(new Date()).add(i, "days");
                    const abbreviatedWeekday = newDate.format("ddd");
                    const fullWeekday = newDate
                      .format("dddd")
                      .toLocaleLowerCase();
                    const isToday = i === 0;
                    const isTomorrow = i === 1;
                    const isSelected = setlectedWeek === fullWeekday;
                    return (
                      <div key={item} className="">
                        <div className="mt-3">
                          <Button
                            className={`btn-weekdays me-2 ${
                              isSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              handleButtonClick(fullWeekday, newDate)
                            }
                          >
                            <h6 className="mb-1">{abbreviatedWeekday}</h6>

                            <h6 className="mb-0 fw-bold">
                              {newDate?.format("DD")}
                            </h6>
                            <span className="font-size-10">
                              {" "}
                              {isToday ? "Today" : isTomorrow ? "Tomorrow" : ""}
                            </span>
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {setlectedWeek ? (
                  <Card className="mt-2 time_slots_div">
                    <CardBody className="p-2 mt-2">
                      <div className="d-flex flex-wrap justify-content-center">
                        {providers
                          .filter(
                            pitem => pitem?._id === newProviderSelected?.value
                          )
                          ?.map((pitem, index) => {
                            let weekAvailability =
                              pitem?.timeSlots?.weekAvailability?.[
                                setlectedWeek
                              ]?.working;
                            if (weekAvailability) {
                              let availableSlots =
                                pitem?.timeSlots?.weekAvailability?.[
                                  setlectedWeek
                                ]?.available || [];
                              let timeStamp = moment(selectedDate).format("X");
                              let reservedSlots =
                                pitem?.timeSlots?.reserved?.[timeStamp] || [];
                              let currentMoment = moment();
                              let selectedMoment = moment(selectedDate);

                              let isToday = selectedMoment.isSame(
                                currentMoment,
                                "day"
                              );
                              let currentTimeSlot = isToday
                                ? Object.keys(timesSlots).find(slot =>
                                    moment(timesSlots[slot], "h:mm A").isAfter(
                                      currentMoment
                                    )
                                  )
                                : null;

                              let futureSlots = isToday
                                ? availableSlots.filter(
                                    slot => slot >= currentTimeSlot
                                  )
                                : availableSlots;

                              if (isToday && futureSlots.length === 0) {
                                return (
                                  <div key={index} className="p-3">
                                    No available slots for today
                                  </div>
                                );
                              }

                              return futureSlots.map((slotNumber, i) => {
                                let selectedSlot =
                                  timeSelected?.slotNumber === slotNumber;
                                let notAvailable = reservedSlots?.includes(
                                  Number(slotNumber)
                                );

                                return (
                                  <Button
                                    key={i}
                                    className={`Time_slots btn me-2 mb-2 pt-2 ${
                                      selectedSlot ? "selectedSlot" : ""
                                    }`}
                                    style={{
                                      backgroundColor: notAvailable
                                        ? "#b1b1b1"
                                        : "white",
                                    }}
                                    onClick={() => {
                                      if (
                                        notAvailable ||
                                        reservedSlots?.includes(
                                          Number(slotNumber) + 1
                                        )
                                      ) {
                                        alert(
                                          "This time slot is not available. Please choose a different one."
                                        );
                                      } else {
                                        setTimeSelected({
                                          time: timesSlots[slotNumber],
                                          providerID: pitem?._id,
                                          date: selectedDate,
                                          slotNumber: slotNumber,
                                          timeStamp: timeStamp,
                                        });
                                        getSlotsArray(slotNumber, selecteditem);
                                      }
                                    }}
                                  >
                                    {timesSlots[slotNumber]}
                                  </Button>
                                );
                              });
                            } else {
                              return (
                                <div key={index} className="p-3">
                                  Stylist is not available on this day
                                </div>
                              );
                            }
                          })}
                      </div>
                    </CardBody>
                  </Card>
                ) : (
                  ""
                )}

                <hr />
                <div className="d-flex justify-content-end">
                  <Button
                    color="light"
                    className="w-md btn-sm me-2"
                    onClick={closemodel}
                  >
                    cancel
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    className="w-md btn-sm float-end"
                    onClick={OnSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Container>
          </ModalBody>
        </Modal>
      )}

      {success_msg ? (
        <SuccessAlert
          msg={"Successfully Order Rescheduled"}
          leaveToList={leaveToList}
          stayHere={stayHere}
        />
      ) : null}
      {error_msg ? (
        <ErrorAlert
          msg={"error"}
          leaveToList={leaveToList}
          stayHere={stayHere}
        />
      ) : null}
    </>
  );
};

export default ChangeStylistModal;
