import Breadcrumb from "components/Common/Breadcrumb";
import BreadcrumbSub from "components/Common/BreadcrumbSub";
import React from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";
import OrderDetailsList from "./list";

const Order_Details_index = () => {
  return (
    <React.Fragment>
      <div className="all_orders_details">
        <div className="page-content mb-5">
          <Container fluid>
            <MetaTags>
              <title>Orders</title>
            </MetaTags>
            <Breadcrumb title="Orders" breadcrumbItem="All Orders List" />
            <BreadcrumbSub
              subtile="All Orders"
              SubBreadcrumbItem="All Orders"
            />
            <OrderDetailsList />
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Order_Details_index;
