export const getOrders = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/orders/all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at orders ${e}`));
};

export const getOrdersCountsAndList = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/orders/admin/counts`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at orders ${e}`));
};

export const getAdminOrdersList = status => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/orders/admin/status?status=${status}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at orders ${e}`));
};

export const getAdminSearchOrdersList = search => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/orders/admin/search?search=${search}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at orders ${e}`));
};

export const getPaginatedOrders = url => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}${url}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at orders ${e}`));
};

export const updateStatusById = (id, status) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/orders/updateStatusById/${id}/${status}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at order status Update ${e}`));
};

export const getOrderbyId = id => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/orders/get/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting order ${e}`));
};

export const applyBid = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/orders/applybid/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at login ${e}`));
};

export const getEcomOrders = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/order/all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at orders ${e}`));
};

export const UpdateOrdersAdminNotesForService = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/orders/updateAdmin/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Admin Notes Update ${e}`));
};

export const UpdateOrdersAdminNotesForQuote = (id, body) => {
  console.log(id, body, "idbody");
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/quote/update/${id}`,

    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Admin Notes Update ${e}`));
};

export const UpdateStylistAvailability = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/orders/rescheduleOrder`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Servce Update ${e}`));
};

export const UpdateBonusToStylist = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/orders/bonus/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Servce Update ${e}`));
};

export const ConfirmServiceOrder = (OrderId, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/orders/updateConfirmOrderByStylist/${OrderId}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Admin Notes Update ${e}`));
};
