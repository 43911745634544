import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { createBrand } from "services/ecommerce/brand.service";

import MetaTags from "react-meta-tags";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  FormFeedback,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import Select from "react-select";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import ProfileImage from "./../../components/profileImage";
import Spinner from "components/spinner";

import "./create.styles.scss";
import {
  createCategory,
  getCategory,
  updateCategory,
} from "services/category.service";
import {
  getParentCategories,
  getParentCategoriesByQuery,
} from "services/parentcategory.service";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const defaultValues = {
  name: "",
  color: "",
  order: "",
  tagline: "",
  parentCategory: "",
  image: {},
  status: "",
  type: "",
  specialArtist: ""
};

const CreateCategory = ({ toggle, modal, id = false }) => {
  // const { id } = useParams();
  let history = useHistory();

  const [selectedParentCat, setSelectedParentCat] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedSpecial, setSelectedSpecial] = useState(null);
  const [selectedTagline, setSelectedTagline] = useState("");

  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [parentCatList, setParentCatList] = useState([]);
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);

  const initialValues = defaultValues;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Name"),
    color: Yup.string().required("Please Enter color"),
    order: Yup.string().required("Please Enter Order"),
    tagline: Yup.string().required("Please Enter tagline"),
    parentCategory: Yup.string().required("Please Select ParentCategory"),
    // image: Yup.object().shape({}).required("Please Select Image"),
    status: Yup.string().required("Please Select Status"),
    type: Yup.string().required("Please Select Type"),
    specialArtist: Yup.bool().required("Please Select If Special"),
  });
  const onSubmit = values => {
    if (id) {
      editChanges(values);
    } else {
      saveChanges(values);
    }
  };
  const { image } = values;

  const getCategoryById = id => {
    setLoading(true);
    getCategory(id)
      .then(res => {
        let {
          color,
          tagline,
          image,
          name,
          order,
          parentCategory,
          status,
          type,
          specialArtist
        } = res.data;

        let obj = {
          name,
          color,
          order,
          parentCategory,
          tagline,
          image,
          status,
          type,
          specialArtist
        };

        setValues({ ...obj });
        setSelectedParentCat({
          value: parentCategory,
          label: parentCategory,
        });
        setSelectedTagline(tagline);
        setSelectedStatus({ label: status, value: status });
        setSelectedType({ label: type, value: type });
        setSelectedSpecial({ label: specialArtist, value: specialArtist });
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const preload = id => {
    if (id) {
      getCategoryById(id);
    } else {
      setValues({ ...values });
    }
  };

  useEffect(() => {
    // preload(id);
    getParentCategories()
      .then(res => {
        if (res.data) {
          let list = res?.data;
          let resArr = [];
          list.map(cat => {
            resArr.push({
              value: cat._id,
              label: cat.name,
            });
          });
          setParentCatList(resArr);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const goToPreviousPath = () => {
    history.goBack();
  };

  // const parentCategoryList = [
  //   { label: "beauty", value: "beauty" },
  //   { label: "electric", value: "electric" },
  // ];

  const statusList = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];
  const typeList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Kids", value: "Kids" },
  ];
  const specialList = [
    { label: "true", value: "true" },
    { label: "false", value: "false" }
  ];
  const inputChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setValues({ ...values, ["image"]: files[0] });
  }
  function handleSelectParentCat(event) {
    setValues({ ...values, ["parentCategory"]: event.value });
    setSelectedParentCat(event);
  }

  function handleSelectStatus(event) {
    setValues({ ...values, ["status"]: event.value });
    setSelectedStatus(event);
  }
  function handleSelectType(event) {
    setValues({ ...values, ["type"]: event.value });
    setSelectedType(event);
    getParentCategoriesByQuery({ type: event.value })
      .then(res => {
        if (res.data) {
          let list = res?.data;
          let resArr = [];
          list.map(cat => {
            resArr.push({
              value: cat._id,
              label: cat.name,
            });
          });
          setParentCatList(resArr);
          setSelectedParentCat(null);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }

  function handleSelectSpecial(event) {
    setValues({ ...values, ["specialArtist"]: (event.value == "true") ? true : false });
    setSelectedSpecial(event);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const saveChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    createCategory(formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
          console.log(res);
        }
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const editChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    updateCategory(id, formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const leaveToList = () => {
    // props.history.push("/category-list");
    // goToPreviousPath();
    toggle();
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    // props.history.push("/category-list");
    goToPreviousPath();
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully Sub-Category created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}> {
          id ? "Edit SubCategory" : "Create SubCategory"
        }</ModalHeader>
        <ModalBody className="bg-light">
          <Spinner loading={loading} />
          <Container fluid={false}>
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    handleChange,
                    setFieldTouched,
                    values,
                  }) => {
                    useEffect(() => {
                      if (id) {
                        setLoading(true);
                        getCategory(id)
                          .then(res => {
                            const data = { ...res.data };
                            const fields = [
                              "name",
                              "color",
                              "order",
                              "tagline",
                              "image",
                              "status",
                              "type",
                              "specialArtist"
                            ];
                            fields.forEach(field =>
                              setFieldValue(field, data[field], false)
                            );
                            setFieldValue(
                              "parentCategory",
                              data.parentCategory._id,
                              false
                            );
                            setSelectedParentCat({
                              value: data.parentCategory._id,
                              label: data.parentCategory.name,
                            });
                            setSelectedTagline(data.tagline);
                            setSelectedStatus({
                              label: data.status,
                              value: data.status,
                            });
                            setSelectedType({
                              label: data.type,
                              value: data.type,
                            });
                            setSelectedSpecial({
                              label: (data.specialArtist) ? "true" : "false",
                              type: (data.specialArtist) ? "true" : "false"
                            })
                            setLoading(false);
                          })
                          .catch(e => {
                            console.log(e);
                          });
                      }
                    }, []);

                    return (
                      <Form>
                        <Row className="mt-4">
                          <Col md={6} className="px-4">
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">Name</Label>
                                <Input
                                  name="name"
                                  placeholder="Name"
                                  type="text"
                                  className="form-control"
                                  id="validationCustom01"
                                  onChange={handleChange}
                                  // onBlur={validation.handleBlur}
                                  value={values.name}
                                  invalid={
                                    touched.name && errors.name ? true : false
                                  }
                                />

                                {touched.name && errors.name ? (
                                  <FormFeedback type="invalid">
                                    {errors.name}
                                    {touched.name}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Row>
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">
                                  Color
                                </Label>
                                <Input
                                  name="color"
                                  placeholder="color"
                                  type="text"
                                  className="form-control"
                                  id="validationCustom02"
                                  onChange={handleChange}
                                  value={values.color}
                                  invalid={
                                    touched.color && errors.color ? true : false
                                  }
                                />
                                {touched.color && errors.color ? (
                                  <FormFeedback type="invalid">
                                    {errors.color}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Row>
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom03">
                                  Order
                                </Label>
                                <Input
                                  name="order"
                                  placeholder="order"
                                  type="text"
                                  className="form-control"
                                  id="validationCustom03"
                                  onChange={handleChange}
                                  value={values.order}
                                  invalid={
                                    touched.order && errors.order ? true : false
                                  }
                                />
                                {touched.order && errors.order ? (
                                  <FormFeedback type="invalid">
                                    {errors.order}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Row>
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom04">Type</Label>
                                <Select
                                  // className="form-control"
                                  id="validationCustom04"
                                  value={selectedType}
                                  onChange={event => {
                                    handleSelectType(event);
                                    handleChange("type")(event.value);
                                  }}
                                  name="type"
                                  options={typeList}
                                  classNamePrefix="select2-selection"
                                />
                                {touched.type && errors.type ?
                                  <Label className="statusValidation">
                                    {" "}
                                    {errors.type}
                                  </Label>
                                  : ""}
                              </FormGroup>
                            </Row>
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom04">Is Special?</Label>
                                <Select
                                  // className="form-control"
                                  id="validationCustom04"
                                  value={selectedSpecial}
                                  onChange={event => {
                                    handleSelectSpecial(event);
                                    handleChange("specialArtist")(event.value);
                                  }}
                                  name="specialArtist"
                                  options={specialList}
                                  classNamePrefix="select2-selection"
                                />
                                {touched.specialArtist && errors.specialArtist ?
                                  <Label className="statusValidation">
                                    {" "}
                                    {errors.specialArtist}
                                  </Label>
                                  : ""}
                              </FormGroup>
                            </Row>
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom05">
                                  Parent Category
                                </Label>
                                <Select
                                  // className="form-control"
                                  id="validationCustom05"
                                  value={selectedParentCat}
                                  onChange={event => {
                                    handleSelectParentCat(event);
                                    handleChange("parentCategory")(event.value);
                                  }}
                                  name="type"
                                  options={parentCatList}
                                  classNamePrefix="select2-selection"
                                />
                                {touched.parentCategory && errors.parentCategory ?
                                  <Label className="statusValidation">
                                    {" "}
                                    {errors.parentCategory}
                                  </Label>
                                  : ""}
                              </FormGroup>
                            </Row>
                          </Col>
                          <Col md={6} className="px-4">
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom06">
                                  Image
                                </Label>
                                <Dropzone
                                  id="validationCustom06"
                                  onDrop={acceptedFiles => {
                                    acceptedFiles.map(file =>
                                      Object.assign(file, {
                                        preview: URL.createObjectURL(file),
                                        formattedSize: formatBytes(file.size),
                                      })
                                    );
                                    setFieldValue("image", acceptedFiles[0]);
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone image_drop">
                                      <div
                                        className="dz-message needsclick "
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="">
                                          <i className="display-6 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h6>
                                          Drop files here or click to upload.
                                        </h6>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                <Label className="statusValidation">
                                  {" "}
                                  {errors.image ? errors.image : ""}
                                </Label>
                                <div
                                  className="dropzone-previews mt-3"
                                  id="file-previews"
                                >
                                  {values.image &&
                                    Object.keys(values.image).length > 0 && (
                                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <ProfileImage
                                                image={values?.image}
                                                height="80"
                                                classNames="avatar-sm rounded bg-light"
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                              >
                                                {values.image.name ||
                                                  values.image.filename}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {values.image.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    )}
                                </div>
                              </FormGroup>
                            </Row>
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="tagline">Tagline</Label>
                                <div className="custom_ck_editor">
                                  <CKEditor
                                    editor={ClassicEditor}
                                    id="tagline"
                                    data={selectedTagline}
                                    onReady={editor => {
                                      // You can store the "editor" and use when it is needed.
                                      // editor.setData(values.tagline);
                                    }}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      handleChange("tagline")(data);
                                    }}
                                  />
                                </div>
                                {touched.tagline && errors.tagline ?
                                  <Label className="statusValidation">
                                    {" "}
                                    {errors.tagline}
                                  </Label>
                                  : ""}
                              </FormGroup>
                            </Row>
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom08">
                                  Status
                                </Label>
                                <Select
                                  // className="form-control"
                                  id="validationCustom08"
                                  value={selectedStatus}
                                  onChange={event => {
                                    handleSelectStatus(event);
                                    handleChange("status")(event.value);
                                  }}
                                  name="type"
                                  options={statusList}
                                  classNamePrefix="select2-selection"
                                />
                                {touched.status && errors.status ?
                                  <Label className="statusValidation">
                                    {" "}
                                    {errors.status}
                                  </Label>
                                  : ""}
                              </FormGroup>
                            </Row>
                          </Col>
                        </Row>
                        <hr></hr>
                        <div className="d-flex justify-content-end">
                          <Button
                            color="light"
                            className="w-md btn-sm me-3"
                            onClick={leaveToList}
                          >
                            cancel
                          </Button>
                          {!id ? (
                            <Button
                              color="primary"
                              type="submit"
                              className="w-md btn-sm me-4"
                            // onClick={saveChanges}
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              className="w-md btn-sm me-4"
                              type="submit"
                            // onClick={editChanges}
                            >
                              Save Changes
                            </Button>
                          )}

                        </div>
                      </Form>
                    );
                  }}
                </Formik>

                {success_msg ? successPopup() : null}
                {error_msg ? errorPopup() : null}
              </CardBody>
            </Card>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateCategory;
