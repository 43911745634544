import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  CardBody,
  ModalBody,
  Modal,
  ModalHeader,
  Button,
} from "reactstrap";
import * as moment from "moment";
import { getStatusBgClass } from "./common";
import "./list.scss";
import { OrderHistory } from "./OrderHistory";
import door from "../../assets/images/door-to-door.png";
import ring from "../../assets/images/notification.png";
import mask from "../../assets/images/mask.png";
import call from "../../assets/images/no-call.png";
import dog from "../../assets/images/dog.png";
import ProfileImage from "components/profileImage";
import noImage from "assets/images/PicUpload.jpg";
import { getRatingsByOrderId } from "services/rating.service";
import Rating from "./Rating";
import ServiceTable from "./ServiceTable";
import OrderTable from "./OrderTable";
import AdminNotesServiceOrders from "./AdminNotes";
import MomentTimezone from "moment-timezone";
import axios from "axios";
import Select from "react-select";
import timesSlots from "utils/TimeSlots";
import {
  ConfirmServiceOrder,
  UpdateStylistAvailability,
} from "services/orders.service";
import Spinner from "components/spinner";
import SuccessAlert from "components/Alerts/succes";
import ErrorAlert from "components/Alerts/error";
import UpdateBonus from "./UpdateBonus";
import OrderConfirmation from "./OrderConfirmation";

export const OrderDetails = ({ activeOrder, reload, getallOrders }) => {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subTotal, setSubTotal] = useState("-");
  const [tax, setTax] = useState("-");
  const [totalAmount, setTotalAmount] = useState("-");
  const [provider, setProvider] = useState("-");
  const [additional, setAdditional] = useState("");
  const [amountPaid, setAmountPaid] = useState(0);
  const [amountDue, setAmountDue] = useState(0);
  let [subAmt, setSubAmt] = useState(0);
  let [amtDue, setAmtDue] = useState(0);
  let [refundAmt, setRefundAmt] = useState(0);
  let [rating, setRating] = useState(null);
  const [ServiceAmount, setServiceAmount] = useState();
  const [productTotal, setProductTotal] = useState(0);
  const [serviceTotal, setServiceTotal] = useState(0);
  const [setlectedWeek, setsetlectedWeek] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeSelected, setTimeSelected] = useState();
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [otp, setOtp] = useState([]);
  const handleButtonClick = (weekday, date) => {
    let formatedDate = moment(date).format("YYYY-MM-DD");
    setsetlectedWeek(weekday);
    setSelectedDate(formatedDate);
    // setDate(date)
    setTimeSelected({});
  };

  let instructions = order?.stylistInstructions;
  const dateArray = [0, 1, 2, 3, 4];

  const getOtp = otp => {
    let otpstring = otp?.toString();

    let otpArray = otpstring ? otpstring?.split("") : "";
    setOtp(otpArray);
  };
  useEffect(() => {
    if (activeOrder) {
      getAmounts(activeOrder?.biddings, activeOrder);
      getCompletedPaidAmount(activeOrder?.biddings, activeOrder);
      activeOrder.createdAt = MomentTimezone.tz(
        activeOrder.createdAt,
        activeOrder?.timeZone || "Asia/Calcutta"
      ).format("DD-MMMM-YYYY @ HH:mm A");
      activeOrder.date = MomentTimezone.tz(
        activeOrder.date,
        activeOrder?.timeZone || "Asia/Calcutta"
      ).format("DD-MMMM-YYYY");
      setOrder(activeOrder);
      getProvider(activeOrder?.biddings);
      if (activeOrder?.status === "Paid") {
        getRatings(activeOrder?._id);
      }
      getOtp(activeOrder?.biddings[0]?.otp);
    } else {
      setOrder(null);
    }
  }, [activeOrder]);

  const [newProviders, setNewProviders] = useState([]);
  const [newProviderOptions, setNewProviderOptions] = useState([]);
  const [newProviderSelected, setNewProviderSelected] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openBonusModal, setopenBonusModal] = useState(false);
  const toggle = () => setOpenModal(!openModal);
  const Bonustoggle = () => setopenBonusModal(!openBonusModal);
  useEffect(() => {
    setNewProviderSelected({
      label: provider.legalname,
      value: provider._id,
    });
  }, [provider]);

  const getProvidersByZipCode = async order => {
    let selectedServices = order.selectedList;
    if (
      selectedServices &&
      selectedServices.length &&
      selectedServices.filter(i => i.SelectedType == "Service").length
    ) {
      let tempData = [...selectedServices];
      // tempData =  tempData.filter((i)=>(i.SelectedType && (i.SelectedType == "Service")));
      tempData = tempData
        .filter(i => i.SelectedType && i.SelectedType === "Service")
        .map(item => ({
          _id: item._id,
          selectedServiceOptions: item?.serviceOptionsData?.flatMap(option => {
            if (option.type === "Single Select") {
              return [
                {
                  optionTitle: option.optionTitle,
                  optionID: option._id,
                  optionValueID: option.values.length
                    ? option.values[0]._id
                    : "",
                  optionValueName: option.values.length
                    ? option.values[0].name
                    : "",
                },
              ];
            } else {
              return option.values.map(value => ({
                optionTitle: option.optionTitle,
                optionID: option._id,
                optionValueID: value._id,
                optionValueName: value.name,
              }));
            }
          }),
        }));

      if (tempData && tempData.length) {
        // setServiceIdSelected(serviceIdSelected);
        getProviders(order.zipcode, tempData, order.timezone);
      } else {
        getProviders(order.zipcode, [], order.timezone);
      }
      setOpenModal(true);
    }
  };

  const getProviders = (zipcode, serviceIds, timezone) => {
    axios
      .post(
        `${process.env.REACT_APP_DATABASEURL}/api/v1/provider/getbyzipcode`,
        {
          zipcode: zipcode,
          services: serviceIds,
          timeZone: timezone || "Asia/Culcutta",
        }
      )
      .then(async res => {
        let options = res.data.data.map(provider => ({
          label: provider.legalname,
          value: provider._id,
        }));
        setNewProviders(res.data.data);
        setNewProviderOptions(options);
      });
  };

  useEffect(() => {
    if (order) {
      getProductTotal(order.status);
      getServiceTotal(order.status);
    }
  }, [order]);

  const getRatings = id => {
    getRatingsByOrderId(id)
      .then(res => {
        if (res?.success && res?.data?.length) {
          setRating(res.data[0]);
        } else {
          setRating(null);
        }
      })
      .catch(e => {
        console.log(e);
        setRating(null);
      });
  };

  const getProvider = biddings => {
    biddings?.forEach(ele => {
      setProvider(ele?.provider);
    });
  };

  const RenderEarnings = bidding => {
    return (
      <>
        {" "}
        <div className="row border-top border-3 py-2">
          <div className="col-md-12 d-flex align-items-center">
            <h6 className="font-size-15 mb-0  me-3">Total Amount Earned :</h6>
            <button className="btn btn-sm btn-success font-size-16">
              INR ₹{" "}
              {(
                Number(
                  bidding[0].totalServiceEarnings
                    ? bidding[0].totalServiceEarnings
                    : 0
                ) +
                Number(
                  bidding[0].totalProductSharePrice
                    ? bidding[0].totalProductSharePrice
                    : 0
                )
              )?.toFixed(2)}
            </button>
          </div>
        </div>
      </>
    );
  };

  const renderAddress = () => {
    let address, area, landmark, areaDetails;
    const checkAddress = order?.address?.includes("||");
    if (checkAddress) {
      address = order.address.split("||");
      area = address[1] && address[3] ? address[1] + "," + address[3] : "";
      areaDetails = address[1] ? address[1] : "";
      landmark = address[2] ? address[2] : "";
    }
    return (
      <>
        <div>
          {checkAddress ? (
            <>
              <h6 className="">{address[0]}</h6>
              <h6 className="">{area}</h6>
              <h6 className="">{landmark ? <>Landmark :{landmark}</> : ""}</h6>
            </>
          ) : (
            <h6 className="">{order?.address?.replaceAll("|", " ")}</h6>
          )}
        </div>
      </>
    );
  };

  const getAmounts = (biddings, order) => {
    let total = 0;
    let additionalCharges = 0;
    try {
      if (biddings?.length > 0) {
        biddings.forEach(service => {
          additionalCharges = service?.serviceStatus?.additionalCharges
            ? service?.serviceStatus?.additionalCharges
            : 0;
          const serviceAmount = service?.amount;
          const productAmount = service?.totalProductsPrice;
          setServiceAmount(serviceAmount);
          if (service?.totalProductsPrice) {
            total = serviceAmount + productAmount;
          } else total += serviceAmount;
        });
        let taxVal = 0;

        setSubTotal(total?.toFixed(2));
        setTax(taxVal?.toFixed(2));
        setTotalAmount((total + taxVal + additionalCharges)?.toFixed(2));
        setAdditional(additionalCharges?.toFixed(2));
      }
      if (order?.orderPaymentType == "PayProducts") {
        setAmountPaid(order?.orderPaymentDetails?.amountPaid);
        let due =
          Number(total)?.toFixed(2) -
          Number(order?.orderPaymentDetails?.amountPaid) +
          additionalCharges;
        setAmountDue(due?.toFixed(2));
      }
      if (order?.orderPaymentType == "PayNow") {
        setAmountPaid(order?.orderPaymentDetails?.amountPaid);
        let due =
          Number(total)?.toFixed(2) -
          Number(order?.orderPaymentDetails?.amountPaid) +
          additionalCharges;
        setAmountDue(due.toFixed);
      }
      if (order?.orderPaymentType == "PayLater") {
        setAmountPaid(order?.orderPaymentDetails?.amountPaid);
        let due = Number(total) + Number(additionalCharges);
        setAmountDue(due?.toFixed(2));
      }
    } catch (e) {
      console.log(e);
      setSubTotal("-");
      setTax("-");
      setTotalAmount("-");
      setAmountDue("-");
      setAmountPaid("-");
    }
  };

  const getProductTotal = status => {
    const productList = order?.selectedList.filter(
      item => item?.SelectedType == "Product"
    );
    const completedProductList =
      order?.biddings[0].serviceStatus.ServicesDetails.filter(
        item => item?.productid && item.status == "Completed"
      );
    const temp = productList?.filter(product =>
      completedProductList?.some(c => c.productid === product._id)
    );

    let total = 0;
    for (let product of temp || []) {
      total = total + product.quantity * parseFloat(product.price);
    }
    setProductTotal(total);
  };

  const getServiceTotal = () => {
    const completedServiceList =
      order?.biddings[0].serviceStatus.ServicesDetails.filter(
        item =>
          item?.serviceid &&
          item?.status ==
            (order?.status === "Paid" ? "Completed" : order?.status)
      );
    let total = 0;

    for (let service of completedServiceList) {
      const s = order?.selectedList[service.index];
      const thisService = order?.biddings[0].serviceid.filter(
        item => item?.index === service?.index
      );
      total = total + (s?.quantity * parseFloat(thisService?.[0].amount) || 0);
    }
    setServiceTotal(total);
  };

  const getCompletedPaidAmount = (biddings, order) => {
    order &&
    order?.biddings &&
    order?.biddings?.filter(
      i => i?.status == "Completed" || i?.status == "Paid"
    ).length
      ? order?.biddings
          .filter(i => i.status == "Completed" || i.status == "Paid")
          .map((bidding, index) => {
            let productsNotSelected = order.selectedList.map((i, iindex) => {
              if (i.SelectedType == "Product") {
                return {
                  productid: i._id,
                  ProductName: i.name,
                  index: iindex,
                  totalPrice: Number(i.quantity) * Number(i.price),
                  type: i.SelectedFrom,
                };
              }
            });
            productsNotSelected = productsNotSelected.filter(
              i => i != undefined
            );

            let productRes = productsNotSelected.filter(item1 =>
              bidding?.serviceStatus.ServicesDetails.some(
                item2 =>
                  item2.productid === item1.productid &&
                  item2.index === item1.index &&
                  item2.status != "Completed" &&
                  item1.type == "ShopProducts"
              )
            );
            let subtotal1;
            let refundAmount1 = 0;
            let dueAmount = 0;
            if (bidding && bidding?.serviceStatus) {
              if (order?.orderPaymentType == "PayProducts") {
                if (
                  bidding &&
                  bidding.serviceStatus &&
                  bidding.serviceStatus.ServicesDetails &&
                  bidding.serviceStatus.ServicesDetails.length &&
                  bidding.serviceStatus.ServicesDetails.filter(
                    i => i?.status != "Completed"
                  ) &&
                  bidding.serviceStatus.ServicesDetails.filter(
                    i => i?.status != "Completed"
                  ).length == 0
                ) {
                  subtotal1 =
                    Number(bidding.serviceStatus.TotalAmount) +
                    (bidding.serviceStatus.additionalCharges
                      ? Number(bidding.serviceStatus.additionalCharges)
                      : Number(0));
                  subAmt = subtotal1?.toFixed(2);
                  setSubAmt(subtotal1?.toFixed(2));
                } else {
                  subtotal1 =
                    Number(bidding.serviceStatus.TotalAmount) +
                    (bidding.serviceStatus.additionalCharges
                      ? Number(bidding.serviceStatus.additionalCharges)
                      : Number(0)) +
                    Number(
                      order?.orderPaymentDetails?.amountPaid
                        ? order?.orderPaymentDetails.amountPaid
                        : 0
                    ) -
                    (productRes && productRes.length
                      ? Number(
                          productRes
                            .map(item => Number(item.totalPrice))
                            .reduce((prev, curr) => prev + curr)
                        )
                      : Number(0));
                  subAmt = subtotal1?.toFixed(2);
                  setSubAmt(subtotal1?.toFixed(2));
                }
              } else {
                subtotal1 =
                  Number(bidding.serviceStatus.TotalAmount) +
                  (bidding.serviceStatus.additionalCharges
                    ? Number(bidding.serviceStatus.additionalCharges)
                    : Number(0)) +
                  (bidding?.tipAmount ? bidding?.tipAmount : Number(0));
                subAmt = subtotal1?.toFixed(2);
                setSubAmt(subtotal1?.toFixed(2));
              }

              if (order?.orderPaymentType == "PayNow") {
                if (
                  bidding &&
                  bidding.serviceStatus &&
                  bidding.serviceStatus.ServicesDetails &&
                  bidding.serviceStatus.ServicesDetails.length &&
                  bidding.serviceStatus.ServicesDetails.filter(
                    i => i?.status != "Completed"
                  ) &&
                  bidding.serviceStatus.ServicesDetails.filter(
                    i => i?.status != "Completed"
                  ).length == 0
                ) {
                  refundAmount1 =
                    Number(bidding.serviceStatus.TotalAmount) +
                    (bidding.serviceStatus.additionalCharges
                      ? Number(bidding.serviceStatus.additionalCharges)
                      : Number(0)) -
                    Number(
                      order?.orderPaymentDetails?.amountPaid
                        ? order?.orderPaymentDetails.amountPaid
                        : 0
                    );
                  refundAmt = refundAmount1;
                  setRefundAmt(refundAmount1?.toFixed(2));
                } else {
                  refundAmount1 =
                    Number(
                      order?.orderPaymentDetails?.amountPaid
                        ? order?.orderPaymentDetails.amountPaid
                        : 0
                    ) -
                    Number(bidding.serviceStatus.TotalAmount) +
                    (bidding.serviceStatus.additionalCharges
                      ? Number(bidding.serviceStatus.additionalCharges)
                      : Number(0));
                  refundAmt = refundAmount1;
                  setRefundAmt(refundAmount1?.toFixed(2));
                }
              }
              if (order?.orderPaymentType == "PayProducts") {
                if (
                  bidding &&
                  bidding.serviceStatus &&
                  bidding.serviceStatus.ServicesDetails &&
                  bidding.serviceStatus.ServicesDetails.length &&
                  bidding.serviceStatus.ServicesDetails.filter(
                    i => i?.status != "Completed"
                  ) &&
                  bidding.serviceStatus.ServicesDetails.filter(
                    i => i?.status != "Completed"
                  ).length == 0
                ) {
                  refundAmount1 = Number(0);
                  refundAmt = refundAmount1;
                  setRefundAmt(refundAmount1?.toFixed(2));
                } else {
                  if (productRes && productRes.length) {
                    refundAmount1 = Number(
                      productRes
                        .map(item => Number(item.totalPrice))
                        .reduce((prev, curr) => prev + curr)
                    );
                    refundAmt = refundAmount1;
                    setRefundAmt(refundAmount1?.toFixed(2));
                  } else {
                    refundAmount1 = Number(0);
                    refundAmt = refundAmount1;
                    setRefundAmt(refundAmount1?.toFixed(2));
                  }
                }
              }
              if (order?.orderPaymentType == "PayProducts") {
                if (
                  bidding &&
                  bidding.serviceStatus &&
                  bidding.serviceStatus.ServicesDetails &&
                  bidding.serviceStatus.ServicesDetails.length &&
                  bidding.serviceStatus.ServicesDetails.filter(
                    i => i?.status != "Completed"
                  ) &&
                  bidding.serviceStatus.ServicesDetails.filter(
                    i => i?.status != "Completed"
                  ).length == 0
                ) {
                  dueAmount =
                    Number(bidding.serviceStatus.TotalAmount) +
                    (bidding.serviceStatus.additionalCharges
                      ? Number(bidding.serviceStatus.additionalCharges)
                      : Number(0)) -
                    Number(
                      order?.orderPaymentDetails?.amountPaid
                        ? order.orderPaymentDetails.amountPaid
                        : 0
                    );
                  setAmtDue(dueAmount?.toFixed(2));
                } else {
                  dueAmount =
                    Number(bidding.serviceStatus.TotalAmount) +
                    (bidding.serviceStatus.additionalCharges
                      ? Number(bidding.serviceStatus.additionalCharges)
                      : Number(0));
                  setAmtDue(dueAmount?.toFixed(2));
                }
              } else {
                dueAmount =
                  Number(bidding.serviceStatus.TotalAmount) +
                  (bidding.serviceStatus.additionalCharges
                    ? Number(bidding.serviceStatus.additionalCharges)
                    : Number(0));
                setAmtDue(dueAmount?.toFixed(2));
              }
            }
          })
      : null;
  };

  const RenderNoOrder = () => {
    return (
      <Card className="p-3 pt-0 empty_order">
        <CardBody className="border-3">
          <div className="text-center">
            <i className="mdi mdi-arrow-left-circle" id="nullCatList"></i>
          </div>
          <p className="text-center">Please Select Order</p>
        </CardBody>
      </Card>
    );
  };

  const RenderAcceptandInProgress = (services, biddings) => {
    let arr3 = [];
    let productsArray;
    productsArray = services?.filter(ele => ele?.SelectedType === "Product");
    let servicesArray = services;
    for (let service of servicesArray) {
      for (let bidding of biddings) {
        for (let biddingService of bidding?.serviceid) {
          if (service?.uniqueId === biddingService?.index) {
            arr3.push({
              ...service,
              amount: biddingService?.amount,
              shareAmount: biddingService?.shareAmount,
            });
          }
        }
      }
    }

    let orderArray = [...arr3, ...productsArray];
    console.log(orderArray, "orderArray pedning");
    return (
      <>
        <ServiceTable serviceList={orderArray} />
      </>
    );
  };
  const renderOrderListCase1 = (services, biddings) => {
    let arrayService = [];
    let productArr = [];
    let uniqArray;
    let productUniqArray = [];
    let selectedProducts = [];
    services?.map((ele, ind) => {
      biddings?.map((item, i) => {
        productArr = services?.filter(ele => ele?.SelectedType == "Product");

        if (item?.status == "Paid" || item?.status == "Completed") {
          item.serviceid.some((ser, index) => {
            item?.serviceStatus?.ServicesDetails?.map((details, idx) => {
              if (
                ele._id === ser.serviceid &&
                details?.serviceid &&
                ser?.index == details?.index
              ) {
                arrayService.push({ ...ele, ...ser, ...details });
              }

              selectedProducts = item?.serviceStatus?.ServicesDetails?.filter(
                ele => ele?.productid
              );
            });

            productArr?.map(pro => {
              let obj;
              selectedProducts?.map(ele => {
                if (pro?._id == ele?.productid) {
                  obj = { ...pro, ["productDetails"]: ele };
                  productUniqArray.push(obj);
                }
              });
            });

            uniqArray = _.uniqBy(arrayService, function (e) {
              return e?._id;
            });

            productUniqArray = _.uniqBy(productUniqArray, function (e) {
              return e?.productDetails?.index;
            });
          });
        }
      });
    });

    return { uniqArray, productUniqArray };
  };

  const RenderCompleted = (services, biddings) => {
    let { uniqArray, productUniqArray } = renderOrderListCase1(
      services,
      biddings
    );
    let orderArray = [...uniqArray, ...productUniqArray];
    console.log(orderArray, "order Array");
    return (
      <>
        <OrderTable serviceList={orderArray} />

        <table className="table table-responsive table-sm table-bodered additional-charges">
          <tbody>
            <tr className="font-size-10">
              <td style={{ width: "33px" }}></td>
              <td className="pricetype" style={{ width: "40px" }}></td>
              <td className=" " style={{ width: "75px" }}>
                Additional Charges
              </td>
              <td className=" " style={{ width: "40px" }}></td>
              <td className=" " style={{ width: "40px" }}></td>
              <td className=" " style={{ width: "40px" }}></td>
              <td className="text-center " style={{ width: "60px" }}>
                ₹ {additional}
              </td>
              <td className=" " style={{ width: "50px" }}></td>
              <td className=" " style={{ width: "50px" }}></td>
              <td className=" " style={{ width: "50px" }}></td>
              <td className=" text-center" style={{ width: "50px" }}>
                ₹ {additional}
              </td>
              {order?.status == "Completed" ||
                (order?.status == "Paid" && (
                  <td className=" " style={{ width: "50px" }}></td>
                ))}
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const RenderPaymentDone = order => {
    return (
      <div className="d-flex justify-content-between border border-2 rounded p-2 ">
        <div className="d-flex">
          <i className="bx bx-md bx-check-circle text-success p-2"></i>
          {/* {order?.orderPaymentType == "PayLater" ? (
            <h6 className="p-2 mt-2">Payment is not yet done.</h6>
          ) : (
            ""
          )}
          {order?.orderPaymentType == "PayNow" ? (
            <h6 className="p-2 mt-2">
              Payment is done for Services and Products.
            </h6>
          ) : (
            ""
          )}
          {order?.orderPaymentType == "PayProducts" ? (
            <h6 className="p-2 mt-2">
              Payment done only for beauty shop products.
            </h6>
          ) : (
            ""
          )} */}
          {order?.status == "Paid" ? (
            <h6 className="p-2 mt-2">Payment is done</h6>
          ) : (
            <h6 className="p-2 mt-2">Payment is not yet done.</h6>
          )}
        </div>
        {order?.status == "Completed" || order?.status == "Paid" ? (
          <div>
            <button type="" className="btn btn-primary mt-2 ">
              Refund : ₹ {refundAmt}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
  const OnSubmit = () => {
    const servicesList = activeOrder?.selectedList.filter(
      service => service?.SelectedType === "Service"
    );
    let sumOfDuration = servicesList
      .map(item => Number(item.duration.split(" ")[0]) * item.quantity)
      .reduce((prev, curr) => prev + curr);
    sumOfDuration = Math.ceil(sumOfDuration / 30);

    let slots = [];
    let slotNumber = Number(timeSelected?.slotNumber);

    for (let j = 0; j < sumOfDuration; j++) {
      if (j == 0) {
        slots = [slotNumber];
        if (j + 1 == sumOfDuration) {
          if (slotNumber === 14 || slotNumber === 25) {
            slots.push(slotNumber + 2);
            slots.push(slotNumber + 3);
          } else {
            slots.push(slotNumber + 1);
            slots.push(slotNumber + 2);
          }
        }
      } else {
        let LastSlotNum = slots[slots.length - 1];
        if (LastSlotNum === 14 || LastSlotNum === 25) {
          slots.push(LastSlotNum + 2);
          if (j + 1 == sumOfDuration) {
            slots.push(LastSlotNum + 3);
            slots.push(LastSlotNum + 4);
          }
        } else {
          slots.push(LastSlotNum + 1);
          if (j + 1 == sumOfDuration) {
            slots.push(LastSlotNum + 2);
            slots.push(LastSlotNum + 3);
          }
        }
      }
    }

    let body = {
      orderID: activeOrder?._id,
      individual: timeSelected?.providerID,
      date: timeSelected?.date,
      time: timeSelected?.time,
      timeStamp: timeSelected?.timeStamp,
      timeZone: activeOrder?.timezone || "Asia/Calcutta",
      slots: slots,
    };
    UpdateStylistAvailability(body)
      .then(res => {
        if (res?.data?.success === true) {
          setsuccess_msg(true);
          setLoading(false);
          reload(activeOrder);
          getallOrders();
        }
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    toggle();
    setTimeSelected({});
    setSelectedDate(null);
    setsetlectedWeek(null);
  };

  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  let pendingStatus =
    order?.orderConfirmedByStylist === false &&
    order?.notAvailable?.notAvailable == false;

  let stylistnotavailable = order?.notAvailable?.notAvailable == true;

  if (!order) {
    return RenderNoOrder();
  }

  return (
    <React.Fragment>
      <Card className="">
        {" "}
        <CardBody className=" border-3 border-bottom pb-1">
          <Row>
            <Col md="6" className="">
              <div className="">
                <div className="d-flex justify-content-between mb-4 align-items-center">
                  <div className="">
                    <h6 className="font-size-18 fw-bold mb-0">
                      Order ID #{order?.orderId}
                    </h6>
                  </div>
                  {order?.status == "Completed" || order?.status == "Paid" ? (
                    <div>
                      <button
                        className={
                          "btn btn-sm font-size-12 text-white " +
                          getStatusBgClass(
                            " bg",
                            order?.status === "Accept" && pendingStatus
                              ? "Pending"
                              : stylistnotavailable
                              ? "Not available"
                              : order?.status
                          )
                        }
                      >
                        {order?.status === "Accept" && pendingStatus
                          ? "Pending"
                          : order?.status === "cancel"
                          ? "Cancelled"
                          : stylistnotavailable
                          ? "Not available"
                          : order?.status}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <h6 className="fw-bold">
                  Created On :
                  <span className="fw-bold"> {order?.createdAt}</span>
                </h6>
              </div>
              {order?.status == "Completed" || order?.status == "Paid" ? (
                <>
                  <div>
                    <h6 className="fw-bold">
                      Appointment On : {order?.date} @ {order?.time}
                    </h6>
                  </div>
                  <div className="d-flex align-items-center">
                    <p className="mb-0 fw-bold font-size-18">OTP :</p>
                    <div className="d-flex align-items-center ms-3">
                      {otp?.map((otp, index) => {
                        return (
                          <div key={index} className="otp-div">
                            <h6 className="mb-0 otp_num">{otp}</h6>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </Col>
            <Col md="6" className="border-start border-3">
              {order?.status == "Completed" || order?.status == "Paid" ? (
                <div className=" ">
                  <h5 className="">Stylist Earnings</h5>{" "}
                  {RenderEarnings(order?.biddings)}
                </div>
              ) : (
                <div className="d-flex justify-content-between align-items-center ">
                  <div>
                    <p className="mb-2">Appointment Date & Time </p>
                    <h6 className="">
                      {order?.date} @ {order?.time}
                    </h6>
                    <div className="d-flex align-items-center">
                      <p className="mb-0 fw-bold font-size-18">OTP :</p>
                      <div className="d-flex align-items-center ms-3">
                        {otp?.map((otp, index) => {
                          return (
                            <div key={index} className="otp-div">
                              <h6 className="mb-0 otp_num">{otp}</h6>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div>
                    <button
                      className={
                        "btn btn-sm text-white " +
                        getStatusBgClass(
                          "bg",
                          order?.status === "Accept" && pendingStatus
                            ? "Pending"
                            : stylistnotavailable
                            ? "Not available"
                            : order?.status
                        )
                      }
                    >
                      {order?.status === "Accept" && pendingStatus
                        ? "Pending"
                        : order?.status === "cancel"
                        ? "Cancelled"
                        : stylistnotavailable
                        ? "Not available"
                        : order?.status}
                    </button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
        <CardBody className=" border-3 border-bottom">
          <Row>
            <Col md="8" className=" border-3 mb-3">
              <div>
                <div className="bg-light p-1">
                  <p className="mb-0">Stylist Details</p>
                </div>
                {order?.notAvailable?.notAvailable && (
                  <div className="d-flex justify-content-end mb-2">
                    <a
                      className="btn btn-sm btn-primary mt-2 px-3"
                      onClick={() => {
                        getProvidersByZipCode(order);
                      }}
                      style={{
                        backgroundColor: "#ef4354",
                        border: "1px solid #ef4354",
                      }}
                    >
                      Change Stylist
                    </a>
                  </div>
                )}
                <div className="d-flex flex-wrap">
                  <ProfileImage
                    image={
                      provider?.image == "undefined" ? noImage : provider?.image
                    }
                    height="70"
                    width={70}
                    classNames="me-2 border"
                  />
                  <div>
                    <div className="d-flex align-items-center ">
                      <div>
                        <i className="bx bxs-user-circle me-1 font-size-14"></i>
                      </div>
                      <h6 className="mt-1 ">
                        {provider?.legalname || "-"}{" "}
                        {provider?.shortname || "-"}
                      </h6>
                    </div>

                    <div className="d-flex align-items-center ">
                      <div>
                        <i className="bx bxs-phone-call me-1 font-size-14"></i>
                      </div>
                      <h6 className="mt-1">
                        {provider?.mobileNumber ? provider?.mobileNumber : "-"}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              {!stylistnotavailable && (
                <div className="mb-2 border-start ps-2">
                  <OrderConfirmation
                    activeOrder={activeOrder}
                    getOrdersList={getallOrders}
                    getOrderById={reload}
                    Status={pendingStatus}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="12" className="">
              <div>
                <div className="bg-light p-1 mb-3">
                  <p className="mb-0">Customer Details</p>
                </div>
                <div className="d-flex">
                  <div className="me-2">
                    <ProfileImage
                      image={
                        order?.userid?.image &&
                        order?.userid?.image == "undefined"
                          ? noImage
                          : order?.userid?.image
                      }
                      height="70"
                      width={70}
                      classNames="border"
                    />
                  </div>
                  <div>
                    <div className="d-flex align-items-center">
                      <div>
                        <i className="bx bxs-user-circle me-1 font-size-14"></i>
                      </div>
                      <h6 className="mt-1 ">
                        {order?.userid?.firstname
                          ? order.userid?.firstname
                          : "-"}{" "}
                        {order?.userid?.lastname ? order.userid?.lastname : "-"}
                      </h6>
                    </div>

                    <div className="d-flex align-items-center ">
                      <div>
                        <i className="bx bxs-phone-call me-1 font-size-14"></i>
                      </div>
                      <h6 className="mt-1 ">
                        {order?.userid?.mobilenumber
                          ? order.userid?.mobilenumber
                          : "-"}
                      </h6>
                    </div>
                    <div className="d-flex align-items-center ">
                      <div>
                        <i className="bx bxs-envelope me-1 font-size-14"></i>
                      </div>
                      <h6 className="mt-1 ">
                        {order?.userid?.email ? order.userid?.email : "-"}
                      </h6>
                    </div>
                    <div className="d-flex  ">
                      <div>
                        <i className="bx bxs-home me-1 font-size-14"></i>
                      </div>
                      {renderAddress()}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardBody className="p-0">
          <div>
            {order?.status == "Accept" ||
            order?.status == "In Progress" ||
            order?.status == "cancel"
              ? RenderAcceptandInProgress(order?.selectedList, order?.biddings)
              : ""}
            {order?.status == "Completed" || order?.status == "Paid"
              ? RenderCompleted(order?.selectedList, order?.biddings)
              : ""}
          </div>

          {order?.status == "cancel" ? (
            <div>
              <div className="bg-light p-2">
                <h6>Reason for cancellation</h6>
              </div>
              <p className="p-2">{activeOrder?.CancelReason}</p>
            </div>
          ) : (
            ""
          )}
        </CardBody>
      </Card>
      <Card>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h5 className="p-2 mt-2 ms-2">Order Summary</h5>
          </div>

          <div className="px-3">
            <Button
              type="button"
              color="primary"
              className="btn-sm px-2"
              onClick={() => Bonustoggle()}
            >
              Add Bonus
            </Button>
          </div>
        </div>
        <CardBody className="border-top border-3 py-0 px-3">
          {" "}
          <div className="d-flex justify-content-start mb-0 mt-4 px-0">
            <div className="me-5">
              {order?.status == "Paid" ? (
                <p className="">Payment Method </p>
              ) : (
                ""
              )}
              <p className="">Services Amount</p>
              <p className="">Products</p>
              {additional ? (
                <p className="text-danger">Additional Charges</p>
              ) : (
                ""
              )}
              {activeOrder?.biddings[0]?.tipAmount ? (
                <p className="text-success fw-bold">Tip amount</p>
              ) : (
                ""
              )}
              <p className="">Taxes</p>
              {activeOrder?.notAvailable?.bonusToStylist ? (
                <p className="text-danger">Bonus To Stylist</p>
              ) : (
                ""
              )}
              {activeOrder?.discount ? (
                <p className="text-success">Discount</p>
              ) : (
                ""
              )}
              <p className="">Partner Amount</p>
              <p className="">
                <b>Grand Total</b>
              </p>
            </div>

            <div className="text-end ms-5">
              {order?.status == "Paid" ? (
                <p className="">
                  {order?.biddings[0]?.paymentDetails?.paymentType}{" "}
                </p>
              ) : (
                ""
              )}
              <p className="">
                ₹{" "}
                {order?.status == "Completed" || order?.status == "Paid"
                  ? serviceTotal?.toFixed(2)
                  : ServiceAmount?.toFixed(2) || 0}
              </p>

              <p className="">₹ {productTotal?.toFixed(2)}</p>
              {additional ? <p className="">₹ {additional}</p> : "0"}
              {activeOrder?.biddings[0]?.tipAmount ? (
                <p>₹ {(activeOrder?.biddings[0]?.tipAmount).toFixed(2)}</p>
              ) : (
                ""
              )}
              <p className=""> ₹ {"0"}</p>
              {activeOrder?.notAvailable?.bonusToStylist ? (
                <p className="text-danger">
                  {activeOrder?.notAvailable?.bonusToStylist
                    ? activeOrder?.notAvailable?.bonusToStylist?.toFixed(2)
                    : ""}
                </p>
              ) : (
                ""
              )}
              {activeOrder?.discount ? (
                <p className="text-success">
                  {" "}
                  {activeOrder?.discount?.discountAmount?.toFixed(2)}
                </p>
              ) : (
                ""
              )}
              <p className="">
                ₹{" "}
                {(
                  Number(
                    order?.biddings[0]?.totalServiceEarnings
                      ? order?.biddings[0]?.totalServiceEarnings
                      : 0
                  ) +
                  Number(
                    order?.biddings[0]?.totalProductSharePrice
                      ? order?.biddings[0]?.totalProductSharePrice
                      : 0
                  )
                )?.toFixed(2)}
              </p>

              {activeOrder?.discount ? (
                <p className="">
                  <b>
                    ₹{" "}
                    {order?.status == "Completed" || order?.status == "Paid"
                      ? parseFloat(subAmt)?.toFixed(2)
                      : (
                          parseFloat(totalAmount) -
                          activeOrder.discount.discountAmount
                        ).toFixed(2)}
                  </b>
                </p>
              ) : (
                <p className="">
                  <b>
                    ₹{" "}
                    {order?.status === "Completed" || order?.status === "Paid"
                      ? parseFloat(subAmt).toFixed(2)
                      : parseFloat(totalAmount).toFixed(2)}
                  </b>
                </p>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        {" "}
        <CardBody className="p-2">
          {" "}
          {order?.status != "cancel" ? RenderPaymentDone(order) : ""}
        </CardBody>
      </Card>
      <>
        {(instructions?.bewareDog === "No" &&
          instructions?.dontRingDoorBell === "No" &&
          instructions?.instructions === "No") ||
        (instructions?.instructions === "" &&
          instructions?.wearMask === "No" &&
          instructions?.withoutDoorMan === "No") ? (
          ""
        ) : (
          <Card className=" ">
            <h5 className="p-2 mt-2 ms-2">Instructions</h5>
            <CardBody className="border-top border-3">
              <div className="">
                {instructions?.wearMask == "Yes" ? (
                  <div className="d-flex align-items-center my-2">
                    {" "}
                    <img src={mask} />
                    <p className="mb-0 ms-2">Please wear mask</p>
                  </div>
                ) : (
                  ""
                )}
                {instructions?.callMe == "No" ? (
                  <div className="d-flex align-items-center my-2">
                    {" "}
                    <img src={call} />
                    <p className="mb-0 ms-2">Do not call me</p>
                  </div>
                ) : (
                  ""
                )}
                {instructions?.dontRingDoorBell == "Yes" ? (
                  <div className="d-flex align-items-center my-2">
                    <img src={ring} />
                    <p className="mb-0 ms-2">
                      Please don&apos;t ring the doorbell when you arrive
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {instructions?.withoutDoorMan == "Yes" ? (
                  <div className="d-flex align-items-center my-2">
                    <img src={door} />
                    <p className="mb-0 ms-2">
                      Please leave the delivery with our doorman
                    </p>
                  </div>
                ) : (
                  ""
                )}{" "}
                {instructions?.bewareDog == "Yes" ? (
                  <div className="d-flex align-items-center my-2">
                    {" "}
                    <img src={dog} />
                    <p className="mb-0 ms-2">Please Beware of Dog </p>
                  </div>
                ) : (
                  ""
                )}
                {instructions?.instructions && (
                  <div className="mt-3">
                    <h6>Additional Comments by the customer : </h6>
                    <p>{instructions?.instructions}</p>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        )}
      </>

      {rating ? (
        <Rating value={rating} />
      ) : (
        <div>
          <Card>
            <h5 className="p-2 mt-2 ms-2">Rating & Review</h5>
            <CardBody className="border-top border-3">
              There are no reviews
            </CardBody>
          </Card>
        </div>
      )}

      <OrderHistory
        biddingHistory={order?.biddingHistory}
        OrderHistory={order?.orderHistory}
      />
      <AdminNotesServiceOrders activeOrder={order} />
      <Modal isOpen={openModal} toggle={toggle} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>Update Stylist Availability</ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid>
            <Row>
              <Col md={5}>
                {newProviderOptions && newProviderOptions?.length ? (
                  <div>
                    <Select
                      name="parentCat"
                      id="parentCat"
                      value={newProviderSelected}
                      placeholder="Select Stylist..."
                      onChange={event => {
                        setNewProviderSelected(event);
                      }}
                      options={newProviderOptions}
                    />
                  </div>
                ) : (
                  <Card>
                    <CardBody>No Providers are available</CardBody>
                  </Card>
                )}
              </Col>
              <Col md={2}></Col>
              <Col md={5} className="">
                <Card className="">
                  <CardBody className="p-2">
                    <h6 className="fw-bold">
                      Current Appointment Date & Time :
                    </h6>
                    <h6 className="mb-0">
                      {order?.date} @ {order?.time}
                    </h6>
                    <div></div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="d-flex stylist_availability">
              {dateArray?.map((item, i) => {
                const newDate = moment(new Date()).add(i, "days");
                const abbreviatedWeekday = newDate.format("ddd");
                const fullWeekday = newDate.format("dddd").toLocaleLowerCase();
                const isToday = i === 0;
                const isTomorrow = i === 1;
                const isSelected = setlectedWeek === fullWeekday;
                return (
                  <div key={item} className="">
                    <div className="mt-3">
                      <Button
                        className={`btn-weekdays me-2 ${
                          isSelected ? "selected" : ""
                        }`}
                        onClick={() => handleButtonClick(fullWeekday, newDate)}
                      >
                        {abbreviatedWeekday}
                        <br />
                        {newDate?.format("DD")}
                        <br />
                        <span className="font-size-10">
                          {" "}
                          {isToday ? "Today" : isTomorrow ? "Tomorrow" : ""}
                        </span>
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
            {setlectedWeek ? (
              <Card className="mt-2 time_slots_div">
                <CardBody className="p-2">
                  <div className="d-flex flex-wrap">
                    {newProviders
                      .filter(
                        pitem => pitem?._id === newProviderSelected?.value
                      )
                      ?.map((pitem, index) => {
                        let weekAvailability =
                          pitem?.timeSlots?.weekAvailability?.[setlectedWeek]
                            ?.working;
                        if (weekAvailability) {
                          let timeStamp = moment(selectedDate).format("X");
                          let reservedSlots = pitem?.timeSlots?.reserved;
                          if (reservedSlots && reservedSlots[timeStamp]) {
                            reservedSlots = reservedSlots[timeStamp];
                          } else {
                            reservedSlots = [];
                          }
                          return Object.keys(timesSlots).map(
                            (slotNumber, i) => {
                              let selectedSlot =
                                timeSelected?.slotNumber === slotNumber;
                              let notAvailable = reservedSlots?.includes(
                                Number(slotNumber)
                              );
                              return (
                                <Button
                                  key={i}
                                  className={`Time_slots btn  me-2 mb-2 pt-2 ${
                                    selectedSlot ? "selectedSlot" : ""
                                  }`}
                                  style={{
                                    backgroundColor: reservedSlots.includes(
                                      Number(slotNumber)
                                    )
                                      ? "gray"
                                      : "white",
                                  }}
                                  onClick={() => {
                                    if (
                                      reservedSlots?.includes(
                                        Number(slotNumber)
                                      ) ||
                                      reservedSlots?.includes(
                                        Number(slotNumber) + 1
                                      )
                                    ) {
                                      alert(
                                        "There is not enough time as Stylist booked for other services. Please choose different time slot"
                                      );
                                    } else {
                                      setTimeSelected({
                                        time: timesSlots[slotNumber],
                                        providerID: pitem._id,
                                        date: selectedDate,
                                        slotNumber: slotNumber,
                                        timeStamp: timeStamp,
                                      });
                                    }
                                  }}
                                >
                                  {timesSlots[slotNumber]}
                                </Button>
                              );
                            }
                          );
                        } else {
                          return (
                            <div key={index} className="p-3">
                              Stylist is not available on this day
                            </div>
                          );
                        }
                      })}
                  </div>
                </CardBody>
              </Card>
            ) : (
              ""
            )}
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                color="light"
                className="w-md btn-sm me-2"
                onClick={leaveToList}
              >
                cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                className="w-md btn-sm float-end"
                onClick={OnSubmit}
              >
                Save
              </Button>
            </div>
          </Container>
        </ModalBody>
      </Modal>
      <UpdateBonus
        toggle={Bonustoggle}
        modal={openBonusModal}
        leaveToList={leaveToList}
        activeOrder={order}
        stayHere={stayHere}
      />
      <Spinner loading={loading} />
      {success_msg ? (
        <SuccessAlert
          msg={"Successfully Order Updated"}
          leaveToList={leaveToList}
          stayHere={stayHere}
        />
      ) : null}
      {error_msg ? (
        <ErrorAlert
          msg={"error"}
          leaveToList={leaveToList}
          stayHere={stayHere}
        />
      ) : null}
    </React.Fragment>
  );
};
