import React from "react";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, CardBody, Row, Col } from "reactstrap";
import { Badge } from "reactstrap";
import banner from "./avatar.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { getCustomers, deleteUser } from "services/user.service";
import { getUser } from "services/user.service";
import moment from "moment";
import SuccessAlert from "components/Alerts/succes";
import ErrorAlert from "components/Alerts/error";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

const UserIndex = () => {
  const badgeColor = " badge-soft-";
  const [userData, setUser] = useState({});
  const [customerData, setCustomerData] = useState([]);
  const [allList, setAllList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [activetab, setActivetab] = useState();
  console.log(activetab, "activetab");
  const handleSearch = search => {
    if (search) {
      let tempList = _.cloneDeep(allList);
      let filterList = tempList.filter(
        ele =>
          ele?.firstname?.toLowerCase().includes(search.toLowerCase()) ||
          ele?.lastname?.toLowerCase().includes(search.toLowerCase())
      );
      setCustomerData(filterList);
    } else {
      setCustomerData(allList);
    }
  };

  useEffect(() => {
    getCustomerList();
    // getUserById();
  }, []);

  const getCustomerList = () => {
    getCustomers()
      .then(res => {
        setCustomerData(res);
        setAllList(res);
        if (res.data) {
          setsuccess_msg(true);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        seterror_msg(true);
      });
  };
  const getUserById = id => {
    setLoading(true);
    getUser(id)
      .then(res => {
        setUser(res.data);
        console.log(res);
      })
      .catch(e => {
        console.log(e);
      });
  };
  const handleService = (ele, index) => {
    getUserById(ele._id);
  };
  const handleDelete = id => {
    deleteUser(id)
      .then(res => {
        console.log(res);
        getCustomerList();

        if (res.data) {
          setsuccess_msg(true);
          // getUserById();
        }
      })
      .catch(err => {
        console.log(err);
        seterror_msg(true);
      });
  };
  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };
  const renderUserView = () => {
    return (
      <div>
        {!userData && (
          <Card className="border-dark">
            <CardBody className="p-0">
              <div className="empty_list p-3 pt-0">
                <div className="text-center">
                  <i className="mdi mdi-arrow-left-circle" id=""></i>
                </div>
                <p className="text-center">Please Select User</p>
              </div>
            </CardBody>
          </Card>
        )}

        {userData && (
          <Card>
            <CardBody>
              <div className="float-end">
                <div className="text-end">
                  <Badge
                    className="font-size-12 badge-soft-info py-2 px-4 me-2"
                    color={"bg-info"}
                    pill
                  >
                    <i className="bx bx-user" />
                    &nbsp;
                    {userData.role}
                  </Badge>
                  <Badge
                    role="button"
                    className="font-size-12 badge-soft-danger py-2 px-3"
                    color={"bg-danger"}
                    pill
                    onClick={() => handleDelete(userData._id)}
                  >
                    <i className="bx bxs-trash-alt" />
                    &nbsp; Delete
                  </Badge>
                </div>
              </div>
              <Row>
                <Col md={4} className="">
                  <div className="text-center">
                    <img
                      src={banner}
                      alt=""
                      className="img-thumbnail profile rounded-circle"
                    />
                    <h5 className="mt-4 text-primary">
                      {userData.firstname}&nbsp;{userData.lastname}
                    </h5>
                  </div>
                </Col>
                <Col md={8} className=" pt-4 mb-4 px-0 user_detail">
                  <Row className="mx-3 ps-2">
                    <p className="ps-0">
                      <span className="pe-3 fw-bold">Email </span>{" "}
                      <span className="text-muted">{userData.email}</span>
                    </p>
                    <p className="ps-0 ">
                      <span className="pe-3 fw-bold">User ID </span>{" "}
                      <span className="text-muted">{userData._id} </span>
                    </p>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">First Name</p>
                      <p className="text-muted">{userData.firstname}</p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Last Name</p>
                      <p className="text-muted">{userData.lastname}</p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Status</p>

                      <Badge
                        className={
                          userData.status === "confirmed"
                            ? badgeColor + "success"
                            : badgeColor + "warning"
                        }
                        color={"bg-secondary"}
                        pill
                      >
                        {userData.status}
                      </Badge>
                    </Col>
                  </Row>

                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Created</p>
                      <p className="text-muted">
                        {moment(userData.createdAt).format("MMM Do YYYY")}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Last Updated</p>
                      <p className="text-muted">
                        {moment(userData.updatedAt).format("MMM Do YYYY")}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Mobile Number</p>
                      <p className="text-muted">{userData.mobilenumber}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </div>
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title></title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Users" breadcrumbItem="User List" />
          <BreadcrumbSub
            subtile="Users list"
            SubBreadcrumbItem="Customer Data"
          />
          <Row>
            <Col md="3">
              <div>
                <div className="search-box me-2 mb-2  ">
                  <div className="position-relative">
                    <i className="bx bx-search-alt search-icon" />
                    <input
                      className="form-control py-2 rounded-pill bg-white"
                      type="search"
                      id="example-search-input"
                      placeholder=" Search for Users"
                      onChange={event => handleSearch(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <Card>
                {customerData &&
                  customerData.map((ele, index) => {
                    const FixedClass = "border border-bottom-2 ";
                    const activeStatusClass =
                      activetab === ele?._id
                        ? FixedClass + "bg-lightGrey"
                        : FixedClass;
                    return (
                      <CardBody
                        role="button"
                        className={activeStatusClass}
                        key={ele._id}
                        onClick={() => {
                          handleService(ele, index);
                          setActivetab(ele?._id);
                        }}
                      >
                        <Row>
                          <Col md="2">
                            <img
                              src={banner}
                              height="50"
                              alt=""
                              className="fit-content rounded-circle avatar-sm  "
                            />
                          </Col>
                          <Col md="8">
                            <div className="ms-2">
                              <small className="h6">
                                {ele.firstname}&nbsp;
                                {ele.lastname}
                              </small>
                              <br />
                              <small className="text-secondary">
                                {ele.email}
                              </small>
                              <br />
                              <small className="text-secondary">
                                {moment(ele.createdAt).format("MMM Do YYYY")}
                              </small>
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="text-secondary float-end">
                              {/* <small className="float-end">Just now</small> */}
                              <div>
                                <Badge
                                  className={
                                    ele.status === "confirmed"
                                      ? badgeColor + "success"
                                      : badgeColor + "warning"
                                  }
                                  color={"bg-secondary"}
                                  pill
                                >
                                  {ele.status}
                                </Badge>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    );
                  })}
              </Card>
            </Col>
            <Col md="9">{renderUserView()}</Col>
          </Row>
        </Container>
      </div>
      {success_msg ? (
        <SuccessAlert
          msg={"Successfully User deleted"}
          leaveToList={leaveToList}
          stayHere={stayHere}
        />
      ) : null}
      {error_msg ? (
        <ErrorAlert
          msg={"Something went wrong"}
          leaveToList={leaveToList}
          stayHere={stayHere}
        />
      ) : null}
    </React.Fragment>
  );
};
export default UserIndex;
