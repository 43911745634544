const timesSlots = {
  1: "12:00 AM",
  2: "12:30 AM",
  3: "1:00 AM",
  4: "1:30 AM",
  5: "2:00 AM",
  6: "2:30 AM",
  7: "3:00 AM",
  8: "3:30 AM",
  9: "4:00 AM",
  10: "4:30 AM",
  11: "5:00 AM",
  12: "5:30 AM",
  13: "6:00 AM",
  14: "6:30 AM",
  15: "7:00 AM",
  16: "7:30 AM",
  17: "8:00 AM",
  18: "8:30 AM",
  19: "9:00 AM",
  20: "9:30 AM",
  21: "10:00 AM",
  22: "10:30 AM",
  23: "11:00 AM",
  24: "11:30 AM",
  // 25: "11:59 AM",
  25: "12:00 PM",
  26: "12:30 PM",
  27: "1:00 PM",
  28: "1:30 PM",
  29: "2:00 PM",
  30: "2:30 PM",
  31: "3:00 PM",
  32: "3:30 PM",
  33: "4:00 PM",
  34: "4:30 PM",
  // 35: "4:59 PM",
  35: "5:00 PM",
  36: "5:30 PM",
  37: "6:00 PM",
  38: "6:30 PM",
  39: "7:00 PM",
  40: "7:30 PM",
  41: "8:00 PM",
  42: "8:30 PM",
  43: "9:00 PM",
  44: "9:30 PM",
  45: "10:00 PM",
  46: "10:30 PM",
  47: "11:00 PM",
  48: "11:30 PM",
};

export default timesSlots;
