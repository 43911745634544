import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Step1 from "./step1";
import Step2 from "./step2";

import { createService } from "services/services.service";
const step1InitValues = {
    name: "",
    categories: "",
    parentCategory: "",
    price: "",
    discountPrice: "",
    priceUnit: "",
    customQuantityUnit: "",
    order: 0,
    duration: null,
    country: null,
    type: "",
  }
  const step2InitValues = {
    brands: [],
    products: [],
  }

const CreateStepperService = ({reloadServicesList, toggle, modal, parentCat,subCat }) => {
  const [step1Values, setStep1Values] = useState(step1InitValues);
  const [step2Values, setStep2Values] = useState(step2InitValues);

  const defaultEmptyValues = {
    description: "",
    tips: "",
    featured: "false",
    enableBooking: "false",
    popular: "false",
    banner: {},
    images: JSON.stringify([]),
    colorOptions: [],
    serviceOptions: [],
    status: "",
    tagline: "",
    precautions: "",
    benfits: "",
  };

  const [currentStepper, setCurrentStepper] = useState(1);

  const handleStepperBack = () => {
    setCurrentStepper(currentStepper - 1);
  };

  const handleSubmitStepper = values => {
    if (currentStepper == 1) {
      setStep1Values(values);
      let newCount = currentStepper + 1;
      setCurrentStepper(newCount);
    } else if (currentStepper == 2) {
      onSubmit(values);
    }
  };

  const onSubmit = values => {
    const body = { defaultEmptyValues, ...step1Values, ...values };
    let formData = new FormData();
    for (const key in body) {
      if (key === "country") {
        formData.set("country", JSON.stringify(body[key]));
      } else {
        formData.set(key, body[key]);
      }
    }
    createService(formData)
      .then(res => {
        if (res.success) {
          // alert("Successfully Created");
          //   setsuccess_msg(true);
          //   setLoading(false);
          setCurrentStepper(1);
          setStep1Values(step1InitValues)
          reloadServicesList()
        } else {
          console.log("Something went wrong");
          //   seterror_msg(true);
          //   setLoading(false);
        }
      })
      .catch(e => {
        alert("error", e);
        console.log(e, "error");
        seterror_msg(true);
      });
  };

  const renderSteper = () => {
    if (currentStepper == 1)
      return (
        <Step1
          step1Values={step1Values}
          // backStepper={handleStepperBack}
          handleSubmitStepper={handleSubmitStepper}
          parentCat={parentCat}
          subCat= {subCat}
        />
      );
    else if (currentStepper == 2)
      return (
        <Step2
          step2Values={step2Values}
          backStepper={handleStepperBack}
          handleSubmitStepper={handleSubmitStepper}
        />
      );
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>Create Service</ModalHeader>
        <ModalBody>{renderSteper()}</ModalBody>
        {/* <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Do Something
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter> */}
      </Modal>
    </>
  );
};

export default CreateStepperService;
