import React from "react";
import { Redirect } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Providers from "pages/Providers";
import Customers from "pages/Customers";

//ProviderManagement
import ProviderType from "pages/ProviderManagement/ProviderType";
import ProviderList from "pages/ProviderManagement/ProviderList";
import ProviderRequest from "pages/ProviderManagement/ProviderRequest";
import CreateProvider from "pages/ProviderManagement/CreateProvider";
import CreateList from "pages/Categories/list";
import CreateCategory from "pages/Categories/create";
import ViewCategory from "pages/Categories/ViewCategory";
import ServicesList from "pages/Services/list";
import CreateService from "pages/Services/create";
import BookingList from "pages/Bookings/list";
import RequestView from "pages/ProviderManagement/RequestView";
import CreateType from "pages/ProviderManagement/CreateProviderType";
import GalleryList from "pages/Galleries/GalleryList";
import CreateGallery from "pages/Galleries/CreateGallery";
import GlobalSettings from "pages/Settings/GlobalSettings";
import providerlist from "pages/Users/providerlist";
import ProviderView from "pages/ProviderManagement/providerView";

//User View"
import UserView from "./../pages/Users/UserView";
import CreateEmployee from "pages/Employees/CreateEmployee";
import ViewService from "pages/Services/ViewSevice";

import OrdersList from "./../pages/Orders/List";
import SettingsList from "pages/Settings/SettingsList";
import BrandList from "pages/Brand/list";
import ViewBrand from "./../pages/Brand/view";
import CreateBrand from "pages/Brand/create";
import ProductsList from "pages/Products/List";
import ViewProduct from "pages/Products/view";
import CreateProduct from "pages/Products/create";
import CreateParentcategory from "pages/ParentCategory/create";
import ParentCatList from "pages/ParentCategory/list";
import ViewParentCat from "pages/ParentCategory/view";
import ViewOrder from "pages/Orders/view";
import CreatePackage from "pages/Package/create";
import PackageList from "pages/Package/list";
import ViewPackage from "pages/Package/view";
import CreatePage from "pages/pages/create";
import PageList from "pages/pages/list";
import CreateSlide from "pages/slide/create";
import SlideList from "pages/slide/list";
import editSettings from "pages/globalSettings/edit";
import ZipcodeList from "pages/ZipCode/list";
import ZipcodeCreate from "pages/ZipCode/create";
import QuickServicesList from "pages/QuickServices/list";
import CreateQuickService from "pages/QuickServices/create";
import ViewQuickService from "pages/QuickServices/view";

// import ViewQuickService from "pages/QuickServices/view"

//Provider Register
import indvidualList from "pages/indvidual/list";
import ProviderRegisterIndividual from "pages/ProviderRegister/individual/individualCreate";
import CompanyRegister from "pages/ProviderRegister/company/companycreate";
import CompanyList from "pages/ProviderRegister/company/companylist";
import IndividualExpertise from "pages/ProviderRegister/individual/expertise";
import ServiceLocations from "pages/ProviderRegister/individual/serviceLocations";
import AddMember from "pages/ProviderRegister/company/addMember";
import JoinCompany from "pages/ProviderRegister/individual/info/joinCompany";
import IndividualInfo from "pages/ProviderRegister/individual/info/info";
import CompanyInfo from "pages/ProviderRegister/company/info/info";
import FileMan from "pages/FileManager";
import ServiceCost from "pages/ServiceCost";

// import { UserDetails } from "pages/Users2/UserDetails"
import ParentCategoryNew from "pages/ParentCategoryNew";
import SubCategorieIndex from "pages/SubCategorieNew";
//Ecommerce
import ListBrand from "pages/Ecommerce/Brand/list";
import BrandCreate from "pages/Ecommerce/Brand/Create";
import CreateAttributes from "pages/Ecommerce/ProductAttributes/CreateAttribute";
import ListAttribute from "pages/Ecommerce/ProductAttributes/list";
import ProductsIndex from "pages/Ecommerce/Products";
import ProductsListEcommerce from "pages/Ecommerce/Products/List";
import ProductCategoryCreate from "pages/Ecommerce/ProductCategory/Create";
import ProductCatListEcommerce from "pages/Ecommerce/ProductCategory/List";
import UserIndex from "pages/Users2";
import EcommerceProducts from "pages/Ecommerce/EcommerceProducts/index";
import EcommerceProductDetail from "pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
import Ecomcategories from "pages/Ecommerce/E-categories";
import { Reviews } from "pages/Ecommerce/Reviews";
import { RatingsQuestions } from "pages/RatingQuestions";
import ProductEditIndex from "pages/Ecommerce/Products/ProductEditIndex";
import EcomOrders from "pages/Ecommerce/Orders";
import CreateArticle from "pages/Blogs/create";
import ArticleIndex from "pages/Blogs";
import LabelsPage from "pages/MobileLabels/LabelsPage";
import Authorsindex from "pages/Authors";
import IndvidualListNew from "pages/Individual-New/list";
import ArtistList from "pages/Artist/list";
import ArtistInfo from "pages/Artist/info";
import CompaniesList from "pages/Company/list";
import ArtistForms from "pages/ArtistQuoteForms";
import ArtistQuoteView from "pages/ArtistQuoteForms/View";
import PaymentList from "pages/Payment/list";
import ContactUsUsers from "pages/Contact-us";

// import CreateCoupon from "pages/CouponManagement/Create";
import CouponLists from "pages/CouponManagementt";
import CouponsCount from "pages/CouponManagementt/CouponsCount";
import SettlementList from "pages/Settlements/list";
import ExceptionsSummary from "pages/App-Exceptions";
import ApiExceptionsList from "pages/App-Exceptions/api";
import AppExceptionsList from "pages/App-Exceptions/app";
import SettlementsByCode from "pages/Settlements/SettlementsByCode";
import SettlementsSummaryStylist from "pages/Settlements";
import SettlementsSummaryCompany from "pages/Settlements/summaryCompany";
import AvatarList from "pages/Avatar";
import Order_Details_index from "pages/All-Order-Details";
import AdminOrdersList from "pages/Orders-v2";
import SlotsIndex from "pages/StylistSlots/SlotsIndex";


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/User-list", component: Customers },

  { path: "/category-list", component: CreateList },
  { path: "/category-create", component: CreateCategory },
  { path: "/category-update/:id", component: CreateCategory },
  { path: "/category-view/:id", component: ViewCategory },
  { path: "/Services-list", component: ServicesList },
  { path: "/Services-create", component: CreateService },
  { path: "/services-update/:id", component: CreateService },
  { path: "/services-view/:id", component: ViewService },
  { path: "/bookings-list", component: BookingList },
  { path: "/create-type", component: CreateType },
  { path: "/gallery-list", component: GalleryList },
  { path: "/create-gallery", component: CreateGallery },
  { path: "/edit-gallery/:id", component: CreateGallery },
  { path: "/global-settings", component: SettingsList },
  { path: "/create-settings", component: GlobalSettings },
  { path: "/edit-settings/:id", component: GlobalSettings },
  { path: "/payments-list", component: PaymentList },
  { path: "/create-user", component: CreateEmployee },
  { path: "/edit-user/:id", component: CreateEmployee },

  { path: "/packages-list", component: PackageList },
  { path: "/packages-create", component: CreatePackage },
  { path: "/packages-update/:id", component: CreatePackage },
  { path: "/package-view/:id", component: ViewPackage },

  { path: "/app-settings", component: editSettings },
  { path: "/page-create", component: CreatePage },
  { path: "/page-update/:id", component: CreatePage },
  { path: "/page-list", component: PageList },

  { path: "/slide-create", component: CreateSlide },
  { path: "/slide-update/:id", component: CreateSlide },
  { path: "/slide-list", component: SlideList },

  { path: "/avatar-list", component: AvatarList },

  // { path: "/orders", component: OrdersList },
  { path: "/orders", component: AdminOrdersList },
  { path: "/order/:id", component: ViewOrder },

  { path: "/providers", component: providerlist },

  { path: "/providers", component: Providers },
  { path: "/provider-type", component: ProviderType },
  { path: "/update-type/:id", component: CreateType },
  { path: "/provider-list", component: ProviderList },
  { path: "/provider-request", component: ProviderRequest },
  { path: "/create-provider", component: CreateProvider },
  { path: "/provider-update/:id", component: CreateProvider },
  { path: "/provider-view/:id", component: ProviderView },

  // //profile
  { path: "/profile", component: UserProfile },

  //user
  { path: "/user-view/:id", component: UserView },
  { path: "/request-view", component: RequestView },

  //Brands
  { path: "/brands", component: BrandList },
  { path: "/brand-view/:id", component: ViewBrand },
  { path: "/brand-create", component: CreateBrand },
  { path: "/brand-update/:id", component: CreateBrand },

  //Products
  { path: "/products", component: ProductsList },
  { path: "/product-view/:id", component: ViewProduct },
  { path: "/product-create", component: CreateProduct },
  { path: "/product-update/:id", component: CreateProduct },

  //sub-category
  { path: "/parentcategory-list", component: ParentCatList },
  { path: "/parentcategory-create", component: CreateParentcategory },
  { path: "/parentcategory-update/:id", component: CreateParentcategory },
  { path: "/parentcategory-view/:id", component: ViewParentCat },

  //Zip Code
  { path: "/zipcode", component: ZipcodeList },
  { path: "/zipcode-create", component: ZipcodeCreate },
  { path: "/zipcode-update/:id", component: ZipcodeCreate },

  //Quick Services Code
  { path: "/quick-services-list", component: QuickServicesList },
  { path: "/quick-service-create", component: CreateQuickService },
  { path: "/quick-service-update/:id", component: CreateQuickService },
  { path: "/quick-service-view/:id", component: ViewQuickService },

  //Provider Register
  {
    path: "/Provider-Register-individul",
    component: ProviderRegisterIndividual,
  },
  { path: "/Provider-Register-company", component: CompanyRegister },
  // { path: "/Provider-list-individul", component: indvidualList },
  { path: "/Provider-list-individul", component: IndvidualListNew },
  { path: "/Provider-list-company", component: CompaniesList },
  // { path: "/Provider-list-company", component: CompanyList },

  { path: "/FileMan", component: FileMan },

  // Artist
  { path: "/Provider-list-artist", component: ArtistList },
  { path: "/artist/:id", component: ArtistInfo },
  { path: "/Artist-Forms", component: ArtistForms },
  // { path: "/ArtistForm-View/:id", component: ArtistQuoteView },

  { path: "/service-cost", component: ServiceCost },

  // { path: "/users2/:id", component: UserDetails },
  { path: "/parentcategory-new", component: ParentCategoryNew },
  //users2
  { path: "/users2", component: UserIndex },

  { path: "/SubCategorieNew", component: SubCategorieIndex },

  { path: "/company-addMember/:id", component: AddMember },
  { path: "/company-info/:id", component: CompanyInfo },

  { path: "/individul-expertise/:id", component: IndividualExpertise },
  { path: "/individul-locations/:id", component: ServiceLocations },
  { path: "/individul-info/:id", component: IndividualInfo },
  { path: "/individul-join-company/:id", component: JoinCompany },
  //Ecommerce
  { path: "/list-brand", component: ListBrand },
  { path: "/create-brand", component: BrandCreate },
  { path: "/list-attribute", component: ListAttribute },
  { path: "/product-create-attributes", component: CreateAttributes },
  { path: "/product-create-index", component: ProductsIndex },
  { path: "/products-list", component: ProductsListEcommerce },
  { path: "/product/:id", component: ProductEditIndex },
  { path: "/product/product-create/:catId", component: ProductEditIndex },
  { path: "/products-category-list", component: ProductCatListEcommerce },
  { path: "/products-category-create", component: ProductCategoryCreate },
  { path: "/Reviews", component: Reviews },
  { path: "/ecom-products", component: EcommerceProducts },
  { path: "ecommerce-product-detail", component: EcommerceProductDetail },
  { path: "/e-categories", component: Ecomcategories },
  { path: "/ecom-orders", component: EcomOrders },
  { path: "/articles", component: ArticleIndex },
  { path: "/ratings-questions", component: RatingsQuestions },
  { path: "/labels", component: LabelsPage },
  { path: "/Authors", component: Authorsindex },
  {
    path: "/settlements-summary-individual",
    component: SettlementsSummaryStylist,
  },
  {
    path: "/settlements-summary-company",
    component: SettlementsSummaryCompany,
  },
  { path: "/settlements/:id", component: SettlementList },
  { path: "/exceptions/app", component: AppExceptionsList },
  { path: "/exceptions/api", component: ApiExceptionsList },
  { path: "/exceptions", component: ExceptionsSummary },
  //Individual Slots
  { path: "/Slots", component: SlotsIndex },
  //contact-us
  { path: "/contact-us-users", component: ContactUsUsers },

  //Coupons
  { path: "/Coupon-mangement", component: CouponLists },
  { path: "/Coupon-users/:code", component: CouponsCount },
  { path: "/SettlementsByCode/:id", component: SettlementsByCode },
  { path: "/Order_details", component: Order_Details_index },
  // { path: "/Create-Coupon", component: CreateCoupon },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];
const privateRoutes = [];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { publicRoutes, authProtectedRoutes, privateRoutes };
