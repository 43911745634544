export const getServices = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/service/all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      // console.log(res);
      return res.json();
    })
    .catch(e => console.log(`error at get all services ${e}`));
};
export const getServicesById = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v2/service/admin/get/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get service by id ${e}`));
};

export const createService = body => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/service/create`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: body,
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Service Create ${e}`));
};

export const updateService = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/service/update/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Servce Update ${e}`));
};
export const updatefeildService = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/service/updatefeild/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Servce Update feild ${e}`));
};
export const updatefilefeildService = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/service/updatefilefeild/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Servce Update file feild ${e}`));
};
export const multiService = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/service/multiservices`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Servce Update ${e}`));
};
export const deleteService = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/service/delete/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get service ${e}`));
};

export const getServicesByCountry = country => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/service/country/${country}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => res.json())
    .catch(e => console.log(`error at get service by country ${e}`));
};

export const getServicesByCountryType = (country, type) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/service/expertise/${country}/${type}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => res.json())
    .catch(e => console.log(`error at get service by type ${e}`));
};
export const getServicesByQuery = body => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/service/query`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at service query ${e}`));
};
export const getServicesByCategories = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/service/byCategories`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at service query ${e}`));
};

export const CreateServiceProduct = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/serviceProducts/create`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at creating service product ${e}`));
};
export const UpdateServiceProduct = (id, body) => {
  console.log("Request Payload:", body); // Log the request payload
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/serviceProducts/update/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      console.log(res, "resssssssssss");
      return res.json();
    })
    .catch(e => console.log(`error at Service Update file field ${e}`));
};

export const UpdateService = (id, body) => {
  console.log("Request Payload:", body); // Log the request payload
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v2/service/update/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      console.log(res, "resssssssssss");
      return res.json();
    })
    .catch(e => console.log(`error at Service Update file field ${e}`));
};