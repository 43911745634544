const getStatusByBgClass = (prefix, status) => {
  if (status === "Completed") return prefix + "-success";
  else if (status === "Paid") return prefix + "-info";
  else if (status === "Confirmed") return prefix + "-secondary";
  else if (status === "In Progress" || status === "InProgress")
    return prefix + "-warning";
  else if (status === "Pending") return prefix + "-pending";
  else return prefix + "-danger";
};

export { getStatusByBgClass };
