import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ChangeStylist from "./ChangeStylist";
const OrderDetailsTable = ({
  serviceList,
  activeOrder,
  getallOrders,
  getOrderById,
  toggleModel
}) => {
  console.log(activeOrder,"activeOrder new")
  const columns = [
    {
      dataField: "serviceList",
      text: "SI. No",
      formatter: (cell, row, rowIndex) => (
        <div className="text-center">{rowIndex + 1}</div>
      ),
    },
    {
      dataField: "provider",
      text: "Stylist",
      formatter: (cell, row) => {
        console.log(row, "rowwww");
        return (
          <div className="text-center mt-2">
            <img
              src={row?.providerid?.image}
              alt=""
              width="40px"
              height="40px"
              className="rounded"
            />
            <p className="mt-1 mb-1">
              {row?.providerid?.legalname} {row?.providerid?.shortname}
            </p>
            {activeOrder?.status === "Pending" ||
            activeOrder?.status === "Confirmed" ? (
              <div className="mb-1">
                <ChangeStylist
                  activeOrder={activeOrder}
                  selectedRow={row}
                  getallOrders={getallOrders}
                  getOrderById={getOrderById}
                  toggleModel={toggleModel}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      dataField: "serviceid",
      text: "Description",
      formatter: (cell, row) => {
        return (
          <div>
            <div className="text-start d-flex">
              <div className="me-1 rounded">
                <img
                  src={row?.serviceid?.images[0]}
                  alt=""
                  width="30px"
                  height="30px"
                  className="boarder boarder-2 rounded"
                />
              </div>
              <div>
                {row.serviceid.name}
                <span className="fw-bold">
                  {row.serviceid.type === "Female"
                    ? " - Women"
                    : row.serviceid.type === "Male"
                    ? " - Men"
                    : ""}
                </span>
              </div>
            </div>

            {row?.selectedProduct?.length && row?.selectedProduct[0]?.name ? (
              <div>
                <div>
                  <hr className="my-2" />
                </div>
                <div>
                  {row?.selectedProduct?.length &&
                  row?.selectedProduct[0]?.name ===
                    "I will provide the Product" ? (
                    <span className="">
                      {row?.selectedProduct?.length &&
                        row?.selectedProduct[0]?.name}
                    </span>
                  ) : (
                    <div>
                      {row?.selectedProduct?.map((prod, inx) => {
                        return (
                          <div key={inx}>
                            <div>
                              <span className="fw-bold">
                                {prod?.product?.name}
                              </span>
                              <div className="d-flex align-items-center mt-2">
                                <img
                                  src={prod?.variants?.image}
                                  className="border rounded"
                                  alt=""
                                  width={40}
                                  height={40}
                                />
                                <div className="ms-2">
                                  {/* {row?.selectedBrandsData?.variantSelected ? (
                          <span>
                            {row?.selectedBrandsData?.variantSelected[0]?.type}{" "}
                            :{" "}
                          </span>
                        ) : (
                          ""
                        )} */}
                                  <span>Color : </span>
                                  <span className="fw-bold">
                                    {prod?.variants?.name}
                                  </span>
                                  <br />
                                  <span>Price : </span>
                                  {/* <span className="fw-bold">
                          {row?.selectedBrandsData &&
                          typeof row?.selectedBrandsData
                            ?.finalShadeProductPrice === "number"
                            ? row?.selectedBrandsData?.finalShadeProductPrice?.toFixed(
                                2
                              )
                            : typeof row?.selectedBrandsData
                                ?.finalShadeProductPrice === "string"
                            ? row?.selectedBrandsData?.finalShadeProductPrice
                            : "0"}
                        </span> */}
                                  <br />
                                  <span>Brand : </span>
                                  {/* <span className="fw-bold">
                          {row?.selectedBrandsData?.variantSelected
                            ? row?.selectedBrandsData?.variantSelected[0]
                                ?.selectedBrand?.name
                            : ""}
                        </span> */}
                                  <br />
                                  <span>Service Price : </span>
                                  {/* <span className="fw-bold">{servivePrice}</span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {row?.selectedOption?.length &&
            row?.selectedOption[0]?.optionTitle ? (
              <div>
                <div>
                  <hr className="my-2" />
                </div>
                <div>
                  {row?.selectedOption &&
                    row?.selectedOption?.map((ele, index) => {
                      return (
                        <div key={index}>
                          <h6>{ele?.optionTitle}</h6>
                          {Array.isArray(ele?.values) ? (
                            ele?.values?.map((option, ind) => (
                              <div
                                key={ind}
                                className="d-flex align-items-center"
                              >
                                <div className="me-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input checkbox mb-2"
                                    id={`checkbox-${index}-${ind}`}
                                    checked={true}
                                    disabled
                                  />
                                </div>
                                <div className="mb-2">
                                  <span className="text-dark">
                                    {option?.name}
                                  </span>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="d-flex align-items-center">
                              <div className="me-2">
                                <input
                                  type="checkbox"
                                  className="form-check-input checkbox mb-2"
                                  id={`checkbox-${index}`}
                                  checked={true}
                                  disabled
                                />
                              </div>
                              <div className="mb-2">
                                <span className="text-dark">
                                  {ele?.values?.name}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      dataField: "type",
      text: "Type",
      formatter: (cell, row) => (
        <div className="text-center">
          {row?.postServiceAddition === false
            ? "Service"
            : "Additional Service"}
        </div>
      ),
    },
    {
      dataField: "quantity",
      text: "Qty",
      formatter: (cell, row) => (
        <div className="text-center">{row?.quantity}</div>
      ),
    },
    {
      dataField: "amt",
      text: "Unit Price",
      formatter: (cell, row, amount) => {
        return (
          <div className="text-center">
            ₹{(parseFloat(row?.amount) / parseFloat(row?.quantity))?.toFixed(2)}
          </div>
        );
      },
    },
    // {
    //   dataField: "netAmt",
    //   text: "Net Amount",
    //   formatter: (cell, row) => (
    //     <div className="text-center">
    //       ₹{parseFloat(row?.amount)?.toFixed(2)}
    //     </div>
    //   ),
    // },
    // {
    //   dataField: "taxType",
    //   text: "Tax Type",
    //   //   formatter: quantity => <div className="text-center">{quantity || "_"}</div>,
    // },
    // {
    //   dataField: "shippingCost",
    //   text: "Tax Amount",
    //   //   formatter: (cell, row) => <div className="text-center">0</div>,
    // },
    // {
    //   dataField: "shareAmount",
    //   text: "Partner Earnings",
    //   //   formatter: shareAmount => (
    //   //     <div className="text-center">
    //   //       ₹ {shareAmount ? parseFloat(shareAmount).toFixed(2) : 0}
    //   //     </div>
    //   //   ),
    // },
    {
      dataField: "totalAmt",
      text: "Total Amount",
      formatter: (cell, row) => (
        <div className="text-center">
          ₹{parseFloat(row?.amount)?.toFixed(2)}
        </div>
      ),
    },
    {
      dataField: "Status",
      text: "Status",
      formatter: (cell, row) => {
        let status;

        if (row?.status == "Complete" || row?.status == "Paid") {
          status = (
            <i className="bx bxs-check-circle text-success font-size-18"></i>
          );
        } else if (row?.status == "Cancelled") {
          status = <i className="bx bxs-x-circle text-danger font-size-18"></i>;
        } else status = <div className="text-warning">{row?.status}</div>;
        return (
          <div className="text-center">
            {status}
            {row?.status == "Cancelled" ? (
              <div className="mb-0 text-start">
                <div>
                  <hr className="my-2" />
                </div>
                <p className="mb-0 fw-bold">Cancelled Reason :</p>
                <p>{row?.cancelReason}</p>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];
  const pageOptions = {
    sizePerPage: 10,
    totalSize: serviceList?.length,
    custom: true,
  };
  return (
    <Row>
      <Col xs="12">
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="_id"
          columns={columns}
          data={serviceList}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="_id"
              data={serviceList}
              columns={columns}
              bootstrap4
              search
            >
              {toolkitProps => (
                <>
                  <div className="table-responsive order-details-table table-sm">
                    <BootstrapTable
                      keyField="id"
                      responsive
                      bordered={true}
                      striped={false}
                      classes={
                        "table align-middle table-nowrap table-check mb-0"
                      }
                      headerWrapperClasses={"table-light"}
                      {...toolkitProps.baseProps}
                      {...paginationTableProps}
                    />
                  </div>
                </>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
        {serviceList && serviceList.length === 0 && (
          <p className="text-center">No Data Found</p>
        )}
      </Col>
    </Row>
  );
};

export default OrderDetailsTable;
