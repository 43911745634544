import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import axios from "axios";
import { EndTimeSlots, StartTimeSlots } from "utils/Availability";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { RadioButton } from "components/RadioButton";
import timesSlots from "utils/TimeSlots";

export const SlotsModal = ({
  modal,
  toggle,
  individualsList,
  getAllArtistForms,
  usersList,
}) => {
  console.log(usersList, "userList");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [providers, setProviders] = useState([]); // Update to handle multiple providers
  const [selectedStylist, setSelectedStylist] = useState([]);
  const [serviceDescription, setServiceDescription] = useState("");
  const [eventTitle, seteventTitle] = useState("");
  const [address, setAddress] = useState("");
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [genderFilter, setGenderFilter] = useState("all");
  const [IndividualList, setIndividualList] = useState({});
  const [selectedUser, setSelectedUser] = useState({});

  const startTimeOptions = StartTimeSlots.map(slot => ({
    label: slot,
    value: slot,
  }));
  const endTimeOptions = EndTimeSlots.map(slot => ({
    label: slot,
    value: slot,
  }));

  useEffect(() => {
    // Set the initial list to all individuals on the first render
    setIndividualList(individualsList);
  }, [individualsList]);

  function handleProviders(selectedMulti) {
    setProviders(selectedMulti || []); // Store multiple selections as an array
  }

  const checkStylistAvailability = async (stylistId, date) => {
    const timeStamp = moment(date).format("X"); // Convert date to Unix timestamp
    try {
      // Fetch stylist availability
      const response = await axios.get(
        `https://dev.stylify.com/api/v1/slots/get/${stylistId}`
      );
      const { specialDays, data } = response?.data?.data[0];
      if (specialDays[timeStamp] && specialDays[timeStamp].working === false) {
        alert("Selected stylist not available, please select another stylist.");
        setProviders([]);
        return false;
      } else {
        setProviders(selectedStylist);
      }

      // Check availability in weekAvailability if specialDays doesn't cover the date
      const weekday = moment(date).format("dddd").toLowerCase();
      const weekAvailability = data[0]?.weekAvailability[weekday];

      if (!weekAvailability.working) {
        alert("Selected stylist not available, please select another stylist.");
        return false;
      }

      return true;
    } catch (error) {
      console.error("Error fetching availability:", error);
      return false;
    }
  };

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const getFilteredStylists = value => {
    if (value === "all") {
      setIndividualList(individualsList);
    } else {
      const filteredStylists = individualsList.filter(
        stylist => stylist?.gender === value
      );
      setIndividualList(filteredStylists);
    }
  };

  function handleSelectStatus(event) {
    console.log(event.target.value,"vallll")
    setGenderFilter(event.target.value);
    getFilteredStylists(event.target.value);
  }

  const handleChangeUser = event => {
    setSelectedUser(event);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Successfully Event Created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  const leaveToList = () => {
    toggle();
    setsuccess_msg(false);
    seterror_msg(false);
    setSelectedStartTime(null);
    setSelectedEndTime(null);
    setSelectedDate(null);
    setAddress("");
    seteventTitle("");
    setServiceDescription("");
    setProviders([]);
  };

  const handleSubmit = async () => {
    const startTimeSlot = Object.keys(timesSlots).find(
      key => timesSlots[key] === selectedStartTime?.value
    );
    const endTimeSlot = Object.keys(timesSlots).find(
      key => timesSlots[key] === selectedEndTime?.value
    );

    const slots = [];
    if (startTimeSlot && endTimeSlot) {
      for (let i = Number(startTimeSlot); i <= Number(endTimeSlot); i++) {
        slots.push(Number(i));
      }
    }

    const formattedProviders = providers?.map(provider => ({
      providerid: provider.value,
    }));

    const payload = {
      eventDate: selectedDate ? selectedDate.toISOString() : null,
      eventTime: selectedStartTime?.value || null,
      eventTitle: eventTitle,
      address: address,
      slots: slots,
      providers: formattedProviders,
      userId: selectedUser?.value,
      serviceDescription: serviceDescription,
      status: "Open",
    };

    try {
      const response = await axios.post(
        "https://dev.stylify.com/api/v2/events/create",
        payload
      );
      setsuccess_msg(true);
      getAllArtistForms();
    } catch (error) {
      console.error("Error creating event:", error);
      seterror_msg(true);
    }
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="lg" centered>
        <ModalHeader toggle={toggle}>Create Event</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <Label htmlFor="order" className="fw-bold">
                Event Title
              </Label>
              <FormGroup>
                <Input
                  type="text"
                  name="event"
                  className="form-control"
                  id="event"
                  placeholder="Enter event title"
                  value={eventTitle}
                  onChange={e => seteventTitle(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <h6 className="fw-bold">Start time</h6>
              <Select
                name="startTime"
                id="startTime"
                value={selectedStartTime}
                onChange={setSelectedStartTime}
                options={startTimeOptions}
              />
            </Col>
            <Col md={3}>
              <h6 className="fw-bold">End time</h6>
              <Select
                name="endTime"
                id="endTime"
                value={selectedEndTime}
                onChange={setSelectedEndTime}
                options={endTimeOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label htmlFor="order" className="fw-bold">
                Select Date
              </Label>
              <FormGroup className="form_group mt-1">
                <DatePicker
                  name="startDate"
                  dateFormat="dd-MMM-yyyy"
                  className="form-control"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dropdownMode="select"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <Label htmlFor="order" className="fw-bold">
                Service Details
              </Label>
              <FormGroup className="mt-1">
                <Input
                  type="text"
                  name="service"
                  className="form-control"
                  id="service"
                  placeholder="Enter Service Details"
                  value={serviceDescription}
                  onChange={e => setServiceDescription(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <div className="d-flex">
                <div>
                  <Label htmlFor="order" className="fw-bold">
                    Select Stylist
                  </Label>
                </div>
                <div className="d-flex ms-3">
                  <div className="me-3">
                    <RadioButton
                      changed={handleSelectStatus}
                      id="all"
                      isSelected={genderFilter === "all"}
                      label="All"
                      value="all"
                    />
                  </div>
                  <div className="me-3">
                    <RadioButton
                      changed={handleSelectStatus}
                      id="Male"
                      isSelected={genderFilter === "Male"}
                      label="Male"
                      value="Male"
                    />
                  </div>
                  <div className="me-3">
                    <RadioButton
                      changed={handleSelectStatus}
                      id="Female"
                      isSelected={genderFilter === "Female"}
                      label="Female"
                      value="Female"
                    />
                  </div>
                </div>
              </div>
              <FormGroup>
                <Select
                  id="providers"
                  value={providers}
                  isMulti={true} // Enable multi-select
                  onChange={handleProviders} // Use updated handler for multiple selections
                  options={IndividualList}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              {/* <Label htmlFor="order" className="fw-bold">
                Select users
              </Label> */}
              <FormGroup>
                <Label htmlFor="order" className="fw-bold">
                  Select User
                </Label>
                <Select
                  id="users"
                  value={selectedUser}
                  onChange={handleChangeUser}
                  options={usersList?.map(user => ({
                    ...user,
                    label: `${user.label} (${user.mobile})`, // Combine name and mobile for display
                  }))}
                  classNamePrefix="select2-selection"
                  isSearchable
                  filterOption={
                    (option, inputValue) =>
                      option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase()) ||
                      option.data.mobile.includes(inputValue) // Search by mobile number too
                  }
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <Label htmlFor="order" className="fw-bold">
                Address
              </Label>
              <FormGroup>
                <Input
                  type="text"
                  name="address"
                  className="form-control"
                  id="address"
                  placeholder="Enter address"
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
      {success_msg ? successPopup() : null}
      {error_msg ? errorPopup() : null}
    </>
  );
};
export default SlotsModal;
