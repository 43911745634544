const getScreenSize = () => {
  if (typeof window !== "undefined") {
    // detect window screen width function
    if (window.innerWidth >= 769) {
      return "desktop";
    }
    return "mobile";
  }
  return "";
};

const getTabScreenSize = () => {
  if (typeof window !== "undefined") {
    // detect window screen width function
    if (window.innerWidth >= 1200) {
      return "desktop";
    }
    return "tab";
  }
  return "";
};
export { getScreenSize, getTabScreenSize };
