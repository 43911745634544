import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import Spinner from "components/spinner";
import { Container, Button, Card, Row, Col, CardBody, Badge } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import moment from "moment";

export const OrderTable = ({ serviceList }) => {
  console.log(serviceList, "serlist");
  const services = () => [
    {
      dataField: "services",
      text: "SI. No",
      formatter: (cell, row, rowIndex) => {
        return <div className="text-center">{rowIndex + 1}</div>;
      },
    },

    {
      dataField: "images",
      text: "Type",
      formatter: images => (
        <div className="">
          <img src={images[0]} alt="" width="30px" height="30px" />
        </div>
      ),
    },
    {
      dataField: "name",
      text: "Description",
      formatter: (cell, row, amount) => {
        console.log(row, "servicetable row");
        const TotalservicesPrice = (
          parseFloat(row?.amount) / parseFloat(row?.quantity)
        )?.toFixed(2);
        const VariantPrice =
          row?.selectedBrandsData && row?.selectedBrandsData?.brandName !== ""
            ? row?.selectedBrandsData?.finalShadeProductPrice
            : "";
        const servivePrice = (
          parseFloat(TotalservicesPrice) - parseFloat(VariantPrice)
        )?.toFixed(2);
        return (
          <div className="text-start">
            {row?.name}
            <span className="fw-bold">
              {row?.type === "Female"
                ? " - Women"
                : row?.type === "Male"
                ? " - Men"
                : ""}
            </span>
            {row?.selectedBrandsData &&
            row?.selectedBrandsData?.brand &&
            row?.selectedBrandsData?.brand !== "" ? (
              <div>
                <div>
                  {/* <span className="fw-bold">Selected Product Details</span> */}
                  <hr className="my-2" />
                </div>
                <div>
                  <span className="fw-bold">
                    {row?.selectedBrandsData?.name}
                  </span>
                  <div className="d-flex align-items-center mt-2">
                    <img
                      src={row?.selectedBrandsData?.shadeImage}
                      className="border rounded"
                      alt=""
                      width={40}
                      height={40}
                    />
                    <div className="ms-2">
                      {row?.selectedBrandsData?.variantSelected ? (
                        <span>
                          {row?.selectedBrandsData?.variantSelected[0]?.type} :{" "}
                        </span>
                      ) : (
                        ""
                      )}
                      <span className="fw-bold">
                        {row?.selectedBrandsData?.variantSelected
                          ? row?.selectedBrandsData?.variantSelected[0]
                              ?.optionName
                          : ""}
                      </span>
                      <br />
                      <span>Price : </span>
                      <span className="fw-bold">
                        {row?.selectedBrandsData &&
                        typeof row?.selectedBrandsData
                          ?.finalShadeProductPrice === "number"
                          ? row?.selectedBrandsData?.finalShadeProductPrice?.toFixed(
                              2
                            )
                          : typeof row?.selectedBrandsData
                              ?.finalShadeProductPrice === "string"
                          ? row?.selectedBrandsData?.finalShadeProductPrice
                          : "0"}
                      </span>
                      <br />
                      <span>Brand : </span>
                      <span className="fw-bold">
                        {row?.selectedBrandsData?.variantSelected
                          ? row?.selectedBrandsData?.variantSelected[0]
                              ?.selectedBrand?.brandName ||
                            row?.selectedBrandsData?.variantSelected[0]
                              ?.selectedBrand?.name
                          : ""}
                      </span>
                      <br />
                      <span>SKU : </span>
                      <span className="fw-bold">
                        {row?.selectedBrandsData?.variantSelectedsku}
                      </span>
                      <br />
                      <span>Service Price : </span>
                      <span className="fw-bold">{servivePrice}</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {row?.serviceOptionsData && row?.serviceOptionsData[0] ? (
              <div>
                <div>
                  {/* <span className="fw-bold">Selected Product Details</span> */}
                  <hr className="my-2" />
                </div>
                <div>
                  {row?.serviceOptionsData &&
                    row?.serviceOptionsData?.map((ele, index) => {
                      return (
                        <div key={index}>
                          <h6>{ele?.optionTitle}</h6>
                          {ele?.values?.map((option, ind) => {
                            return (
                              <div
                                key={ind}
                                className="d-flex align-items-center"
                              >
                                <div className="me-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input checkbox"
                                    id="checkbox"
                                    checked={true}
                                    disabled
                                  />
                                </div>
                                <div>
                                  <span className="text-dark">
                                    {option?.name}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      },
    },

    {
      dataField: "sku",
      text: "SKU",
      formatter: sku => <div className="text-center">{sku ? sku : "-"}</div>,
    },
    {
      dataField: "quantity",
      text: "Qty",
      formatter: quantity => <div className="text-center">{quantity}</div>,
    },
    {
      dataField: "amt",
      text: "Unit Price",
      formatter: (cell, row, amount) => {
        return (
          <div className="text-center">
            ₹{" "}
            {row?.SelectedType == "Product"
              ? parseFloat(row?.price)?.toFixed(2)
              : (parseFloat(row?.amount) / parseFloat(row?.quantity))?.toFixed(
                  2
                )}
          </div>
        );
      },
    },
    {
      dataField: "netAmt",
      text: "Net Amount",
      formatter: (cell, row) => {
        return (
          <div className="text-center">
            ₹{" "}
            {row?.SelectedType == "Product"
              ? (parseFloat(row?.quantity) * parseFloat(row?.price))?.toFixed(2)
              : parseFloat(row?.amount).toFixed(2) || 0}
          </div>
        );
      },
    },
    {
      dataField: "taxType",
      text: "Tax Type",
      formatter: quantity => <div className="text-center">{quantity}</div>,
    },
    {
      dataField: "shippingCost",
      text: "Tax Amount",
      formatter: (cell, row) => <div className="text-center">0</div>,
    },

    {
      dataField: "shareAmount",
      text: "Partner Earnings",
      formatter: shareAmount => (
        <div className="text-center">
          ₹ {shareAmount ? parseFloat(shareAmount)?.toFixed(2) : 0}
        </div>
      ),
    },
    {
      dataField: "totalAmt",
      text: "Total Amount",
      formatter: (cell, row) => {
        return (
          <div className="text-center">
            ₹{" "}
            {row?.SelectedType == "Product"
              ? (parseFloat(row?.quantity) * parseFloat(row?.price))?.toFixed(2)
              : parseFloat(row?.amount).toFixed(2) || 0}
          </div>
        );
      },
    },
    {
      dataField: "Status",
      text: "Status",
      formatter: (cell, row) => {
        let status;
        if (row?.SelectedType == "Product") {
          if (row?.productDetails?.status == "Completed") {
            status = (
              <i className="bx bxs-check-circle text-success font-size-18"></i>
            );
          } else {
            status = (
              <i className="bx bxs-x-circle text-danger font-size-18"></i>
            );
          }
        } else {
          if (row?.status == "Completed") {
            status = (
              <i className="bx bxs-check-circle text-success font-size-18"></i>
            );
          } else {
            status = (
              <i className="bx bxs-x-circle text-danger font-size-18"></i>
            );
          }
        }
        return <div className="text-center">{status}</div>;
      },
    },
  ];
  const pageOptions = {
    sizePerPage: 10,
    totalSize: serviceList?.length,
    custom: true,
  };

  return (
    <Row>
      <Col xs="12">
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="_id"
          columns={services()}
          data={serviceList}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="_id"
              data={serviceList}
              columns={services()}
              bootstrap4
              search
            >
              {toolkitProps => (
                <>
                  <div className="table-responsive order-details-table table-sm ">
                    <BootstrapTable
                      keyField="name"
                      responsive
                      bordered={true}
                      striped={false}
                      classes={"table align-middle table-nowrap table-check"}
                      headerWrapperClasses={"table-light"}
                      {...toolkitProps.baseProps}
                      {...paginationTableProps}
                    />
                  </div>
                  <div>
                    {/* <table className="table ">
                      <tbody>
                        {uniqArray?.map((service, indx) => {
                          return (
                            <tr className="border border-none" key={indx}>
                              <td style={{ maxWidth: "70px" }}>
                                {" "}
                                <img
                                  src={service.images[0]}
                                  width="60px"
                                  height="60px"
                                  className="rounded border border-2"
                                  alt=""
                                />
                              </td>
                              <td
                                className="pricetype"
                                style={{ maxWidth: "130px" }}
                              >
                                <h6 className="h6">{service?.name || ""}</h6>
                                <p>Quantity : {service?.quantity || ""}</p>
                              </td>
                              <td className="float-end border-0">
                                <div>
                                  <p>
                                    <b>₹</b>{" "}
                                    {(service?.amount).toFixed(2) || ""}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table> */}
                  </div>
                </>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
        {serviceList && serviceList?.length == 0 && (
          <p className="text-center"> No Data Found</p>
        )}
      </Col>
    </Row>
  );
};
export default OrderTable;
