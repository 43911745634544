import React, { useState, useEffect, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Input,
  Badge,
  InputGroup,
  InputGroupText,
  FormFeedback,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Select from "react-select";
import countryList from "react-select-country-list";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./create.scss";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import ProfileImage from "./../../components/profileImage";
import Spinner from "components/spinner";

import {
  createService,
  updateService,
  getServicesById,
} from "services/services.service";

import {
  getCategories,
  getCategorybyType,
  getCategoryTypeandParent,
} from "services/category.service";
import { getBrands } from "services/ecommerce/brand.service";
import { getProducts } from "services/ecommerce/product.service";
import {
  getParentCategories,
  getParentCategoriesByQuery,
} from "services/parentcategory.service";

const initialValues = {
  name: "",
  categories: "",
  parentCategory: "",
  price: "",
  discountPrice: "",
  priceUnit: "",
  customQuantityUnit: "",
  duration: "",
  description: "",
  tips: "",
  featured: "false",
  enableBooking: "false",
  popular: "false",
  banner: {},
  images: [],
  colorOptions: [],
  serviceOptions: [],
  //   country:"",
  type: "",
  status: "",
  tagline: "",
  precautions: "",
  benfits: "",
  order: 0,
  brands: [],
  products: [],
};

const CreateService = ({ toggle, modal, id = false }) => {
  // const { id } = useParams();
  let history = useHistory();
  const [values, setValues] = useState(initialValues);
  const [brandsList, setBrandslist] = useState([]);
  const [productsList, setProductslist] = useState([]);
  const [optionImages, setOptionImages] = useState({});
  const [selectedPriceUnit, setSelectedPriceUnit] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedParentCategory, setSelectedParentCategory] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]);
  const [parentCategoriesList, setParentCategoriesList] = useState([]);
  const [selectedDesc, setSelectedDesc] = useState("");
  const [selectedTagline, setSelectedTagline] = useState("");
  const [selectedbenfits, setSelectedbenfits] = useState("");
  const [selectedprecautions, setSelectedprecautions] = useState("");
  const [selectedTips, setSelectedTips] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addons, setaddons] = useState([
    {
      key: "",
      value: "",
    },
  ]);
  const [colorOptions, setColorOption] = useState([
    {
      key: "",
      value: [
        {
          brand: "",
          product: "",
          image: "",
        },
      ],
    },
  ]);
  const [servicesOptions, setServiceOption] = useState([
    {
      key: "",
      value: [
        {
          name: "",
          value: "",
          image: "",
        },
      ],
    },
  ]);
  const [country, setCountry] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Name"),
    categories: Yup.string().required("Please Select Category"),
    // country: Yup.string().required("Please Select Country"),
    price: Yup.string().required("Please Enter Price"),
    discountPrice: Yup.number().required("Please Enter Discount Price"),
    priceUnit: Yup.string().required("Please Enter Price Unit"),
    customQuantityUnit: Yup.string().required(
      "PleaseEnter Custom Quantity Unit"
    ),
    duration: Yup.string().required("Please Enter Duration"),
    description: Yup.string().required("Please Enter description"),
    // tips: Yup.string().required("Please Enter Tips"),
    featured: Yup.string(),
    enableBooking: Yup.string(),
    popular: Yup.string(),
    // banner: Yup.object(),
    // images: Yup.object().required("Please Add images"),
    type: Yup.string().required("Please Enter type"),
    status: Yup.string().required("Please select status"),
  });

  const onSubmit = values => {
    if (id) {
      EditChanges(values);
    } else {
      saveChanges(values);
    }
  };
  const changeHandler = value => {
    setCountry(value);
  };
  const { banner, images } = values;

  const getCategoriesList = () => {
    getCategories()
      .then(res => {
        const list = [];
        res?.map(type => {
          list.push({ label: type.name, value: type._id });
        });
        setCategoriesList(list);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getBrandsList = () => {
    getBrands()
      .then(res => {
        if (res.data) {
          const result = buildOptions(res?.data);
          setBrandslist(result);
        } else {
          console.error(res);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getProductsList = () => {
    getProducts()
      .then(res => {
        const result = buildOptions(res?.data);
        setProductslist(result);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const statusList = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];
  const typeList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Kids", value: "Kids" },
  ];

  useEffect(() => {
    // getCategoriesList();
    getBrandsList();
    const { brands } = initialValues;
    setSelectedBrands(buildOptions(brands));
    getProductsList();
    const { products } = initialValues;
    setSelectedProducts(buildOptions(products));
  }, []);

  const goToPreviousPath = () => {
    history.goBack();
  };

  const getCategoryByType = type => {
    getCategorybyType(type)
      .then(res => {
        const list = [];
        res?.data?.map(type => {
          list.push({ label: type.name, value: type._id });
        });
        setCategoriesList(list);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const getCategoryTypeParent = (body, category = false) => {
    getCategoryTypeandParent(body)
      .then(res => {
        const list = [];
        res?.data?.map(type => {
          list.push({ label: type.name, value: type._id });
        });
        setCategoriesList(list);
        if (!category) {
          setSelectedCategory(null);
        }
        // setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const priceUnitList = [
    { label: "Hourly", value: "hourly" },
    { label: "Day", value: "Day" },
    { label: "Monthly", value: "monthly" },
  ];

  const checkboxChange = name => event => {
    setValues({ ...values, [name]: event.target.checked });
  };

  function handleAcceptedFiles(files, field) {
    let imgArray = values["images"];
    if (imgArray.length < 7) {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      imgArray.push(files[0]);
      setValues({ ...values, [field]: imgArray });
    } else {
      alert("Maximum 7 Images are allowed");
    }
  }

  function handleSelectStatus(event) {
    // setValues({ ...values, ["status"]: event.value });
    setSelectedStatus(event);
  }
  function handleSelectType(event) {
    // setValues({ ...values, ["type"]: event.value });
    setSelectedType(event);
    getParentCategoriesByQuery({ type: event.value })
      .then(res => {
        if (res.data) {
          let list = res?.data;
          let resArr = [];
          list.map(cat => {
            resArr.push({
              value: cat._id,
              label: cat.name,
            });
          });
          setParentCategoriesList(resArr);
          setSelectedParentCategory(null);
          setSelectedCategory(null);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }
  function removeImage(index) {
    let imgArray = values["images"];
    imgArray.splice(index, 1);
    setValues({ ...values, ["images"]: imgArray });
  }

  function handleSelect(event, name) {
    // setValues({ ...values, [name]: event.value });
    if (name === "categories") setSelectedCategory(event);
    if (name === "parentCategory") {
      setSelectedParentCategory(event);
      if (selectedType?.value) {
        let obj = {
          type: selectedType.value,
          parentCategory: event.value,
        };
        getCategoryTypeParent(obj);
      }
      // let obj={type:values.type,categories:values.categories}
      // console.log(obj)
    }
    if (name === "priceUnit") setSelectedPriceUnit(event);
  }
  //Build {label,value}array
  const buildOptions = list => {
    const result = list?.map(ele => {
      let obj = {
        label: ele.name,
        value: ele._id,
      };
      return obj;
    });
    return result;
  };
  function handleBrands(selectedMulti) {
    const data = [];
    selectedMulti.map(e => {
      data.push(e.value);
    });
    setValues({ ...values, ["brands"]: data });
    setSelectedBrands(selectedMulti);
  }

  function handleProducts(selectedMulti) {
    const data = [];
    selectedMulti.map(e => {
      data.push(e.value);
    });
    setValues({ ...values, ["products"]: data });
    setSelectedProducts(selectedMulti);
  }

  const saveChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (const key in optionImages) {
      formData.set(key, optionImages[key]);
    }
    for (let key in values) {
      if (key == "images") {
        let imgArr = values.images;
        if (imgArr.length > 0) {
          imgArr.forEach((img, index) => {
            formData.set("image" + index, img);
          });
        }
      }
      formData.set(key, values[key]);
    }
    formData.set("addOns", JSON.stringify(addons));
    formData.set("country", JSON.stringify(country));
    formData.set("serviceOptions", JSON.stringify(servicesOptions));
    formData.set("colorOptions", JSON.stringify(colorOptions));
    createService(formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
          setLoading(false);
        } else {
          seterror_msg(true);
          setLoading(false);
        }
      })
      .catch(e => {
        console.log(e, "error");
        seterror_msg(true);
      });
  };

  const EditChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (const key in optionImages) {
      formData.set(key, optionImages[key]);
    }
    for (let key in values) {
      if (key == "images") {
        const previousData = [];
        values.images.forEach((e, i) => {
          if (Object.keys(e).length === 4) {
            previousData.push(e);
          }
          if (Object.keys(e).length !== 4) {
            formData.set(`image${i}`, e);
          }
        });
        formData.set("country", JSON.stringify(country));

        formData.set("image", JSON.stringify(previousData));
      } else {
        // formData.set(key, values[key]);
        formData.set(
          key,
          Array.isArray(values[key]) ? JSON.stringify(values[key]) : values[key]
        );
      }
    }
    formData.set("addOns", JSON.stringify(addons));
    formData.set("serviceOptions", JSON.stringify(servicesOptions));
    formData.set("colorOptions", JSON.stringify(colorOptions));
    updateService(id, formData)
      .then(res => {
        if (res && res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
      });
  };

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const leaveToList = () => {
    // props.history.push("/Services-list");
    // goToPreviousPath();
    toggle();
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    // props.history.push("/Services-list");
    goToPreviousPath();
  };

  const addNewAddon = () => {
    const feilds = [...addons];
    feilds.push({ key: "", value: "" });
    setaddons(feilds);
  };

  const addColorOptions = () => {
    const feilds = [...colorOptions];
    feilds[0].value.push({
      brand: "",
      product: "",
      image: "",
    });
    setColorOption(feilds);
  };

  const handleColorOption = (name, key, index) => {
    const feilds = [...colorOptions];
    if (index !== undefined) {
      feilds[0].value[index][name] = key;
    } else {
      feilds[0][name] = key;
    }
    setColorOption(feilds);
  };
  const handleServiceOption = (name, key, index) => {
    const feilds = [...servicesOptions];
    if (index !== undefined) {
      feilds[0].value[index][name] = key;
    } else {
      feilds[0][name] = key;
    }
    setServiceOption(feilds);
  };
  const handelColorOptionImage = (file, index, name) => {
    const images = { ...optionImages };
    file.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    images[`${name}${index}`] = file[0];
    setOptionImages(images);
  };
  const addServiceOptions = () => {
    const feilds = [...servicesOptions];
    feilds[0].value.push({
      name: "",
      value: "",
      image: "",
    });
    setServiceOption(feilds);
  };
  const removeAddon = index => {
    const feilds = [...addons];
    feilds.splice(index, 1);
    setaddons(feilds);
  };
  const handleAddOn = (event, key, index) => {
    const feilds = [...addons];
    feilds[index][key] = event.target.value;
    setaddons(feilds);
  };
  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully Service created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}>
          {" "}
          {id ? "Edit Service" : "Create Service"}
        </ModalHeader>
        <ModalBody className="bg-light">
          <Spinner loading={loading} />
          <Container fluid={false}>
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    handleChange,
                    values,
                  }) => {
                    // console.log(errors);

                    useEffect(() => {
                      if (id) {
                        getServicesById(id)
                          .then(res => {
                            const data = { ...res.data };
                            const fields = [
                              "name",
                              "price",
                              "categories",
                              "parentCategory",
                              "discountPrice",
                              "priceUnit",
                              "customQuantityUnit",
                              "duration",
                              // "country",
                              "description",
                              "featured",
                              "enableBooking",
                              "popular",
                              "order",
                              "banner",
                              "images",
                              "tips",
                              "status",
                              "type",
                              "tagline",
                              "precautions",
                              "benfits",
                              "brands",
                              "products",
                            ];
                            setValues({
                              ...values,
                              banner: data["banner"],
                              images: data["images"],
                            });
                            fields.forEach(field => {
                              if (
                                field == "categories" ||
                                field == "parentCategory"
                              ) {
                                let cat = data[field]["_id"];
                                setFieldValue(field, cat, false);
                              } else {
                                setFieldValue(field, data[field], false);
                              }
                            });
                            // console.log(data.addOns.length);
                            console.log(data.colorOptions);
                            data.colorOptions.length > 0
                              ? setColorOption(data.colorOptions)
                              : setColorOption([
                                  {
                                    key: "",
                                    value: [
                                      { brand: "", product: "", image: "" },
                                    ],
                                  },
                                ]);
                            data.serviceOptions.length > 0
                              ? setServiceOption(data.serviceOptions)
                              : setServiceOption([
                                  {
                                    key: "",
                                    value: [{ name: "", value: "", image: "" }],
                                  },
                                ]);
                            data.addOns.length > 0
                              ? setaddons(data.addOns)
                              : setaddons([{ key: "", value: "" }]);
                            setSelectedPriceUnit({
                              value: data.priceUnit,
                              label: data.priceUnit,
                            });
                            setSelectedStatus({
                              label: data.status,
                              value: data.status,
                            });
                            setCountry(data.country);
                            setSelectedType({
                              label: data.type,
                              value: data.type,
                            });
                            // getCategoryByType(data.type);
                            let parCatObj = {
                              type: data.type,
                              parentCategory: data?.parentCategory?._id || "",
                            };
                            getCategoryTypeParent(parCatObj, true);
                            setSelectedCategory({
                              value: data.categories._id,
                              label: data.categories.name,
                            });
                            setSelectedDesc(
                              data.description ? data.description : ""
                            );
                            setSelectedTagline(
                              data.tagline ? data.tagline : ""
                            );
                            setSelectedprecautions(
                              data.precautions ? data.precautions : ""
                            );
                            setSelectedbenfits(
                              data.benfits ? data.benfits : ""
                            );
                            setSelectedTips(data.tips ? data.tips : "");
                            setSelectedParentCategory({
                              value: data.parentCategory?._id || "",
                              label: data.parentCategory?.name || "",
                            });
                            let brandsData = [];
                            res?.data?.brands?.map(e => {
                              let dataArray = [];
                              dataArray.push(e._id);
                              brandsData.push({
                                label: e.brandName,
                                value: e._id,
                              });
                              setFieldValue("brands", dataArray);
                            });
                            let productsData = [];
                            res?.data?.products?.map(e => {
                              let dataArray = [];
                              dataArray.push(e._id);
                              productsData.push({
                                label: e.productName,
                                value: e._id,
                              });
                              setFieldValue("brands", dataArray);
                            });
                            setSelectedBrands(brandsData);
                            setSelectedProducts(productsData);
                          })
                          .catch(err => {
                            console.log(err);
                            setLoading(false);
                          });
                      }
                    }, []);

                    return (
                      <Form>
                        <Row className="mt-3 mx-0">
                          <Col md={4} className="px-3">
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="name-Input"
                                className="col-form-label"
                              >
                                Name
                              </Label>
                              <Input
                                name="name"
                                placeholder="Enter Service Name"
                                type="text"
                                className="form-control"
                                id="name-Input"
                                onChange={handleChange}
                                value={values.name}
                                invalid={
                                  touched.name && errors.name ? true : false
                                }
                              />
                              {touched.name && errors.name ? (
                                <FormFeedback type="invalid">
                                  {errors.name}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={4} className="px-3">
                            <FormGroup className="mb-3">
                              <Label className="col-form-label">Country</Label>
                              <Select
                                name="type"
                                id="type"
                                options={options}
                                value={country}
                                onChange={event => {
                                  changeHandler(event);
                                  // handleChange("country")(event);
                                }}
                                classNamePrefix="select2-selection"
                              />
                              <Label className="statusValidation">
                                {" "}
                                {errors.type ? errors.type : ""}
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md={4} className="px-3">
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="customQuantityUnit-Input"
                                className="col-form-label"
                              >
                                Order
                              </Label>
                              <Input
                                name="order"
                                type="text"
                                className="form-control"
                                id="order-Input"
                                onChange={handleChange}
                                value={values.order}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <div className="me-3 hr"> </div>
                        <Row className="mt-3 mx-0">
                          <Col md={4} className="px-3">
                            <FormGroup className="mb-3">
                              <Label className="col-form-label">Type</Label>
                              <Select
                                name="type"
                                id="type"
                                value={selectedType}
                                onChange={event => {
                                  handleSelectType(event);
                                  handleChange("type")(event.value);
                                }}
                                options={typeList}
                                classNamePrefix="select2-selection"
                              />
                              <Label className="statusValidation">
                                {" "}
                                {errors.type ? errors.type : ""}
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md={4} className="px-3">
                            <Row className=" mb-3">
                              <Label className="col-form-label">
                                Parent Categories
                              </Label>
                              <Col sm={12}>
                                <Select
                                  name="parentCategory"
                                  value={selectedParentCategory}
                                  onChange={event => {
                                    handleSelect(event, "parentCategory");
                                    handleChange("parentCategory")(event.value);
                                  }}
                                  options={parentCategoriesList}
                                  classNamePrefix="select2-selection"
                                />
                                <Label className="statusValidation">
                                  {" "}
                                  {errors.parentCategory
                                    ? errors.parentCategory
                                    : ""}
                                </Label>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={4} className="px-3">
                            <Row className=" mb-3">
                              <Label className="col-form-label">
                                Sub Categories
                              </Label>
                              <Col sm={12}>
                                <Select
                                  name="category"
                                  value={selectedCategory}
                                  onChange={event => {
                                    handleSelect(event, "categories");
                                    handleChange("categories")(event.value);
                                  }}
                                  options={categoriesList}
                                  classNamePrefix="select2-selection"
                                />
                                <Label className="statusValidation">
                                  {" "}
                                  {errors.categories ? errors.categories : ""}
                                </Label>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <div className="me-3 hr"> </div>
                        <Row className="mt-3 mx-0">
                          <Col md={4} className="px-3">
                            <FormGroup className="mb-3">
                              <Label className="col-form-label">Price</Label>
                              <InputGroup>
                                <Input
                                  name="price"
                                  placeholder=""
                                  type="text"
                                  id="price"
                                  value={values.price}
                                  onChange={handleChange}
                                  invalid={
                                    touched.price && errors.price ? true : false
                                  }
                                />
                                <InputGroupText>$</InputGroupText>
                              </InputGroup>
                              {touched.price && errors.price ? (
                                <FormFeedback type="invalid">
                                  {errors.price}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={4} className="px-3">
                            <FormGroup className="mb-3">
                              <Label className="col-form-label">
                                Discount Price
                              </Label>
                              <InputGroup>
                                <Input
                                  type="number"
                                  placeholder=""
                                  name="discountPrice"
                                  value={values.discountPrice}
                                  onChange={handleChange}
                                  invalid={
                                    touched.discountPrice &&
                                    errors.discountPrice
                                      ? true
                                      : false
                                  }
                                />
                                <InputGroupText>$</InputGroupText>
                              </InputGroup>
                              {touched.discountPrice && errors.discountPrice ? (
                                <FormFeedback type="invalid">
                                  {errors.discountPrice}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={4} className="px-3">
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="price-Input"
                                className="col-form-label"
                              >
                                Price Unit
                              </Label>
                              <Select
                                name="priceUnit"
                                value={selectedPriceUnit}
                                onChange={event => {
                                  handleSelect(event, "priceUnit");
                                  handleChange("priceUnit")(event.value);
                                }}
                                options={priceUnitList}
                                classNamePrefix="select2-selection"
                              />
                              <Label className="statusValidation">
                                {" "}
                                {errors.priceUnit ? errors.priceUnit : ""}
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <div className="me-3 hr"> </div>
                        <Row className="mt-3 mx-0">
                          <Col md={4} className="px-3">
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="customQuantityUnit-Input"
                                className="col-form-label"
                              >
                                Custom quantity unit
                              </Label>
                              <Input
                                name="customQuantityUnit"
                                type="text"
                                className="form-control"
                                id="customQuantityUnit-Input"
                                onChange={handleChange}
                                value={values.customQuantityUnit}
                                invalid={
                                  touched.customQuantityUnit &&
                                  errors.customQuantityUnit
                                    ? true
                                    : false
                                }
                              />
                              {touched.customQuantityUnit &&
                              errors.customQuantityUnit ? (
                                <FormFeedback type="invalid">
                                  {errors.customQuantityUnit}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={4} className="px-3">
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="duration-Input"
                                className="col-form-label"
                              >
                                Duration
                              </Label>
                              <Input
                                type="text"
                                name="duration"
                                className="form-control"
                                id="duration-Input"
                                placeholder="Enter Duration "
                                onChange={handleChange}
                                value={values.duration}
                                invalid={
                                  touched.duration && errors.duration
                                    ? true
                                    : false
                                }
                              />
                              {touched.duration && errors.duration ? (
                                <FormFeedback type="invalid">
                                  {errors.duration}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        <div className="me-3 mt-3 hr"> </div>
                        <Row className="mt-3 mx-0">
                          <Col md={4} className="px-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="brands">Brands</Label>
                              <Select
                                id="brands"
                                value={selectedBrands}
                                isMulti={true}
                                onChange={event => {
                                  handleBrands(event);
                                  const data = [];
                                  event.map(e => {
                                    data.push(e.value);
                                  });
                                }}
                                options={brandsList}
                                classNamePrefix="select2-selection"
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4} className="px-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="products">Products</Label>
                              <Select
                                id="products"
                                value={selectedProducts}
                                isMulti={true}
                                onChange={event => {
                                  handleProducts(event);
                                  const data = [];
                                  event.map(e => {
                                    data.push(e.value);
                                  });
                                }}
                                options={productsList}
                                classNamePrefix="select2-selection"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <div className="me-3 mt-3 hr"> </div>
                        <Row className="mt-3 mx-0">
                          <Col md={6} className="px-3">
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="firstname-Input"
                                className="col-form-label"
                              >
                                Banner
                              </Label>
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  // handleAcceptedFiles(acceptedFiles, "banner");
                                  acceptedFiles.map(file =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      formattedSize: formatBytes(file.size),
                                    })
                                  );
                                  setFieldValue("banner", acceptedFiles[0]);
                                  setValues({
                                    ...values,
                                    banner: acceptedFiles[0],
                                  });
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {banner && Object.keys(banner).length > 0 && (
                                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <ProfileImage
                                            image={banner}
                                            height="80"
                                            classNames="avatar-sm rounded bg-light"
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {banner.name || banner.filename}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>
                                              {banner.formattedSize}
                                            </strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md={6} className="px-3">
                            {/* Multi Image dropdown here */}
                            <Row className="mb-3">
                              <Label
                                htmlFor="images"
                                className="col-form-label"
                              >
                                Images
                              </Label>
                              <Col sm={12}>
                                <Dropzone
                                  onDrop={acceptedFiles => {
                                    handleAcceptedFiles(
                                      acceptedFiles,
                                      "images"
                                    );
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone image_drop">
                                      <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="">
                                          <i className="display-6 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h6>
                                          Drop files here or click to upload.
                                        </h6>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                <div
                                  className="dropzone-previews mt-3"
                                  id="file-previews"
                                >
                                  {images &&
                                    images.length > 0 &&
                                    images.map((img, index) => {
                                      return (
                                        <Card
                                          key={index}
                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        >
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col className="col-auto">
                                                <ProfileImage
                                                  image={img}
                                                  height="80"
                                                  classNames="avatar-sm rounded bg-light"
                                                />
                                              </Col>
                                              <Col>
                                                <Link
                                                  to="#"
                                                  className="text-muted font-weight-bold"
                                                >
                                                  {img.name || img.filename}
                                                </Link>
                                                <p className="mb-0">
                                                  <strong>
                                                    {img.formattedSize}
                                                  </strong>
                                                </p>
                                              </Col>
                                            </Row>
                                          </div>
                                          {/* <div> */}
                                          <i
                                            style={{
                                              position: "absolute",
                                              right: "3px",
                                              cursor: "pointer",
                                            }}
                                            className="mdi mdi-close-thick font-size-18 text-danger"
                                            id="removeImgtooltip"
                                            onClick={() => removeImage(index)}
                                          />
                                          {/* </div> */}
                                        </Card>
                                      );
                                    })}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <div className="me-3 mt-3 hr"> </div>
                        <Row className="mt-4 mx-0">
                          <Col md={6} className="px-3">
                            <FormGroup className="mb-3">
                              <Row>
                                <Col md={6} className="">
                                  <Button
                                    color="primary"
                                    className="w-md btn-sm me-4"
                                    onClick={() => {
                                      addNewAddon();
                                    }}
                                  >
                                    Add New Addon
                                  </Button>
                                </Col>
                                <Col md={6} className=""></Col>
                              </Row>
                              {addons.length > 0 &&
                                addons.map((e, i) => (
                                  <Row className="mt-2" key={i}>
                                    <Col md={5} className="">
                                      <Input
                                        value={e.key}
                                        onChange={event => {
                                          handleAddOn(event, "key", i);
                                        }}
                                      />
                                    </Col>
                                    <Col md={6} className="">
                                      <Input
                                        value={e.value}
                                        onChange={event => {
                                          handleAddOn(event, "value", i);
                                        }}
                                      />
                                    </Col>
                                    <Col md={1}>
                                      <i
                                        style={{
                                          position: "absolute",
                                          right: "3px",
                                          cursor: "pointer",
                                        }}
                                        className="mdi mdi-close-thick font-size-18 text-danger"
                                        id="removeImgtooltip"
                                        onClick={() => removeAddon(i)}
                                      />
                                    </Col>
                                  </Row>
                                ))}
                            </FormGroup>
                          </Col>
                          <Col md={6} className="px-3">
                            <FormGroup className="mb-3">
                              <Label className="col-form-label">Status</Label>
                              <Select
                                name="Status"
                                value={selectedStatus}
                                onChange={event => {
                                  handleSelectStatus(event);
                                  handleChange("status")(event.value);
                                }}
                                options={statusList}
                                classNamePrefix="select2-selection"
                              />
                              <Label className="statusValidation">
                                {" "}
                                {errors.status ? errors.status : ""}
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <div className="me-3 mt-3 hr"> </div>
                        <Row className="mx-0">
                          <Col md={12} className="px-3">
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="firstname-Input"
                                className="col-form-label"
                              >
                                Description
                              </Label>
                              <Col sm={12} className="custom_ck_editor">
                                <CKEditor
                                  id="description"
                                  editor={ClassicEditor}
                                  data={selectedDesc}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    handleChange("description")(data);
                                    // setValues({ ...values, ["description"]: data });
                                  }}
                                />
                              </Col>
                              <Label className="statusValidation">
                                {errors.description ? errors.description : ""}
                              </Label>
                            </FormGroup>

                            {/* Tips */}
                            <FormGroup className="mb-3">
                              <Label htmlFor="tips" className="col-form-label">
                                Tips
                              </Label>
                              <div className="custom_ck_editor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={selectedTips}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    handleChange("tips")(data);
                                    // setValues({ ...values, ["tips"]: data });
                                  }}
                                />
                              </div>
                              <Label className="statusValidation">
                                {errors.tips ? errors.tips : ""}
                              </Label>
                            </FormGroup>

                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="tagline"
                                className="col-form-label"
                              >
                                Tagline
                              </Label>
                              <Col sm={12} className="custom_ck_editor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={selectedTagline}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    handleChange("tagline")(data);
                                    // setValues({ ...values, ["about"]: data });
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="benfits"
                                className="col-form-label"
                              >
                                Benfits
                              </Label>
                              <Col sm={12} className="custom_ck_editor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={selectedbenfits}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    handleChange("benfits")(data);
                                    // setValues({ ...values, ["about"]: data });
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="precautions"
                                className="col-form-label"
                              >
                                Precautions
                              </Label>
                              <Col sm={12} className="custom_ck_editor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={selectedprecautions}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    handleChange("precautions")(data);
                                    // setValues({ ...values, ["about"]: data });
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mx-0">
                          <Label className="col-form-label">
                            Color-Options
                          </Label>
                          <Col md={2}>
                            <Input
                              name="name"
                              placeholder="Type"
                              type="text"
                              className="form-control"
                              id="name-Input"
                              onChange={event =>
                                handleColorOption("key", event.target.value)
                              }
                              value={colorOptions[0].key}
                            />
                            {() => {
                              console.log(colorOptions[0].key);
                            }}
                          </Col>
                          <Col md={8}>
                            {colorOptions[0].value
                              ? colorOptions[0]?.value.map((e, i) => (
                                  <>
                                    <Row key={i + 1}>
                                      <Col md={4}>
                                        <Input
                                          name="name"
                                          placeholder="Brand Name"
                                          type="text"
                                          className="form-control"
                                          id="name-Input"
                                          onChange={event =>
                                            handleColorOption(
                                              "brand",
                                              event.target.value,
                                              i
                                            )
                                          }
                                          value={e.brand}
                                        />
                                      </Col>
                                      <Col md={4}>
                                        <Input
                                          name="name"
                                          placeholder="Product Name"
                                          type="text"
                                          className="form-control"
                                          id="name-Input"
                                          onChange={event =>
                                            handleColorOption(
                                              "product",
                                              event.target.value,
                                              i
                                            )
                                          }
                                          value={e.product}
                                        />
                                      </Col>
                                      <Col md={4}>
                                        <Row className="mb-3">
                                          <Col sm={12}>
                                            <Dropzone
                                              onDrop={acceptedFiles => {
                                                handelColorOptionImage(
                                                  acceptedFiles,
                                                  i,
                                                  "color"
                                                );
                                              }}
                                            >
                                              {({
                                                getRootProps,
                                                getInputProps,
                                              }) => (
                                                <div className="dropzone image_drop">
                                                  <div
                                                    className="dz-message needsclick"
                                                    {...getRootProps()}
                                                  >
                                                    <input
                                                      {...getInputProps()}
                                                    />
                                                    <div className="">
                                                      <i className="display-6 text-muted bx bxs-cloud-upload" />
                                                    </div>
                                                    <h6>
                                                      Drop files here or click
                                                      to upload.
                                                    </h6>
                                                  </div>
                                                </div>
                                              )}
                                            </Dropzone>
                                            <div
                                              className="dropzone-previews mt-3"
                                              id="file-previews"
                                            >
                                              {!optionImages[`color${i}`] &&
                                                e.image && (
                                                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                                    <div className="p-2">
                                                      <Row className="align-items-center">
                                                        <Col className="col-auto">
                                                          <ProfileImage
                                                            image={e.image}
                                                            height="80"
                                                            classNames="avatar-sm rounded bg-light"
                                                          />
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </Card>
                                                )}
                                              {optionImages[`color${i}`] && (
                                                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                                  <div className="p-2">
                                                    <Row className="align-items-center">
                                                      <Col className="col-auto">
                                                        <ProfileImage
                                                          image={
                                                            optionImages[
                                                              `color${i}`
                                                            ]
                                                          }
                                                          height="80"
                                                          classNames="avatar-sm rounded bg-light"
                                                        />
                                                      </Col>
                                                      <Col>
                                                        <Link
                                                          to="#"
                                                          className="text-muted font-weight-bold"
                                                        >
                                                          {optionImages[
                                                            `color${i}`
                                                          ].name ||
                                                            optionImages[
                                                              `color${i}`
                                                            ].filename}
                                                        </Link>
                                                        <p className="mb-0">
                                                          <strong>
                                                            {
                                                              optionImages[
                                                                `color${i}`
                                                              ].formattedSize
                                                            }
                                                          </strong>
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </Card>
                                              )}
                                            </div>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </>
                                ))
                              : ""}
                          </Col>
                          <Col md={2}>
                            <Button
                              color="primary"
                              className="w-md btn-sm me-4"
                              onClick={() => {
                                addColorOptions();
                              }}
                            >
                              Add Option
                            </Button>
                          </Col>
                        </Row>
                        <Row className="mx-0">
                          <Label className="col-form-label">
                            Service-Options
                          </Label>
                          <Col md={2}>
                            <Input
                              name="name"
                              placeholder="Type"
                              type="text"
                              className="form-control"
                              id="name-Input"
                              onChange={event =>
                                handleServiceOption("key", event.target.value)
                              }
                              value={servicesOptions[0].key}
                            />
                          </Col>
                          <Col md={8}>
                            {servicesOptions[0].value
                              ? servicesOptions[0]?.value.map((e, i) => (
                                  <>
                                    <Row key={i + 1}>
                                      <Col md={4}>
                                        <Input
                                          name="name"
                                          placeholder="Name"
                                          type="text"
                                          className="form-control"
                                          id="name-Input"
                                          onChange={event =>
                                            handleServiceOption(
                                              "name",
                                              event.target.value,
                                              i
                                            )
                                          }
                                          value={e.name}
                                        />
                                      </Col>
                                      <Col md={4}>
                                        <Input
                                          name="name"
                                          placeholder="Value"
                                          type="text"
                                          className="form-control"
                                          id="name-Input"
                                          onChange={event =>
                                            handleServiceOption(
                                              "value",
                                              event.target.value,
                                              i
                                            )
                                          }
                                          value={e.value}
                                        />
                                      </Col>
                                      <Col md={4}>
                                        <Row className="mb-3">
                                          <Col sm={12}>
                                            <Dropzone
                                              onDrop={acceptedFiles => {
                                                handelColorOptionImage(
                                                  acceptedFiles,
                                                  i,
                                                  "service"
                                                );
                                              }}
                                            >
                                              {({
                                                getRootProps,
                                                getInputProps,
                                              }) => (
                                                <div className="dropzone image_drop">
                                                  <div
                                                    className="dz-message needsclick"
                                                    {...getRootProps()}
                                                  >
                                                    <input
                                                      {...getInputProps()}
                                                    />
                                                    <div className="">
                                                      <i className="display-6 text-muted bx bxs-cloud-upload" />
                                                    </div>
                                                    <h6>
                                                      Drop files here or click
                                                      to upload.
                                                    </h6>
                                                  </div>
                                                </div>
                                              )}
                                            </Dropzone>
                                            <div
                                              className="dropzone-previews mt-3"
                                              id="file-previews"
                                            >
                                              {e.image && (
                                                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                                  <div className="p-2">
                                                    <Row className="align-items-center">
                                                      <Col className="col-auto">
                                                        <ProfileImage
                                                          image={e.image}
                                                          height="80"
                                                          classNames="avatar-sm rounded bg-light"
                                                        />
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </Card>
                                              )}
                                              {optionImages[`service${i}`] && (
                                                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                                  <div className="p-2">
                                                    <Row className="align-items-center">
                                                      <Col className="col-auto">
                                                        <ProfileImage
                                                          image={
                                                            optionImages[
                                                              `service${i}`
                                                            ]
                                                          }
                                                          height="80"
                                                          classNames="avatar-sm rounded bg-light"
                                                        />
                                                      </Col>
                                                      <Col>
                                                        <Link
                                                          to="#"
                                                          className="text-muted font-weight-bold"
                                                        >
                                                          {optionImages[
                                                            `service${i}`
                                                          ].name ||
                                                            optionImages[
                                                              `service${i}`
                                                            ].filename}
                                                        </Link>
                                                        <p className="mb-0">
                                                          <strong>
                                                            {
                                                              optionImages[
                                                                `service${i}`
                                                              ].formattedSize
                                                            }
                                                          </strong>
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </Card>
                                              )}
                                            </div>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </>
                                ))
                              : ""}
                          </Col>
                          <Col md={2}>
                            <Button
                              color="primary"
                              className="w-md btn-sm me-4"
                              onClick={() => {
                                addServiceOptions();
                              }}
                            >
                              Add Option
                            </Button>
                          </Col>
                        </Row>
                        <hr />
                        <div className="d-flex justify-content-end">
                          <div className="form-check me-4 my-auto">
                            <Label
                              className="form-check-Label fw-bold mb-0"
                              htmlFor="featured"
                            >
                              Featured
                            </Label>
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="featured"
                              value={values.featured === "true" ? true : false}
                              defaultChecked={
                                values.featured === "true" ? true : false
                              }
                              onChange={event => {
                                handleChange("featured")(
                                  event.target.checked ? "true" : "false"
                                );
                              }}
                            />
                          </div>
                          <div className="form-check me-4 my-auto">
                            <Label
                              className="form-check-Label fw-bold mb-0"
                              // htmlFor="enableBooking"
                            >
                              Enable booking(customer can place a booking)
                            </Label>
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="enableBooking"
                              value={
                                values.enableBooking === "true" ? true : false
                              }
                              defaultChecked={
                                values.enableBooking === "true" ? true : false
                              }
                              onChange={event => {
                                handleChange("enableBooking")(
                                  event.target.checked ? "true" : "false"
                                );
                              }}
                            />
                          </div>
                          <div className="form-check me-4 my-auto">
                            <Label
                              className="form-check-Label fw-bold mb-0"
                              htmlFor="popular"
                            >
                              Popular
                            </Label>
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="popular"
                              value={values.popular === "true" ? true : false}
                              defaultChecked={
                                values.popular === "true" ? true : false
                              }
                              onChange={event => {
                                handleChange("popular")(
                                  event.target.checked ? "true" : "false"
                                );
                              }}
                            />
                          </div>
                          {id ? (
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md btn-sm me-4"
                              // onClick={EditChanges}
                            >
                              Update Service
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md btn-sm me-4"
                              // onClick={saveChanges}
                            >
                              Save Service
                            </Button>
                          )}

                          <Button
                            type="submit"
                            color="light"
                            className="w-md btn-sm"
                            onClick={leaveToList}
                          >
                            cancel
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
                {success_msg ? successPopup() : null}
                {error_msg ? errorPopup() : null}
              </CardBody>
            </Card>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateService;
