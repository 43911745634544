import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { UpdateServiceProduct } from "services/services.service";

const EditServiceProducts = ({
  toggle,
  initValues,
  reloadService,
  modal,
  activedata,
}) => {
  const [editedOptions, setEditedOptions] = useState({});
  const [generalPercentage, setgeneralPercentage] = useState("");
  const [Options, setOptions] = useState([]);
  console.log(initValues, "initValues");
  useEffect(() => {
    if (activedata && activedata?.options && activedata?.options.length > 0) {
      const editedOptionsData = {};
      for (const optionGroup of activedata.options) {
        for (const option of optionGroup.optionDetails) {
          editedOptionsData[option.name] = option.extra || 0;
        }
      }
      setEditedOptions(editedOptionsData);
    }
    if (activedata) {
      setgeneralPercentage(activedata?.generalPricePercentage);
    }
    if (initValues) {
      let filteredOptions = initValues?.optionsDetails?.filter(
        ele => ele?.optionCategory === "service"
      );
      setOptions(filteredOptions);
    }
  }, [activedata]);

  function handleOptions(event, option) {
    const updatedOptions = [...(activedata.options || [])];
    const optionIndex = updatedOptions.findIndex(
      opt => opt.name === option.name
    );
    const percentage = event;

    const updatedOption = {
      name: option.name,
      extra: percentage,
    };

    if (optionIndex !== -1) {
      updatedOptions[optionIndex] = updatedOption;
    } else {
      updatedOptions.push(updatedOption);
    }
    setEditedOptions(prevEditedOptions => ({
      ...prevEditedOptions,
      [option.name]: percentage,
    }));
  }
  const handleChange = val => {
    setgeneralPercentage(val);
  };
  const onSubmit = () => {
    const transformedData = {
      gender: "F",
      generalPricePercentage: generalPercentage,
      options: Options.map(ele => ({
        title: ele.optionTitle,
        optionDetails: ele.values.map(option => ({
          name: option.name,
          extra: parseInt(editedOptions[option.name]) || 0,
        })),
      })),
    };
    UpdateServiceProduct(activedata?._id, transformedData)
      .then(res => {
        reloadService();
        toggle();
      })
      .catch(e => console.log("Error:", e));
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size="md" centered>
        <ModalHeader toggle={toggle}>Edit service product</ModalHeader>
        <ModalBody>
          {Options?.length > 0 ? (
            <div>
              {Options?.map((ele, index) => {
                return (
                  <div className="" key={index}>
                    <table className="table" style={{ width: "430px" }}>
                      <thead>
                        <tr>
                          <th className="ps-1">{ele?.optionTitle}</th>
                        </tr>
                      </thead>
                      {ele?.values?.map((option, ind) => {
                        return (
                          <tbody key={ind} className="options_table">
                            <tr>
                              <td style={{ width: "150px" }}>{option?.name}</td>
                              <td style={{ width: "100px" }}>
                                <div className="d-flex align-items-center">
                                  <Input
                                    type="number"
                                    className="form-control "
                                    id="percentage"
                                    placeholder=""
                                    name="percentage"
                                    value={editedOptions[option?.name]}
                                    onChange={event => {
                                      handleOptions(event.target.value, option);
                                    }}
                                  />
                                  <p className="d-inline mb-0 ms-1 font-size-16 fw-bold">
                                    %
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <Label>general Perecentage (%)</Label>

              <div className="d-flex align-items-center">
                <Input
                  type="number"
                  className="form-control "
                  id="generalPricePercentag"
                  placeholder=""
                  name="generalPricePercentag"
                  value={generalPercentage}
                  onChange={event => {
                    handleChange(event.target.value);
                  }}
                />
                <p className="d-inline mb-0 ms-1 font-size-16 fw-bold">%</p>
              </div>
            </div>
          )}
          <hr />
          <div className="d-flex mt-4 justify-content-end">
            <Button
              color="light"
              className="w-md btn-sm me-2"
              onClick={() => toggle()}
            >
              Back
            </Button>
            <Button
              color="primary"
              className="w-md btn-sm ms-2"
              onClick={() => onSubmit()}
            >
              Submit
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditServiceProducts;
