import React, { useState } from "react";
import ChangeStylistModal from "./ChangeStylistModal";
import axios from "axios";

const ChangeStylist = ({
  activeOrder,
  selectedRow,
  getallOrders,
  getOrderById,
  toggleModel,
}) => {
  const [modal, setModal] = useState(false);
  const [newProviders, setNewProviders] = useState([]);
  const [newProviderOptions, setNewProviderOptions] = useState([]);
  // console.log(newProviders, "newproviders", newProviderOptions,selectedRow);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const getProvidersByZipCode = async activeOrder => {
    console.log(activeOrder, "actorder");
    // debugger
    let selectedServices = activeOrder?.selectedList;
    if (selectedServices && selectedServices?.length) {
      let tempData = [...selectedServices];
      tempData = tempData?.map(item => ({
        _id: item?.serviceid?._id,
        selectedServiceOptions: item?.selectedOption?.flatMap(option => {
          if (option?.type === "Single Select") {
            return [
              {
                optionTitle: option?.optionTitle,
                optionID: option?._id,
                optionValueID: option?.values?.length
                  ? option?.values[0]._id
                  : "",
                optionValueName: option?.values?.length
                  ? option?.values[0]?.name
                  : "",
              },
            ];
          } else {
            return option?.values.map(value => ({
              optionTitle: option?.optionTitle,
              optionID: option?._id,
              optionValueID: value?._id,
              optionValueName: value?.name,
            }));
          }
        }),
      }));

      if (tempData && tempData.length) {
        // setServiceIdSelected(serviceIdSelected);
        getProviders(activeOrder?.zipcode, tempData, activeOrder?.timeZone);
      } else {
        getProviders(activeOrder?.zipcode, [], activeOrder?.timeZone);
      }
      setOpenModal(true);
    }
  };

  const getProviders = (zipcode, serviceIds, timezone) => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_DATABASEURL}/api/v1/provider/getbyzipcode`,
        {
          zipcode: zipcode,
          services: serviceIds,
          timeZone: timezone,
        }
      )
      .then(async res => {
        console.log(res, "res  res");
        let options = res?.data?.data.map(provider => ({
          label: provider?.legalname + " " + provider?.shortname,
          value: provider?._id,
          profileimage: provider?.image,
          companyId: provider?.compaines ? provider?.compaines[0] : "",
        }));
        setLoading(false);
        setNewProviders(res?.data?.data);
        setNewProviderOptions(options);
      });
  };
  console.log(activeOrder, "act Order");
  return (
    <>
      <div>
        <button
          className={"btn btn-sm px-3 py-1 font-size-12 btn-primary-red"}
          onClick={() => {
            toggle();
            getProvidersByZipCode(activeOrder);
          }}
        >
          Change Stylist
        </button>
      </div>
      <ChangeStylistModal
        toggle={toggle}
        modal={modal}
        AvailableProviders={newProviderOptions}
        providers={newProviders}
        selecteditem={selectedRow}
        activeOrder={activeOrder}
        loading={loading}
        getallOrders={getallOrders}
        getOrderById={getOrderById}
        toggleModel={toggleModel}
      />
    </>
  );
};

export default ChangeStylist;
