import React, { useState, useEffect, useRef } from "react";
import { MetaTags } from "react-meta-tags";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "components/Common/Breadcrumb";
import BreadcrumbSub from "components/Common/BreadcrumbSub";
import SlotsModal from "./SlotsModal";
import { getProviders } from "services/provider.service";
import moment from "moment";
import { getAllArtistQuotes, getAllEvents } from "services/quote.service";
import EventsView from "./eventsView";
import { getCustomers } from "services/user.service";
import { getPaginatedOrders } from "services/orders.service";
import * as _ from "lodash";
const SlotsIndex = () => {
  const [modal, setModal] = useState(false);
  const [individuals, setIndivididuals] = useState([]);
  const [activeOrder, setActiveOrder] = useState(null);
  const [usersList, setUserslist] = useState([]);
  const [nextUrl, setNextUrl] = useState("");
  const containerRef = useRef(null);
  const [allList, setAllList] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loadMore, setLoadMore] = useState(false);
  console.log(loadMore, "loadmore");
  useEffect(() => {
    getAllProviders();
    getAllArtistForms();
    getCustomerList();
  }, []);
  const toggle = () => {
    setModal(!modal);
  };
  const getAllArtistForms = () => {
    setLoading(true);
    getAllEvents()
      .then(res => {
        if (res?.success === true) {
          setAllList(res?.data);
          console.log(res?.data, "response");
          if (res?.data.length < 10) {
            setLoadMore(false);
          } else {
            setNextUrl(res?.meta?.nextUrl);
            setLoadMore(true);
          }
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const buildOptionsUsers = list => {
    console.log(list, "buildOptionsUsers");
    const result = list?.map(ele => {
      let obj = {
        label: ele?.firstname + " " + ele?.lastname,
        value: ele?._id,
        mobile: ele?.mobilenumber,
      };
      return obj;
    });
    return result;
  };
  const getCustomerList = () => {
    getCustomers()
      .then(res => {
        const result = buildOptionsUsers(res);
        setUserslist(result);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const buildOptions = list => {
    const result = list?.map(ele => {
      const experienceYears = ele?.experience
        ? Math.floor(
            (new Date() - new Date(ele.experience)) /
              (1000 * 60 * 60 * 24 * 365.25)
          )
        : 0;

      let obj = {
        label: `${ele?.legalname} ${ele?.shortname} - ${experienceYears} yrs Experience`,
        value: ele?._id,
        image: ele?.image,
        gender: ele?.gender,
      };
      return obj;
    });
    return result;
  };

  const getAllProviders = () => {
    getProviders()
      .then(res => {
        if (res?.data) {
          console.log(res.data, "ressssss");

          let VerifiedStylist = res?.data?.filter(
            ele => ele?.status === "Verified"
          );
          const result = buildOptions(VerifiedStylist);
          setIndivididuals(result);
        } else {
          console.error(res);
        }
      })
      .catch(e => {
        setLoading(false);
        console.log(e);
      });
  };
  const handleScroll = _.debounce(() => {
    setLoading(true);
    getPaginatedOrders(nextUrl)
      .then(res => {
        setAllList([...allList, ...res?.data]);
        if (res?.data.length < 10) {
          setLoadMore(false);
        } else {
          setNextUrl(res?.meta?.nextUrl);
          setLoadMore(true);
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  }, 500);
  const handleScrollEvent = () => {
    const container = containerRef.current;
    console.log(
      container,
      "conatiner",
      container.scrollHeight,
      container.scrollTop,
      container.clientHeight + 1,
      container.scrollHeight - container.scrollTop <=
        container.clientHeight + 1,
      loadMore
    );
    if (
      container.scrollHeight - container.scrollTop <=
        container.clientHeight + 1 &&
      loadMore
    ) {
      handleScroll();
    }
  };
  const handleOrder = order => {
    const activeOrder = allList?.filter(ele => ele?._id === order?._id);
    setActiveOrder(activeOrder);
    console.log(activeOrder, "actorder");
  };

  const RenderOrderCards = orders => {
    if (orders?.length === 0) {
      return (
        <CardBody role="button" className="">
          Orders are empty
        </CardBody>
      );
    }
    return (
      <>
        {orders?.map((order, indx) => {
          return (
            <CardBody
              role="button"
              key={indx}
              className="border border-bottom-1"
              onClick={() => {
                handleOrder(order);
              }}
            >
              <div className="buttons">
                <div className="">
                  <div className="d-flex justify-content-between">
                    {/* <h6 className="font-size-18 fw-bold">#{order?.quoteid}</h6> */}
                    {/* <small className="float-end mt-1 text-secondary">
                      {moment(order?.eventDate).fromNow()}
                    </small> */}
                  </div>
                  <span className="text-secondary ">Event Date: </span>
                  <br />
                  <span className="fw-bold font-size-14">
                    {moment(order?.eventDate).format("DD-MMM-YYYY @ ")}
                    {order?.eventTime}
                  </span>
                  <div className="mt-1">
                    <span className="text-secondary">Providers : </span>
                    {order?.providers?.map((ele, ind) => {
                      return (
                        <div key={ind}>
                          <span>
                            {ele?.providerid?.legalname +
                              " " +
                              ele?.providerid?.shortname}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  {/* <div className="mt-1">
                    <span className="text-secondary">Makeup Artist: </span>
                    <span className="fw-bold">
                      {order?.artistid?.name ? order.artistid.name : ""}{" "}
                    </span>
                  </div> */}
                </div>
              </div>
            </CardBody>
          );
        })}
      </>
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Individuals | stylepro - -</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Individuals" breadcrumbItem="Events" />
          <BreadcrumbSub subtile="Events" SubBreadcrumbItem="Individual" />
        </Container>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-sm btn-primary px-3"
            onClick={() => {
              toggle();
            }}
          >
            Create Event
          </button>
        </div>
        <Row className="mt-3">
          <Col md="4">
            <Card className="">
              <CardBody className="p-2">
                <div className="search-box  position-relative w-100 ">
                  <i className="bx bx-search-alt search-icon" />
                  <input
                    className="form-control  rounded-pill bg-white "
                    type="search"
                    id="example-search-input"
                    placeholder=" Search..."
                    // onChange={event => handleSearch(event.target.value)}
                  />
                </div>
              </CardBody>
              <CardBody className="p-0 artist_orders_list">
                <div className="tabs Orders_tabs">
                  <div
                    className="order-scroll"
                    ref={containerRef}
                    onScroll={handleScrollEvent}
                  >
                    {RenderOrderCards(allList)}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="8">
            <EventsView activeOrder={activeOrder} />
          </Col>
        </Row>
      </div>
      <SlotsModal
        modal={modal}
        toggle={toggle}
        individualsList={individuals}
        getAllArtistForms={getAllArtistForms}
        usersList={usersList}
      />
    </React.Fragment>
  );
};

export default SlotsIndex;
