import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Link } from "react-router-dom";

import Breadcrumb from "../../components/Common/Breadcrumb";

import { getServicesById } from "services/services.service";
import ProfileImage from "../../components/profileImage";
import Spinner from "components/spinner";
import CreateService from "./create";

const defaultValues = {
  name: "",
  categories: "",
  parentCategory: "",
  type: "",
  price: "",
  discountPrice: "",
  priceUnit: "",
  customQuantityUnit: "",
  duration: null,
  description: "",
  featured: false,
  enableBooking: "false",
  available: false,
  banner: {},
  images: [],
  brands: [],
  products: [],
  createdAt: null,
  updatedAt: null,
};

const ViewService = ({ toggle, modal, id = false }) => {
  // const { id } = useParams();
  let history = useHistory();
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(true);
  const [activeServiceId, setActiveServiceId] = useState(false);
  const [modalService, setModalService] = useState(false);
  const toggleService = () => {
    console.log("CLIKCED MODAL", !modalService);
    if (modalService) {
      setActiveServiceId(null);
    }
    setModalService(!modalService);
  };

  let { banner, images, brands, products } = values;

  useEffect(() => {
    getServicesById(id)
      .then(res => {
        let {
          name,
          price,
          categories,
          parentCategory,
          type,
          discountPrice,
          priceUnit,
          customQuantityUnit,
          duration,
          description,
          featured,
          enableBooking,
          available,
          banner,
          images,
          tips,
          tagline,
          benfits,
          precautions,
          brands,
          products,
          createdAt,
          updatedAt,
          _id,
        } = res.data;
        console.log(res.data);
        let obj = {
          name,
          price,
          categories,
          parentCategory,
          type,
          discountPrice,
          priceUnit,
          customQuantityUnit,
          duration,
          description,
          featured,
          enableBooking,
          available,
          banner,
          images,
          tips,
          tagline,
          benfits,
          precautions,
          brands,
          products,
          createdAt,
          updatedAt,
          _id,
        };
        setValues({ ...obj });
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const goToPreviousPath = () => {
    console.log(history);
    history.goBack();
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}> Service View</ModalHeader>
        <ModalBody className="bg-light">
          {/* <Spinner loading={loading} /> */}
          <Container fluid>
            {/* <Breadcrumb title="Services" breadcrumbItem="Service View" /> */}
            <Card className="view-users">
              <CardBody>
                <div className="float-end">
                  <Badge
                    className="font-size-12 badge-soft-warning py-1 px-3"
                    color={"bg-warning"}
                    pill
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setActiveServiceId(values._id);
                      toggleService();
                    }}
                  >
                    <i className="mdi mdi-pencil font-size-14" /> Edit
                  </Badge>
                </div>
                <Row>
                  <Col md={4} className="">
                    <div className="text-center my-4">
                      <h5 className="mt-4 text-muted">
                        {values.name ? values.name : "_"}
                      </h5>
                      <ProfileImage image={banner} classNames="img-thumbnail" />
                    </div>
                  </Col>
                  <Col md={8} className="mb-1 px-0 view_detail">
                    <Row className="mx-3 mb-2">
                      <Col md={3} className="mt-4">
                        <p className="fw-bold mb-1">Category</p>
                        <p className="text-muted">
                          {values.categories ? values.categories.name : "_"}{" "}
                        </p>
                      </Col>
                      <Col md={3} className="mt-4">
                        <p className="fw-bold mb-1">Parent Category</p>
                        <p className="text-muted">
                          {values.parentCategory
                            ? values.parentCategory.name
                            : "_"}{" "}
                        </p>
                      </Col>
                      <Col md={3} className="mt-4">
                        <p className="fw-bold mb-1">Type</p>
                        <p className="text-muted">
                          {values.type ? values.type : "_"}{" "}
                        </p>
                      </Col>
                      <Col md={3} className="mt-4">
                        <p className="fw-bold mb-1">Price</p>
                        <p className="text-muted">
                          {values?.price ? values.price : "_"}
                        </p>
                      </Col>
                      <Col md={3} className="mt-4">
                        <p className="fw-bold mb-1">Discount Price</p>
                        <p className="text-muted">
                          {values?.discountPrice ? values.discountPrice : "_"}
                        </p>
                      </Col>
                      <Col md={3} className="mt-4">
                        <p className="fw-bold mb-1">Duration</p>
                        <p className="text-muted">
                          {values?.duration ? values.duration : "_"}
                        </p>
                      </Col>
                      <Col md={3} className="mt-4">
                        <p className="fw-bold mb-1">Enable Booking</p>
                        <p className="text-muted">
                          {values?.enableBooking == "true" ? "YES" : "NO"}
                        </p>
                      </Col>
                    </Row>
                    <div className=" mt-2 me-3 hr"> </div>
                    <Row className="mx-3 my-2">
                      <Col md={12} className="mt-4">
                        <p className="fw-bold mb-1">Brands</p>
                        {values.brands &&
                          values.brands.length > 0 &&
                          values.brands.map(brand => {
                            return (
                              <Badge
                                key={brand.name}
                                className="font-size-12 badge-soft-primary px-4 py-2 me-3 my-1"
                                color={"bg-primary"}
                                pill
                              >
                                {brand.name}
                              </Badge>
                            );
                          })}
                        {values.brands.length == 0 && <>there are no brands</>}
                      </Col>
                      <Col md={12} className="mt-4">
                        <p className="fw-bold mb-1">Products</p>
                        {values.products &&
                          values.products.length > 0 &&
                          values.products.map(product => {
                            return (
                              <Badge
                                key={product.name}
                                className="font-size-12 badge-soft-primary px-4 py-2 me-3 my-1"
                                color={"bg-primary"}
                                pill
                              >
                                {product.name}
                              </Badge>
                            );
                          })}
                        {values.products.length == 0 && <>there are no products</>}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={12} className="mt-4">
                      <p className="fw-bold mb-1">Description</p>
                      {values?.description ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: values.description,
                          }}
                        />
                      ) : (
                        "_"
                      )}
                    </Col>
                    <Col md={12} className="mt-4">
                      <p className="fw-bold mb-1">Tips</p>
                      {values?.tips ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: values.tips,
                          }}
                        />
                      ) : (
                        "_"
                      )}
                    </Col>
                    <Col md={12} className="mt-4">
                      <p className="fw-bold mb-1">Tagline</p>
                      {values?.tagline ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: values.tagline,
                          }}
                        />
                      ) : (
                        "_"
                      )}
                    </Col>
                    <Col md={12} className="mt-4">
                      <p className="fw-bold mb-1">Benfits</p>
                      {values?.benfits ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: values.benfits,
                          }}
                        />
                      ) : (
                        "_"
                      )}
                    </Col>
                    <Col md={12} className="mt-4">
                      <p className="fw-bold mb-1">Precautions</p>
                      {values?.precautions ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: values.precautions,
                          }}
                        />
                      ) : (
                        "_"
                      )}
                    </Col>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Created</p>
                      <p className="text-muted">
                        {moment(
                          values.createdAt ? values.createdAt : "_"
                        ).format("MMM Do YYYY")}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Last Updated</p>
                      <p className="text-muted">
                        {moment(
                          values.updatedAt ? values.updatedAt : "_"
                        ).format("MMM Do YYYY")}
                      </p>
                    </Col>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                </Row>
                <Row className="mt-4">
                  <h5 className="text-muted">IMAGES</h5>
                  {images?.map((img, index) => {
                    return (
                      <Col key={index} md={4} className="px-1">
                        <ProfileImage image={img} classNames="img-thumbnail" />
                      </Col>
                    );
                  })}
                </Row>
              </CardBody>
            </Card>
          </Container>
        </ModalBody>
      </Modal>
      {modalService && (
        <CreateService
          toggle={toggleService}
          modal={modalService}
          id={activeServiceId}
        />
      )}
    </>
  );
};

export default ViewService;
