import Spinner from "components/spinner";
import React, { useState } from "react";
import { Container, Label } from "reactstrap";
import Select from "react-select";
import { MetaTags } from "react-meta-tags";
import OrdersList from "pages/Orders/List";
import BreadcrumbSub from "components/Common/BreadcrumbSub";
import Breadcrumbs from "components/Common/Breadcrumb";
import MainList from "./mainList";

const AdminOrdersList = () => {
  const [loading, setLoading] = useState(null);
  const [selectedversion, setSelectedVersion] = useState({
    label: "V2.0",
    value: "v2",
  });
  const VersionsList = [
    { label: "V1.0", value: "v1" },
    { label: "V2.0", value: "v2" },
  ];
  const handleChangeVersion = event => {
    setSelectedVersion(event);
  };
  return (
    <div>
      <React.Fragment>
        <Spinner loading={loading} />
        <div className="page-content">
          <MetaTags>
            <title></title>
          </MetaTags>
          <Container fluid>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div>
                <Breadcrumbs title="Orders" breadcrumbItem="Orders List" />
                <BreadcrumbSub
                  subtile="Service orders"
                  SubBreadcrumbItem="Orders"
                />
              </div>
              <div className="me-5" style={{ width: "200px" }}>
                <Label htmlFor="version" className="col-form-label">
                  Select version
                </Label>
                <Select
                  name="version"
                  id="version"
                  onChange={event => {
                    handleChangeVersion(event);
                  }}
                  options={VersionsList}
                  value={selectedversion}
                  placeholder="Select..."
                  classNamePrefix={"select2-selection "}
                />
              </div>
            </div>

            <div>
              {selectedversion?.value === "v1" ? <OrdersList /> : <MainList />}
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
};

export default AdminOrdersList;
