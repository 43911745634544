import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import ProfileImage from "../../components/profileImage";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { UpdateAuthor, createAnAuthor } from "services/Author.service";
import SuccessAlert from "components/Alerts/succes";
import ErrorAlert from "components/Alerts/error";
import Spinner from "components/spinner";
const defaultValues = {
  firstname: "",
  lastname: "",
  avatar: "",
  designation: "",
  description: "",
};

const CreateAuthor = ({ toggle, modal, AuthorData, reload }) => {
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = AuthorData
    ? { ...defaultValues, ...AuthorData }
    : defaultValues;
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("Please Write firstname"),
    avatar: Yup.string().required("Please Upload Image"),
  });

  /*** Formats the size ***/
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const onSubmit = values => {
    console.log(values, "val");
    if (AuthorData) {
      editChanges(values);
    } else {
      saveChanges(values);
    }
  };

  const saveChanges = values => {
    console.log(values);
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    createAnAuthor(formData)
      .then(res => {
        console.log(res, "resss");
        if (res?.success === true) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reload();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const editChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    UpdateAuthor(AuthorData?._id, formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reload();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    toggle();
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };
  return (
    <>
      <Spinner loading={loading} />
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}>
          {AuthorData ? `Edit Author Details` : `Create  Author`}
        </ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid>
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  // onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    handleChange,
                    setFieldTouched,
                    values,
                  }) => {
                    return (
                      <Form>
                        <Row className="mt-4">
                          <Col md={10}>
                            <Row>
                              <Col md={6} className="px-4">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="validationCustom01">
                                    First Name
                                  </Label>
                                  <Input
                                    name="firstname"
                                    placeholder="first Name"
                                    type="text"
                                    classtitle="form-control"
                                    id="firstname"
                                    onChange={handleChange}
                                    value={values.firstname}
                                    invalid={
                                      touched.firstname && errors.firstname
                                        ? true
                                        : false
                                    }
                                  />

                                  {touched.firstname && errors.firstname ? (
                                    <FormFeedback type="invalid">
                                      {errors.firstname}
                                      {touched.firstname}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col md={6} className="px-4">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="validationCustom01">
                                    Last Name
                                  </Label>
                                  <Input
                                    name="lastname"
                                    placeholder="last Name"
                                    type="text"
                                    classtitle="form-control"
                                    id="lastname"
                                    onChange={handleChange}
                                    value={values.lastname}
                                    invalid={
                                      touched.lastname && errors.lastname
                                        ? true
                                        : false
                                    }
                                  />

                                  {touched.lastname && errors.lastname ? (
                                    <FormFeedback type="invalid">
                                      {errors.lastname}
                                      {touched.lastname}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>

                              <Col md={6} className="px-4">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="validationCustom01">
                                    Designation
                                  </Label>
                                  <Input
                                    name="designation"
                                    placeholder="Designation"
                                    type="text"
                                    classtitle="form-control"
                                    id="designation"
                                    onChange={handleChange}
                                    value={values.designation}
                                    invalid={
                                      touched.designation && errors.designation
                                        ? true
                                        : false
                                    }
                                  />

                                  {touched.designation && errors.designation ? (
                                    <FormFeedback type="invalid">
                                      {errors.designation}
                                      {touched.designation}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col md={12} className="px-4">
                                <div className="">
                                  <h6> Description</h6>
                                </div>
                                <div className="mb-3">
                                  <Card style={{ border: "0px" }}>
                                    <CardBody className="p-0">
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={values?.description}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          handleChange("description")(data);
                                        }}
                                      />
                                    </CardBody>
                                  </Card>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={2}>
                            <div className="profile-div text-end d-inline-flex justify-content-end flex-column">
                              <ProfileImage
                                classNames={"border p-3"}
                                image={values.avatar}
                              />

                              <Dropzone
                                id="validationCustom05"
                                onDrop={acceptedFiles => {
                                  acceptedFiles.map(file =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      formattedSize: formatBytes(file.size),
                                    })
                                  );
                                  setFieldValue("avatar", acceptedFiles[0]);
                                  // handleChange(acceptedFiles[0], "avatar");
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="">
                                    <div className="" {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-sm mt-2"
                                      >
                                        {" "}
                                        Upload Profile
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            </div>
                          </Col>
                        </Row>
                        <hr></hr>
                        <div className="d-flex justify-content-end">
                          <Button
                            color="light"
                            className="w-md btn-sm me-3"
                            onClick={leaveToList}
                          >
                            cancel
                          </Button>
                          {!AuthorData ? (
                            <Button
                              color="primary"
                              type="submit"
                              className="w-md btn-sm"
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              className="w-md btn-sm"
                              type="submit"
                            >
                              Save Changes
                            </Button>
                          )}
                        </div>
                      </Form>
                    );
                  }}
                </Formik>

                {/* {success_msg ? successPopup() : null}
                {error_msg ? errorPopup() : null} */}
                {success_msg ? (
                  <SuccessAlert
                    msg={"Successfully Author Updated"}
                    leaveToList={leaveToList}
                    stayHere={stayHere}
                  />
                ) : null}
                {error_msg ? (
                  <ErrorAlert
                    msg={"error"}
                    leaveToList={leaveToList}
                    stayHere={stayHere}
                  />
                ) : null}
              </CardBody>
            </Card>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateAuthor;
