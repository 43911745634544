import React from "react";

export const RadioButton = props => {
  const { changed, id, isSelected, label, value, disabled } = props;

  return (
    <div className="RadioButton">
      <input
        className="form-check-input me-1"
        id={id}
        onClick={event => changed(event)}
        value={value}
        type="radio"
        checked={isSelected}
        disabled={disabled}
      />
      <label htmlFor={id} className="ms-1">
        {label}
      </label>
    </div>
  );
};
