import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Form, Formik } from "formik";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import banner from "../../../src/assets/images/coupon.png";
import { getSkuCode } from "services/ecommerce/product.service";
import Select from "react-select";
import * as Yup from "yup";
import { createcoupon, updateCoupon } from "services/coupons.service";
import { getServices } from "services/services.service";
import csc from "countries-states-cities";
import getImageUrl from "utils/getImageUrl";
import formatBytes from "utils/formatBytes";
import ProfileImage from "components/profileImage";
import Dropzone from "react-dropzone";
import { getProducts } from "services/ecommerce/product.service";
import SweetAlert from "react-bootstrap-sweetalert";
import Spinner from "components/spinner";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const defaultValues = {
  type: "",
  title: "",
  code: "",
  discountType: "",
  discountAmount: "",
  minimumPurchase: "",
  startDate: "",
  endDate: "",
  serviceId: "",
  productId: "",
  country: "",
  bannerImage: "",
  status: "",
  description: "",
  usage: "",
  visibility: "",
};
const CreateCoupon = ({ modal, toggle, coupondata, reload }) => {
  console.log(coupondata, "coupondata");
  const [couponCode, setcouponCode] = useState();
  console.log(couponCode, "couponecode");
  const [createmodal, setCreateModal] = useState(modal);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedDiscountType, setselectedDiscountType] = useState(null);
  const [selectedCouponStatus, setselectedCouponStatus] = useState(null);
  const [values, setValues] = useState(defaultValues);
  const [selectedService, setSelectedService] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [productMain, setProductMain] = useState([]);
  const [products, setProducts] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [selectedusage, setselectedUsage] = useState({
    label: "Single-Use",
    value: "Single-Use",
  });
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedVisibility, setSelectedVisibility] = useState({
    label: "Public",
    value: "Public",
  });
  const [images, setImages] = useState();
  const [loading, setLoading] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);

  useEffect(() => {
    getAllServices();
    getAllProducts();
    setCreateModal(modal);
  }, []);
  const generateRandomCode = () => {
    getSkuCode()
      .then(res => {
        setcouponCode(res?.data);
        setValues({ ...values, ["code"]: couponCode });
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    let list = [];
    csc.getAllCountries().forEach(ele => {
      let obj = {
        value: ele.iso2,
        id: ele.id,
        label: ele.name,
      };
      list.push(obj);
    });
    setCountriesList(list);
  }, []);

  function handleSelectCountry(event) {
    setValues({ ...values, ["country"]: event.value });
    setSelectedCountry(event);
  }

  const DiscountTypeList = [
    {
      label: "Amount",
      value: "Amount",
    },
    {
      label: "Percentage",
      value: "Percentage",
    },
  ];
  const Usagelist = [
    {
      label: "Single-Use",
      value: "Single-Use",
    },
    {
      label: "Multiple-Use",
      value: "Multiple-Use",
    },
  ];
  const CouponStatusList = [
    {
      label: "Active",
      value: "Active",
    },
    {
      label: "Inactive",
      value: "Inactive",
    },
  ];
  const visibilityList = [
    {
      label: "Public",
      value: "Public",
    },
    {
      label: "Private",
      value: "Private",
    },
  ];
  const statusList = [
    { label: "First Order", value: "First Order" },
    { label: "Discount On Service", value: "Discount On Service" },
    { label: "Discount On Product", value: "Discount On Product" },
    { label: "General", value: "General" },
  ];
  const initialValues = coupondata
    ? { ...defaultValues, ...coupondata }
    : defaultValues;

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Please Enter title"),
    // type: Yup.string().required("Please Select status"),
    // code: Yup.string().required("Please Upload Image"),
  });

  function handleSelectType(event) {
    setValues({ ...values, ["type"]: event.value });
    setSelectedType(event);
  }
  function handleSelectDiscountType(event) {
    setValues({ ...values, ["discountType"]: event.value });
    setselectedDiscountType(event);
  }
  function handleSelectVisibility(event) {
    setValues({ ...values, ["visibility"]: event.value });
    setSelectedVisibility(event);
  }
  function handleSelectCouponStatus(event) {
    setValues({ ...values, ["status"]: event.value });
    setselectedCouponStatus(event);
  }
  function handleSelectusage(event) {
    setValues({ ...values, ["usage"]: event.value });
    setselectedUsage(event);
  }
  const leaveToList = () => {
    toggle();
    setsuccess_msg(false);
    seterror_msg(false);
    setValues(defaultValues);
    setSelectedCountry({});
    // setSelectedProduct({});
    // setSelectedService({});
    setSelectedType({});
    setselectedDiscountType({});
    setSelectedVisibility({});
    setselectedCouponStatus({});
    setImages();
    setcouponCode("");
    // setCreateModal(!createmodal);
  };
  const onCancel = () => {
    setValues(defaultValues);
    setSelectedCountry({});
    // setSelectedProduct({});
    // setSelectedService({});
    setSelectedType({});
    setselectedDiscountType({});
    setSelectedVisibility({});
    setselectedCouponStatus({});
    setImages();
    setcouponCode("");
    // setCreateModal(!createmodal);
  };
  const onSubmit = values => {
    if (coupondata) {
      editChanges(values);
    } else {
      saveChanges(values);
    }
  };
  const buildOptions = list => {
    const result = list?.map(ele => {
      let obj = {
        label: ele.name,
        value: ele.name,
        id: ele?._id,
      };
      return obj;
    });
    return result;
  };
  const buildOptionsProducts = list => {
    const result = list?.map(ele => {
      let obj = {
        label: ele.name,
        value: ele.name,
        id: ele?._id,
      };
      return obj;
    });
    return result;
  };
  const getAllServices = () => {
    getServices()
      .then(res => {
        if (res) {
          setProductMain(buildOptions(res?.data));
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
  const getAllProducts = () => {
    getProducts()
      .then(res => {
        if (res) {
          setProducts(buildOptionsProducts(res?.data));
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
  const saveChanges = values => {
    setLoading(true);
    let body = {
      type: selectedType?.value,
      title: values?.title,
      code: couponCode
        ? couponCode?.toUpperCase()
        : values?.code?.toUpperCase(),
      discountType: selectedDiscountType?.value,
      discountAmount: values?.discountAmount,
      minimumPurchase: values?.minimumPurchase,
      startDate: values?.startDate,
      endDate: values?.endDate,
      country: selectedCountry?.value,
      bannerImage: images,
      status: selectedCouponStatus?.value,
      description: values?.description,
      usage: selectedusage?.value,
      visibility: selectedVisibility?.value,
    };
    if (selectedService?.id) {
      body.serviceId = selectedService?.id;
    }
    if (selectedProduct?.id) {
      body.productId = selectedProduct.id;
    }
    const ObjBody = JSON.stringify(body);
    createcoupon(ObjBody)
      .then(res => {
        if (res?.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reload();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const editChanges = values => {
    setLoading(true);
    let body = {
      type: selectedType?.value,
      title: values?.title,
      code: couponCode
        ? couponCode?.toUpperCase()
        : values?.code?.toUpperCase(),
      discountType: selectedDiscountType?.value,
      discountAmount: values?.discountAmount,
      minimumPurchase: values?.minimumPurchase,
      startDate: values?.startDate,
      endDate: values?.endDate,
      country: selectedCountry?.value,
      bannerImage: images,
      status: selectedCouponStatus.value,
      description: values?.description,
      usage: selectedusage?.value,
      visibility: selectedVisibility?.value,
    };
    if (selectedService?.id) {
      body.serviceId = selectedService?.id;
    }
    if (selectedProduct?.id) {
      body.productId = selectedProduct.id;
    }
    const ObjBody = JSON.stringify(body);
    updateCoupon(coupondata?._id, ObjBody)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reload();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        // onConfirm={leaveToList}
        // onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        {coupondata
          ? " Successfully Coupon Edited"
          : " Successfully Coupon Created"}
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  function getcountryname(code) {
    const fullcountryname = countriesList?.filter(ele =>
      ele?.value?.includes(code)
    );
    return fullcountryname;
  }
  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
        size="xl"
        onClosed={() => {
          onCancel();
        }}
      >
        <ModalHeader toggle={toggle}>
          <div className="d-flex align-items-center">
            <div className="me-2">
              <img src={banner} alt="" width={30} />
            </div>
            <h5 className="fw-bold text-uppercase mb-0">Coupon setUp</h5>
          </div>
        </ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid>
            <div>
              <div className="mt-3">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      // onSubmit={onSubmit}
                    >
                      {({
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        setFieldTouched,
                        values,
                      }) => {
                        useEffect(() => {
                          const getfullcountryname = getcountryname(
                            coupondata?.country
                          );
                          if (coupondata) {
                            setselectedDiscountType({
                              value: coupondata.discountType,
                              label: coupondata.discountType,
                            });
                            setSelectedType({
                              value: coupondata.type,
                              label: coupondata.type,
                            });
                            setSelectedCountry({
                              value: coupondata.country,
                              label: getfullcountryname[0]?.label,
                            });
                            setSelectedProduct({
                              value: coupondata.productId?.name,
                              label: coupondata.productId?.name,
                              id: coupondata?.productId?._id,
                            });
                            setSelectedService({
                              value: coupondata.serviceId?.name,
                              label: coupondata.serviceId?.name,
                              id: coupondata?.serviceId?._id,
                            });
                            setselectedCouponStatus({
                              value: coupondata?.status,
                              label: coupondata?.status,
                            });
                            setselectedUsage({
                              value: coupondata?.usage,
                              label: coupondata?.usage,
                            });
                            setSelectedVisibility({
                              value: coupondata?.visibility,
                              label: coupondata?.visibility,
                            });
                            setImages(coupondata?.bannerImage);
                            // setcouponCode(coupondata?.code);
                          }
                        }, [coupondata]);

                        return (
                          <Form>
                            <Row>
                              <Col sm={4} className="">
                                <Label htmlFor="country">Country</Label>
                                <Select
                                  name="country"
                                  id="country"
                                  onChange={event => {
                                    handleSelectCountry(event);
                                  }}
                                  options={countriesList}
                                  value={selectedCountry}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="visibility">Visibility</Label>
                                  <Select
                                    id="visibility"
                                    options={visibilityList || []}
                                    placeholder="Select..."
                                    value={selectedVisibility}
                                    onChange={event => {
                                      handleSelectVisibility(event);
                                      handleChange("visibility")(event.value);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="usage">Type</Label>
                                  <Select
                                    id="usage"
                                    options={Usagelist || []}
                                    placeholder="Select..."
                                    value={selectedusage}
                                    onChange={event => {
                                      handleSelectusage(event);
                                      handleChange("usage")(event.value);
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={4}>
                                <FormGroup>
                                  <Label for="title">Coupon Title</Label>
                                  <Input
                                    type="text"
                                    name="title"
                                    id="title"
                                    placeholder="Coupon Title"
                                    value={values?.title}
                                    onChange={event => {
                                      handleChange("title")(event.target.value);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="name">Coupon Type</Label>
                                  <Select
                                    id="type"
                                    value={selectedType}
                                    onChange={event => {
                                      handleSelectType(event);
                                      handleChange("type")(event.value);
                                    }}
                                    name="type"
                                    options={statusList}
                                    classNamePrefix="select2-selection"
                                  />
                                  <Label className="statusValidation">
                                    {errors.type ? errors.type : ""}
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label
                                    for="code"
                                    className="d-flex justify-content-between"
                                  >
                                    <div>Coupon Code </div>

                                    {selectedVisibility?.value === "Public" &&
                                    !couponCode ? (
                                      <Button
                                        className=" border border-0 p-0 px-2 text-info "
                                        color="light"
                                        role="button"
                                        onClick={() => generateRandomCode()}
                                      >
                                        Generate coupon code
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Label>
                                  <Input
                                    type="text"
                                    name="code"
                                    id="code"
                                    placeholder="Coupon Code"
                                    value={
                                      couponCode
                                        ? couponCode?.toUpperCase()
                                        : values?.code.toUpperCase()
                                    }
                                    onChange={event => {
                                      handleChange("code")(event.target.value);
                                    }}
                                    // disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="discountType">
                                    Discount Type
                                  </Label>
                                  <Select
                                    id="discountType"
                                    options={DiscountTypeList || []}
                                    value={selectedDiscountType}
                                    onChange={event => {
                                      handleSelectDiscountType(event);
                                      handleChange("discountType")(event.value);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <div className="d-flex">
                                  <div className="w-100">
                                    <FormGroup>
                                      <Label for="discountAmount">
                                        Discount Amount/Percentage
                                      </Label>
                                      <Input
                                        type="text"
                                        name="discountAmount"
                                        id="discountAmount"
                                        placeholder={
                                          selectedDiscountType?.value ===
                                          "Percentage"
                                            ? "Discount percentage"
                                            : "Discount Amount"
                                        }
                                        value={values?.discountAmount}
                                        onChange={event => {
                                          handleChange("discountAmount")(
                                            event.target.value
                                          );
                                        }}
                                      />
                                    </FormGroup>
                                  </div>
                                  <div className="mt-4 ms-2">
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="INR"
                                        id="INR"
                                        placeholder={
                                          selectedDiscountType?.value ===
                                          "Percentage"
                                            ? "%"
                                            : "INR"
                                        }
                                        disabled
                                      />
                                    </FormGroup>
                                  </div>
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="d-flex">
                                  <div className="w-100">
                                    <FormGroup>
                                      <Label for="minimumPurchase">
                                        Minimum Purchase Required
                                      </Label>
                                      <Input
                                        type="text"
                                        name="minimumPurchase"
                                        id="minimumPurchase"
                                        placeholder="Minimum Purchase"
                                        value={values?.minimumPurchase}
                                        onChange={event => {
                                          handleChange("minimumPurchase")(
                                            event.target.value
                                          );
                                        }}
                                      />
                                    </FormGroup>
                                  </div>
                                  <div className="mt-4 ms-2">
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        name="INR"
                                        id="INR"
                                        placeholder={
                                          selectedDiscountType?.value ===
                                          "Percentage"
                                            ? "%"
                                            : "INR"
                                        }
                                        disabled
                                      />
                                    </FormGroup>
                                  </div>
                                </div>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="startDate">Start Date</Label>
                                  <DatePicker
                                    name="startDate"
                                    selected={
                                      values?.startDate
                                        ? moment(
                                            values.startDate,
                                            "YYYY-MM-DD"
                                          ).toDate()
                                        : null
                                    }
                                    onChange={date => {
                                      const formattedDate =
                                        moment(date).format("YYYY-MM-DD");
                                      setFieldValue("startDate", formattedDate);
                                    }}
                                    dateFormat="dd-MMM-yyyy" // Display format
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="endDate">End Date</Label>
                                  <DatePicker
                                    name="endDate"
                                    selected={
                                      values?.endDate
                                        ? moment(
                                            values.endDate,
                                            "YYYY-MM-DD"
                                          ).toDate()
                                        : null
                                    }
                                    onChange={date => {
                                      const formattedDate =
                                        moment(date).format("YYYY-MM-DD");
                                      setFieldValue("endDate", formattedDate);
                                    }}
                                    dateFormat="dd-MMM-yyyy" // Display format
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="Status">Status</Label>
                                  <Select
                                    name="Status"
                                    id="Status"
                                    value={selectedCouponStatus}
                                    placeholder="Select..."
                                    onChange={event => {
                                      handleSelectCouponStatus(event);
                                      handleChange("status")(event.value);
                                    }}
                                    options={CouponStatusList}
                                  />
                                </FormGroup>
                              </Col>

                              {selectedType?.value === "Discount On Service" ? (
                                <Col md={4}>
                                  <FormGroup>
                                    <Label for="serviceId">Services</Label>
                                    <Select
                                      name="serviceId"
                                      id="serviceId"
                                      value={selectedService}
                                      placeholder="Select..."
                                      onChange={event => {
                                        setSelectedService(event);
                                        getAllServices(event.value);
                                      }}
                                      options={productMain}
                                    />
                                    <Label className="statusValidation">
                                      {errors.type ? errors.type : ""}
                                    </Label>
                                  </FormGroup>
                                </Col>
                              ) : (
                                ""
                              )}
                              {selectedType?.value === "Discount On Product" ? (
                                <Col md={4}>
                                  <FormGroup>
                                    <Label for="productId">Products</Label>
                                    <Select
                                      name="productId"
                                      id="productId"
                                      value={selectedProduct}
                                      placeholder="Select..."
                                      onChange={event => {
                                        setSelectedProduct(event);
                                        getAllProducts(event.value);
                                      }}
                                      options={products}
                                    />
                                    <Label className="statusValidation">
                                      {errors.productId ? errors.productId : ""}
                                    </Label>
                                  </FormGroup>
                                </Col>
                              ) : (
                                ""
                              )}
                              <div>
                                <Label for="description">Description</Label>
                                <Card>
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={values?.description || ""}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      handleChange("description")(data);
                                    }}
                                  />
                                </Card>
                              </div>
                              <div>
                                <Row>
                                  <Col
                                    sm={3}
                                    className="notes_attach_file mt-3"
                                  >
                                    <Label htmlFor="validationCustom06">
                                      Coupon Image
                                    </Label>
                                    <Dropzone
                                      onDrop={async acceptedFiles => {
                                        acceptedFiles.map(file =>
                                          Object.assign(file, {
                                            preview: URL.createObjectURL(file),
                                            formattedSize: formatBytes(
                                              file.size
                                            ),
                                          })
                                        );

                                        const imgUrl = await getImageUrl({
                                          image: acceptedFiles,
                                        });
                                        // const images = [];
                                        // const imgArray = [...images];
                                        // imgArray.push(imgUrl);
                                        setImages(imgUrl);
                                      }}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone ser_img_drop min-vh-0">
                                          <div
                                            className="dz-message needsclick "
                                            {...getRootProps()}
                                          >
                                            <input {...getInputProps()} />
                                            <div className="">
                                              <i className="display-6 text-muted bx bxs-cloud-upload" />
                                            </div>
                                            <h6 className="">
                                              Drop files here or click to
                                              upload.
                                            </h6>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                  </Col>
                                  <Col sm={1} className="custom_ck_editor">
                                    <div className="mt-4 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <ProfileImage
                                              image={images}
                                              height="50"
                                              width="50"
                                              classNames="avatar-sm rounded bg-light"
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Row>
                            <hr></hr>
                            <div className="d-flex justify-content-end">
                              <Button
                                color="light"
                                className="w-md btn-sm me-3"
                                onClick={leaveToList}
                              >
                                Cancel
                              </Button>
                              {!coupondata ? (
                                <Button
                                  color="primary"
                                  type="submit"
                                  className="w-md btn-sm"
                                  onClick={() => {
                                    onSubmit(values);
                                  }}
                                >
                                  Submit
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  className="w-md btn-sm"
                                  type="submit"
                                  onClick={() => {
                                    onSubmit(values);
                                  }}
                                >
                                  Save Changes
                                </Button>
                              )}
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
        </ModalBody>
      </Modal>
      <Spinner loading={loading} />
      {success_msg ? successPopup() : null}
      {error_msg ? errorPopup() : null}
    </>
  );
};

export default CreateCoupon;
