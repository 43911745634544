export const getAllArtistQuotes = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/quote/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting Artists Quotes ${e}`));
};

export const getArtistQuoteById = id => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/quote/get/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting Artists ${e}`));
};

export const getAllEvents = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v2/events/admin/get`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting Artists Quotes ${e}`));
};