import React from "react";
import { MetaTags } from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import "./Styles.scss";
import moment from "moment";
import ProfileImage from "components/profileImage";

const EventsView = ({ activeOrder }) => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Artist Forms View | stylepro - -</title>
      </MetaTags>
      <Container fluid>
        <React.Fragment>
          {activeOrder?.length &&
            activeOrder?.map((order, index) => {
              return (
                <Card className="" key={index}>
                  {" "}
                  <CardBody className=" border-3 border-bottom pb-1">
                    <Row>
                      <Col md="6" className="">
                        <div className="">
                          <div>
                          <h6 className="font-size-18 fw-bold mb-2">
                             #{order?.eventId ? order?.eventId :""}
                            </h6>
                            <h6 className="font-size-18 fw-bold mb-2">
                              Event Title - {order?.eventTitle ? order?.eventTitle :"-"}
                            </h6>
                          </div>

                          <h6 className="fw-bold">
                            Event Date :
                            <span className="fw-bold">
                              {" "}
                              {moment(order?.eventDate).format(
                                "DD-MMMM-YYYY @ "
                              )}
                              {order?.eventTime}
                            </span>
                          </h6>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody className=" border-3 border-bottom">
                    <div>
                      <div className="bg-light p-1 mb-3">
                        <p className="mb-0 fw-bold text-center font-size-14">
                          Providers Details
                        </p>
                      </div>
                      <div className="d-flex flex-wrap align-items-center mt-2">
                        {order?.providers.map((stylist, indx) => {
                          console.log(stylist, "stylist");
                          return (
                            <div className="me-4 d-flex flex-wrap" key={indx}>
                              <ProfileImage
                                image={
                                  stylist.providerid?.image === "undefined"
                                    ? noImage
                                    : stylist.providerid?.image
                                }
                                height="70"
                                width={70}
                                classNames="me-2 border"
                              />
                              <div>
                                <div className="d-flex align-items-center ">
                                  <div>
                                    <i className="bx bxs-user-circle me-1 font-size-14"></i>
                                  </div>
                                  <h6 className="mt-1 ">
                                    {stylist?.providerid?.legalname || "-"}{" "}
                                    {stylist?.providerid?.shortname || "-"}
                                  </h6>
                                </div>

                                <div className="d-flex align-items-center ">
                                  <div>
                                    <i className="bx bxs-phone-call me-1 font-size-14"></i>
                                  </div>
                                  <h6 className="mt-1">
                                    {stylist.providerid?.mobileNumber
                                      ? stylist.providerid?.mobileNumber
                                      : "-"}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {/* <div className="d-flex mb-1 ms-3">
                        <h6 className="customer_name mb-0">Client E-mail</h6>
                        <h6 className="mb-0">:</h6>
                        <h6 className="fw-bold ms-1 mb-0">
                          {order?.userid?.email ? order?.userid?.email : ""}
                        </h6>
                      </div> */}
                    </div>
                    <div className="mt-3 events_information">
                      <div className="bg-light p-1 mb-3">
                        <p className="mb-0 fw-bold text-center font-size-14">
                          Event Details
                        </p>
                      </div>
                      <div className="d-flex mb-1 artist_name">
                        <h6 className="customer_name">Services</h6>
                        <h6>:</h6>
                        <h6 className="fw-bold ms-1">
                          {order?.serviceDescription
                            ? order?.serviceDescription
                            : ""}
                        </h6>
                      </div>
                      <div className="d-flex mb-1 artist_name">
                        <h6 className="customer_name">Address</h6>
                        <h6>:</h6>
                        <h6 className="fw-bold ms-1">
                          {order?.address ? order?.address : ""}
                        </h6>
                      </div>
                    </div>
                    <div className="mt-3 events_information">
                      <div className="bg-light p-1 mb-3">
                        <p className="mb-0 fw-bold text-center font-size-14">
                         Client Details
                        </p>
                      </div>
                      <div className="d-flex mb-1 artist_name">
                        <h6 className="customer_name">User Name</h6>
                        <h6>:</h6>
                        <h6 className="fw-bold ms-1">
                          {order?.userId?.firstname
                            ? order?.userId?.firstname
                            : "-"}{" "}{order?.userId?.lastname
                              ? order?.userId?.lastname
                              : "-"}
                        </h6>
                      </div>
                      
                    </div>
                  </CardBody>
                </Card>
              );
            })}

          {!activeOrder ? (
            <Card className="p-3 pt-0 empty_order">
              <CardBody className="border-3">
                <div className="text-center">
                  <i className="mdi mdi-arrow-left-circle" id="nullCatList"></i>
                </div>
                <p className="text-center">Please Select Order</p>
              </CardBody>
            </Card>
          ) : (
            ""
          )}
        </React.Fragment>
      </Container>
    </React.Fragment>
  );
};

export default EventsView;
