import moment from "moment";
import React from "react";
import { Card, CardBody } from "reactstrap";
import SimpleBar from "simplebar-react";

const ServiceOrderHistory = ({ OrderHistory }) => {
  const RenderOrderHistory = OrderHistory => {
    return (
      <SimpleBar>
        <div className="mt-2">
          <ul className="verti-timeline list-unstyled mx-2">
            {OrderHistory &&
              OrderHistory?.map((log, indx) => {
                return (
                  <li className="event-list" key={indx}>
                    <div className="event-timeline-dot">
                      <i className="bx bx-right-arrow-circle font-size-18"></i>
                    </div>
                    <div className="d-flex">
                      <div className="me-2 d-flex">
                        <h5 className="font-size-14">
                          {log.time && moment(log.time).format("DD-MM-YYYY")}
                          <br />
                          <span className="font-size-12">
                            {log.time && moment(log.time).format(" hh:mm:ss A")}
                          </span>
                        </h5>
                        <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="">{log.status}</h6>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </SimpleBar>
    );
  };
  return (
    <div>
      <Card className="mb-0">
        <h5 className="p-2 mt-2 ms-2">Order History</h5>
        <CardBody className="border-top border-3">
          <div className="">
            {OrderHistory?.length > 0
              ? RenderOrderHistory(OrderHistory)
              : "History is not available"}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ServiceOrderHistory;
