import React, { useEffect, useState } from "react";
import { Col, Row, Label, Button } from "reactstrap";
import * as _ from "lodash";

import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import ProfileImage from "components/profileImage";
import {
  updatefilefeildService,
  UpdateService,
} from "services/services.service";
import { createImageUrl } from "services/imageUrl.service";

const ImageInfoModal = ({ toggle, initValues, reloadService }) => {
  let initImgVal = initValues?.images ? _.cloneDeep(initValues?.images) : [];
  const [values, setValues] = useState({
    images: initImgVal,
  });
  console.log(values, "valll");
  const [selectedImages, setSelectedImages] = useState([]);
  function removeImage(index) {
    let imgArray = values["images"];
    imgArray.splice(index, 1);
    setValues({ ...values, ["images"]: imgArray });
  }

  function handleAcceptedFiles(files, field) {
    let imgArray = values["images"];
    if (imgArray.length < 7) {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );

      imgArray.push(files[0]);
      setValues({ ...values, [field]: imgArray });

      selectedImages.push(files[0]);
      setSelectedImages(_.cloneDeep(selectedImages));
    } else {
      alert("Maximum 7 Images are allowed");
    }
  }
  const handleAcceptedImages = async images => {
    let obj = { ...values };

    if (obj.images.length < 7) {
      try {
        let imageFormData = new FormData();
        +imageFormData.set("image", images[0]);

        const data = await createImageUrl(imageFormData);

        if (data.success) {
          obj.images.push(data.image);
        }
        setValues({ ...obj });
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Maximum 7 Images are allowed");
    }
  };
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  let { images } = values;

  const onSubmit = () => {
    let imageUrls = [];

    values.images.forEach(ele => {
      if (typeof ele === "string") {
        // If the image is already a URL (existing image)
        imageUrls.push(ele);
      } else if (ele.preview) {
        // Handle new uploaded image if it has a preview (URL.createObjectURL)
        imageUrls.push(ele.preview);
      }
    });

    // Create the payload in the desired format
    let body = {
      images: imageUrls,
    };
    UpdateService(initValues._id, body)
      .then(res => {
        console.log(res);
        reloadService();
        toggle();
      })
      .catch(e => {
        console.log(e);
      });
  };
  return (
    <>
      <Row>
        <Label htmlFor="firstname-Input" className="col-form-label">
          Images
        </Label>
        <Col md={6} className="">
          <Dropzone
            onDrop={acceptedImages => {
              handleAcceptedImages(acceptedImages);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone image_drop mt-1">
                <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="">
                    <i className="display-6 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h6>Drop files here or click to upload.</h6>
                </div>
              </div>
            )}
          </Dropzone>
        </Col>
        <Col md={6}>
          <div className="dropzone-previews" id="file-previews">
            <div className="p-2">
              <Row className="align-items-center">
                {values?.images &&
                  values?.images.length > 0 &&
                  values?.images.map((img, index) => {
                    let { image } = img;
                    return (
                      <Col className="col-auto" sm={6} key={index}>
                        <div
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          style={{
                            position: "relative",
                          }}
                        >
                          <img
                            alt={"banner"}
                            src={img || image}
                            height="80"
                            className=" rounded bg-light"
                          />
                          <i
                            style={{
                              position: "absolute",
                              right: "3px",
                              cursor: "pointer",
                              top: 0,
                            }}
                            className="mdi mdi-close-thick font-size-18 text-danger"
                            id="removeImgtooltip"
                            onClick={() => removeImage(index)}
                          />
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <div className="d-flex mt-4 justify-content-end">
        <Button
          color="light"
          className="w-md btn-sm me-2"
          onClick={() => toggle()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="w-md btn-sm ms-2"
          onClick={() => onSubmit()}
        >
          Save
        </Button>
      </div>
    </>
  );
};
export default ImageInfoModal;
