import Spinner from "components/spinner";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import { getAllPaidOrders } from "services/V2_APIs/orders.service";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";
import jsPDF from "jspdf";
import "jspdf-autotable";
import stylifyRedLogo from "../../../src/assets/images/StylifyRed1.png";
const OrderDetailsList = () => {
  const [AllordersList, setAllOrdersList] = useState([]);
  const [downloadPdfModel, setDownloadPdfModel] = useState(false);
  const [loading, setLoading] = useState();
  const [previewModal, setPreviewModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState({});
  const [paymentMode, setSelectedmode] = useState({
    label: "View All",
    value: "View All",
  });
  const [selectedstartDate, setSelectedstartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const pageOptions = {
    sizePerPage: 10,
    totalSize: AllordersList?.length,
    custom: true,
  };

  useEffect(() => {
    getAllAdminPaidOrders(
      paymentMode?.value === "Online" ? "RazorPay" : paymentMode?.value,
      selectedMonth?.value,
      moment().year(),
      selectedstartDate ? moment(selectedstartDate).format("YYYY-MM-DD") : null,
      selectedEndDate ? moment(selectedEndDate).format("YYYY-MM-DD") : null
    );
  }, [paymentMode, selectedMonth, selectedstartDate, selectedEndDate]);

  const months = [];
  for (let i = 0; i < 12; i++) {
    months.push({
      label: moment().month(i).format("MMMM"),
      value: i + 1,
    });
  }

  const getAllAdminPaidOrders = async (
    paymentType,
    month,
    year,
    fromDate,
    ToDate
  ) => {
    try {
      setLoading(true);
      const apiCall = await getAllPaidOrders(
        paymentType,
        month,
        year,
        fromDate,
        ToDate
      );
      if (apiCall?.success === true) {
        console.log(apiCall, "apicall");
        setAllOrdersList(apiCall?.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching filtered list:", error);
      setLoading(false);
    }
  };

  const PaymentModeList = [
    {
      label: "Cash",
      value: "Cash",
    },
    {
      label: "Online",
      value: "Online",
    },
    {
      label: "View All",
      value: "View All",
    },
  ];

  const orders = () => [
    {
      dataField: "servicedate",
      text: "Service Date",
      formatter: (cell, row) => {
        const appointmentDate = moment(row?.appointmentDate);
        const today = moment().startOf("day");
        const tomorrow = moment().add(1, "days").startOf("day");
        let dateText;
        if (appointmentDate?.isSame(today, "day")) {
          dateText = "Today";
        } else if (appointmentDate?.isSame(tomorrow, "day")) {
          dateText = "Tomorrow";
        } else {
          dateText = moment(row?.appointmentDate).format("LL");
        }
        return (
          <div className="">
            <p className=" mb-0 font-size-14 fw-bold">
              {moment(row?.appointmentDate).format("DD-MMM-YYYY")} @{" "}
              {row?.appointmentTime}
            </p>
            {/* <p className="text-danger">
              {(dateText === "Today" || dateText === "Tomorrow") && (
                <p className="font-size-10 tex-danger fw-bold mb-0 ms-1">
                  ({dateText})
                </p>
              )}
            </p> */}
          </div>
        );
      },
    },
    {
      dataField: "orderId",
      text: "Order No.",
      formatter: (cell, row) => {
        // let createdAt = MomentTimezone.tz(
        //   row.createdAt,
        //   row.timeZone || "Asia/Calcutta"
        // ).format("DD-MMM-YYYY, h:mm A");
        // const appointmentDate = moment(row?.date);
        // const today = moment().startOf("day");
        // const tomorrow = moment().add(1, "days").startOf("day");
        // let dateText;
        // if (appointmentDate?.isSame(today, "day")) {
        //   dateText = "Today";
        // } else if (appointmentDate?.isSame(tomorrow, "day")) {
        //   dateText = "Tomorrow";
        // } else {
        //   dateText = moment(row?.date).format("LL");
        // }
        return (
          <>
            <div className="d-flex align-items-center">
              <div>
                <div className="d-flex text-danger">
                  <p className="text-primary fw-bold mb-0">#{row?.orderId}</p>
                </div>
                {/* <p className=" mb-0 font-size-12">{createdAt}</p> */}
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "userid",
      text: "Customer/Stylist",
      formatter: (cell, row) => {
        return (
          <div className="">
            <div className=" mb-2 font-size-12 d-flex ">
              <h6 className="mb-1" style={{ color: "#C55A11" }}>
                User :
              </h6>
              <h6 className="font-size-14 mb-1  ms-1">
                {row?.user?.firstname ? row?.user?.firstname : "-"}{" "}
                {row?.user?.lastname ? row?.user?.lastname : "-"}
              </h6>
            </div>
            <div className=" mb-2 font-size-12 d-flex ">
              <h6 className="mb-1" style={{ color: "#C55A11" }}>
                {row?.providers?.length > 1 ? "Stylists :" : "Stylist :"}
              </h6>
              <h6 className="">
                {row?.providers?.map((provider, ind) => {
                  return (
                    <p key={ind} className="mb-1 ms-1">
                      {provider?.legalname} {provider?.shortname}
                    </p>
                  );
                })}
              </h6>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "total",
      text: "Order Total",
      formatter: (cell, row) => {
        return (
          <div className="">
            <h6 className="font-size-14 mb-1">
              {row?.orderPaymentInfo?.amountPaid ? (
                <div>₹{(row?.orderPaymentInfo?.amountPaid).toFixed(2)}</div>
              ) : (
                "-"
              )}{" "}
            </h6>
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Payment Mode",
      formatter: (cell, row) => {
        return (
          <div className="">
            <p className="mb-0 pb-0 mt-1">
              {row?.orderPaymentInfo?.paymentType
                ? row?.orderPaymentInfo?.paymentType
                : "-"}
            </p>
          </div>
        );
      },
    },
  ];

  const DownloadPdfPopUp = () => {
    return (
      <SweetAlert
        // title="Success"
        // success
        onConfirm={downloadPdf}
        // onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <div>
              <h5 className="mb-3">Download as</h5>
              <div className="d-flex">
                <Button
                  color="light"
                  className="w-md btn-sm mx-1"
                  onClick={() => {
                    setDownloadPdfModel(false);
                  }}
                >
                  Cancel
                </Button>
                <div className="ms-2">
                  <Button
                    color="primary"
                    className="w-md btn-sm mx-1"
                    onClick={downloadPdf}
                  >
                    PDF
                  </Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        }
      ></SweetAlert>
    );
  };

  const downloadPdf = () => {
    if (!AllordersList?.length) {
      console.error("No data to download");
      return;
    }

    const doc = new jsPDF();

    const imageWidth = 40;
    const imageHeight = 20;
    doc.addImage(stylifyRedLogo, "PNG", 80, 0, imageWidth, imageHeight); // Positioning the image at (x, y)

    const tableHeaders = [
      "Order ID",
      "Service Date",
      "Customer/Stylist",
      "Invoice Amt",
      "Payment Mode",
    ];

    const tableRows = AllordersList?.map(order => [
      order?.orderId,
      moment(order?.appointmentDate).format("DD-MMM-YYYY"),
      `Stylist: ${
        order?.providers?.map(p => p?.legalname).join(", ") || "-"
      } \nUser: ${
        (order?.user?.firstname || "_") + " " + (order?.user?.lastname || "_")
      }`,
      `INR ${
        order?.orderPaymentInfo?.amountPaid
          ? order?.orderPaymentInfo?.amountPaid?.toFixed(2)
          : 0
      }`,
      order?.orderPaymentInfo?.paymentType || "N/A",
    ]);

    doc.autoTable({
      head: [tableHeaders],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 10 },
      headStyles: { fillColor: "#ef4354" },
    });

    const totalAmountPaid = AllordersList?.reduce((total, order) => {
      return total + (order?.orderPaymentInfo?.amountPaid || 0);
    }, 0);

    const pageWidth = doc?.internal.pageSize.getWidth();
    const totalText = `Total Invoice Amount: INR ${totalAmountPaid?.toFixed(
      2
    )}`;

    const xPos = pageWidth - doc.getTextWidth(totalText) - 14;

    doc.text(totalText, xPos, doc.lastAutoTable.finalY + 10);

    doc.save("AllOrdersTable.pdf");

    setDownloadPdfModel(false);
  };
  const togglePreviewModal = () => setPreviewModal(!previewModal);
  const ClearFilters = () => {
    setSelectedMonth({});
    setSelectedstartDate("");
    setSelectedEndDate("");
  };
  return (
    <>
      <div>
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <div>
            <Card>
              <CardBody className="p-3 all_orders_table">
                <div className="z">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <Row>
                        <Col md={12}>
                          <FormGroup
                            className="form_group"
                            style={{ width: "200px" }}
                          >
                            <Label htmlFor="paymentMode" className="fw-bold">
                              Select Payment Mode
                            </Label>
                            <Select
                              id="paymentMode"
                              options={PaymentModeList}
                              placeholder="Select a Payment mode"
                              classNamePrefix="select2-selection"
                              onChange={event => {
                                setSelectedmode(event);
                              }}
                              value={paymentMode}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="me-3 d-flex align-items-center mt-3">
                    <div>
                      <FormGroup
                        className="form_group"
                        style={{ width: "200px" }}
                      >
                        <Label htmlFor="year" className="fw-bold">
                          Select Month
                        </Label>
                        <Select
                          id="year"
                          options={months}
                          placeholder="Select Month"
                          classNamePrefix="select2-selection"
                          onChange={event => {
                            setSelectedMonth(event);
                          }}
                          value={selectedMonth}
                        />
                      </FormGroup>
                    </div>
                    <div className="">
                      <button
                        className="btn btn-sm mt-4 ms-2 btn-primary px-4"
                        onClick={() => {
                          togglePreviewModal();
                        }}
                      >
                        View List
                      </button>
                    </div>
                  </div>
                  <div className="me-3 mt-3">
                    <div className="">
                      <Label htmlFor="order" className="fw-bold">
                        Transaction Period
                      </Label>
                      <div className="d-flex align-items-center flex-wrap justify-content-between">
                        <div className="d-flex align-items-center flex-wrap">
                          <div className="d-flex align-items-center">
                            <div>
                              <FormGroup className="form_group">
                                {/* <Label for="startDate">Start Date</Label> */}
                                <DatePicker
                                  name="startDate"
                                  dateFormat="dd-MMM-yyyy"
                                  className="form-control"
                                  selected={selectedstartDate}
                                  onChange={date => setSelectedstartDate(date)}
                                  dropdownMode="select"
                                  showIcon
                                  toggleCalendarOnIconClick
                                  adjustDateOnChange
                                />
                              </FormGroup>
                            </div>
                            <div className="mx-3">
                              <p className="d-inline">To</p>
                            </div>
                          </div>
                          <div className=" ps-0 d-flex flex-wrap align-items-center mt-2">
                            <FormGroup className="form_group">
                              {/* <Label for="startDate">Start Date</Label> */}
                              <DatePicker
                                name="startDate"
                                dateFormat="dd-MMM-yyyy"
                                className="form-control"
                                selected={selectedEndDate}
                                onChange={date => setSelectedEndDate(date)}
                                dropdownMode="select"
                                showIcon
                                toggleCalendarOnIconClick
                                adjustDateOnChange
                              />
                            </FormGroup>
                            <div className="">
                              <button
                                className="btn btn-sm ms-2 btn-primary px-4"
                                onClick={() => {
                                  togglePreviewModal();
                                }}
                                disabled={selectedMonth?.label ? true : false}
                              >
                                View List
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <button
                            className="btn btn-sm mt-4 ms-2 btn-primary px-4 btn-primary-red"
                            onClick={() => {
                              setDownloadPdfModel(true);
                            }}
                            disabled={
                              !selectedMonth?.label ||
                              selectedstartDate !== undefined ||
                              selectedEndDate !== undefined
                            }
                          >
                            <i className="bx bx-download font-size-14 me-2"></i>
                            Download
                          </button>
                          <button
                            className="btn btn-sm mt-4 ms-2 btn-primary px-4 btn-primary ms-2"
                            onClick={() => {
                              ClearFilters();
                            }}
                          >
                            Clear Filters
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="exceptionsList"
                  columns={orders()}
                  data={AllordersList}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="exceptionsList"
                      data={AllordersList}
                      columns={orders()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  id="order-table"
                                  keyField="AllordersList"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  classes="table align-middle table-nowrap table-check"
                                  headerWrapperClasses="table-light"
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                              <PaginationListStandalone {...paginationProps} />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
                {AllordersList?.length == 0 && (
                  <p className="text-center"> No Data Found</p>
                )}
              </CardBody>
            </Card>
          </div>
        )}
      </div>
      {downloadPdfModel ? DownloadPdfPopUp() : null}
      {/* Preview Modal */}
      <Modal
        isOpen={previewModal}
        toggle={togglePreviewModal}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={togglePreviewModal}>Order Preview</ModalHeader>
        <ModalBody>
          <BootstrapTable
            keyField="AllordersList"
            data={AllordersList}
            columns={orders()}
            responsive
            bordered={false}
            striped={false}
            classes="table align-middle table-nowrap table-check"
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrderDetailsList;
