import React, { useState, useEffect } from "react";
import BasicInfo from "./basicInfo";
import DescribeInfo from "./describeInfo";
import ServiceInfo from "./serviceInfo";
import ImagesInfo from "./imagesInfo";
import Options from "./options";
import ProductsInfo from "./productsInfo";
import { updatefeildService } from "services/services.service";

const defaultValues = {
  name: "",
  categories: "",
  parentCategory: "",
  price: "",
  discountPrice: "",
  priceUnit: "",
  customQuantityUnit: "",
  duration: null,
  description: "",
  tips: "",
  featured: "false",
  enableBooking: "false",
  popular: "false",
  banner: {},
  images: [],
  options: [],
  //   country:"",
  type: "",
  status: "",
  tagline: "",
  precautions: "",
  benfits: "",
  order: 0,
  brands: JSON.stringify([]),
  products: JSON.stringify([]),
};

const initBasicInfo = {
  name: "",
  categories: "",
  parentCategory: "",
  price: "",
  discountPrice: "",
  priceUnit: "",
  customQuantityUnit: "",
  order: 0,
  duration: null,
  country: null,
  type: "",
  status: "",
  description: "",
  tagline: "",
};

const initProductsInfo = {
  brands: JSON.stringify([]),
  products: JSON.stringify([]),
};
const initDescribeInfo = {
  description: "",
  tips: "",
  tagline: "",
  benfits: "",
  precautions: "",
};

const initServiceInfo = [
  {
    key: "",
    value: "",
  },
];
const initImagesInfo = {
  banner: {},
  images: [],
};

const initFeaturesInfo = {
  featured: "false",
  enableBooking: "false",
  popular: "false",
};

const initOptionsInfo = [
  {
    optionTitle: "",
    values: [
      {
        type: "",
        name: "",
        value: "",
        image: "",
        displayType: "",
        order: "",
        status: "",
      },
    ],
  },
];

const Info = ({
  serviceValues,
  handleDelete,
  parentCategory,
  subCatList,
  reloadService,
}) => {
  const [basicInfo, setBasicInfo] = useState(initBasicInfo);
  const [productsInfo, setProductsInfo] = useState(initProductsInfo);
  const [imagesInfo, setImagesInfo] = useState(initImagesInfo);
  const [describeInfo, setDescibeInfo] = useState(initDescribeInfo);
  const [serviceInfo, setserviceInfo] = useState(initServiceInfo);
  const [featuresInfo, setFeaturesInfo] = useState(initFeaturesInfo);
  const [optionsInfo, setOptionsInfo] = useState(initOptionsInfo);
  const [id, setId] = useState("");
  const [fulldata, setFulldata] = useState({});

  useEffect(() => {
    if (serviceValues) {
      let {
        name,
        categories,
        parentCategory,
        price,
        discountPrice,
        priceUnit,
        customQuantityUnit,
        duration,
        type,
        status,
        description,
        tips,
        order,
        featured,
        enableBooking,
        popular,
        banner,
        images,
        options,
        country,
        tagline,
        precautions,
        benfits,
        brands,
        products,
        createdAt,
        updatedAt,
        serviceInfo,
        _id,
      } = serviceValues;

      const basicInfoObj = {
        name,
        categories,
        parentCategory,
        price,
        discountPrice,
        priceUnit,
        customQuantityUnit,
        order,
        duration,
        country,
        type,
        status,
        description,
        tagline,
      };

      const productsInfoObj = {
        brands,
        products,
      };

      // const describeInfoObj = {
      //   description,
      //   tips,
      //   tagline,
      //   benfits,
      //   precautions,
      // };

      const imageInfoObj = {
        banner,
        images,
      };

      const featuresInfoObj = {
        featured,
        enableBooking,
        popular,
      };
      setBasicInfo(basicInfoObj);
      setProductsInfo({ ...productsInfoObj });
      // setDescibeInfo(describeInfoObj);
      setImagesInfo(imageInfoObj);
      setFeaturesInfo(featuresInfoObj);
      setserviceInfo(serviceInfo);
      setId(_id);
      if (options && options.length > 0) {
        let optVal = JSON.parse(JSON.stringify(options));
        setOptionsInfo(optVal);
      }
    }
  }, [serviceValues]);

  const onSubmitFields = values => {
    let body = { ...values };
    updatefeildService(id, body)
      .then(res => {
        console.log(res);
        reloadService();
      })
      .catch(e => console.log("====", e));
  };
  
  return (
    <>
      <div className="custom-border px-3 py-3">
        <BasicInfo
          initValues={basicInfo}
          handleSubmit={onSubmitFields}
          parentCategory={parentCategory}
          subCategoryList={subCatList}
          reloadService={reloadService}
        />
      </div>
      <div className="divider"> </div>
      {/* <div className="border-light px-2 py-3 mt-3">
        <ProductsInfo initValues={productsInfo} handleSubmit={onSubmitFields} />
      </div> */}
      {/* <div className="border-light px-2 py-3 mt-3">
        <DescribeInfo
          initValues={describeInfo}
          handleSubmit={onSubmitFields}
          reloadService={reloadService}
        />
      </div> */}
      <div className="custom-border px-3 py-3">
        <ServiceInfo
          initValues={serviceInfo}
          handleSubmit={onSubmitFields}
          serviceId={id}
        />
      </div>
      <div className="divider"> </div>
      <div className="custom-border px-3 py-3 ">
        <ImagesInfo
          initValues={imagesInfo}
          handleSubmit={onSubmitFields}
          reloadService={reloadService}
        />
      </div>
      <div className="divider"> </div>
      <div className="custom-border px-3 py-3 ">
        <Options
          initValues={optionsInfo}
          handleSubmit={onSubmitFields}
          reloadService={reloadService}
          optionId={id}
        />
      </div>
    </>
  );
};

export default Info;
